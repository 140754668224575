import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { getStaffMentions } from '../../../features/staff/services/staff.api';
import { selectDateFilter } from '../../shared/dateFilter/dateFilterSelectors';
import { startOfDay, endOfDay, parseISO, isWithinInterval, startOfMonth, format } from 'date-fns';

const initialState = {
  data: [],
  loading: false,
  error: null,
  hasMore: true,
  cache: {},
  CACHE_DURATION: 5 * 60 * 1000,
};

// Export the cache key generator helper before using it
export const generateCacheKey = (locationId, offset, dateFilter) => {
  return `${locationId}-${offset}-${dateFilter?.startDate}-${dateFilter?.endDate}`;
};

export const fetchStaffMentions = createAsyncThunk(
  'staff/fetchStaffMentions',
  async ({ locationId, offset = 0, dateFilter }, { getState}) => {
    const state = getState();
    const { accessToken } = state.auth;
    const cache = state.staff.cache;
    let cacheKey = generateCacheKey(locationId, offset, dateFilter);

    if(cache[cacheKey]){
      const {data, timestamp} = cache[cacheKey];
      const isExpired = Date.now() - timestamp > state.staff.CACHE_DURATION;

      if(!isExpired){
        return {data, offset, hasMore: data.length === 50, cacheKey};
      }
    }

    // Fetch new data if cache miss or expired
    const response = await getStaffMentions(
      locationId,
      offset,
      50,
      accessToken,
      dateFilter
    );

    return{
      data: response.data,
      offset,
      hasMore: response.data.length === 50,
      cacheKey
    };
  }
);

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    clearStaffMentions: (state) => {
      state.data = [];
      state.hasMore = true;
      state.loading = false;
      state.error = null;
    },
    clearCache: (state) => {
      state.cache = {};
    },
    invalidateCache: (state, action) => {
      const cacheKey = action.payload;
      delete state.cache[cacheKey];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaffMentions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStaffMentions.fulfilled, (state, action) => {
        const { data, offset, hasMore, cacheKey } = action.payload;
        
        // Update cache
        state.cache[cacheKey] = {
          data,
          timestamp: Date.now(),
        };

        state.data = offset === 0 ? data : [...state.data, ...data];
        state.hasMore = hasMore;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchStaffMentions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});


// Selectors
export const selectStaffState = (state) => state.staff;
export const selectStaffMentions = (state) => state.staff.data;
export const selectStaffLoading = (state) => state.staff.loading;
export const selectStaffHasMore = (state) => state.staff.hasMore;
export const selectStaffError = (state) => state.staff.error;

export const selectFilteredStaffMentions = createSelector(
  [
    selectStaffMentions,                          // 1. Gets all staff mentions  
    state => state.location.selectedLocation?.id, // 2. Gets the selected location ID
    selectDateFilter('staff')                     // 3. Gets the date filter for the staff  
  ],
  (staffMentions, locationId, dateFilter) => {
    if (!Array.isArray(staffMentions)) return [];
    
    let filtered = staffMentions;

    // Filter by location if needed
    if (locationId && locationId !== "all") {
      filtered = filtered.filter(mention => mention.location_id === locationId);
    }
    
    // Filter by date range if present
    if (dateFilter?.startDate && dateFilter?.endDate) {
      filtered = filtered.filter((staffMentions) => {
        const mentionDate = startOfDay(parseISO(staffMentions.review_date));
        const startDate = startOfDay(parseISO(dateFilter.startDate));
        const endDate = endOfDay(parseISO(dateFilter.endDate));
        return isWithinInterval(mentionDate, { start: startDate, end: endDate });
      });
    }

    return filtered;
  }
);

export const selectStaffCache = createSelector(
  [(state) => state.staff?.cache],
  (cache) => cache || {}
);

export const selectStaffDateFilter = createSelector(
  [selectDateFilter('staff')],
  (dateFilter) => {
    if (dateFilter?.startDate && dateFilter?.endDate) {
      return dateFilter;
    }
    return {
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
    };
  }
);

// Actions
export const { clearStaffMentions, clearCache, invalidateCache,  } = staffSlice.actions;

export default staffSlice.reducer;
