import { apiRequest } from '../../../services/api/apiUtility';
import { API_ENDPOINTS } from '../../../services/api/config';
import { parseISO, format } from 'date-fns';

export const getGoogleReviews = async (locationId, offset = 0, limit = 50, token, dateFilter = null) => {
  try {
    // Build base URL
    let url = API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.PAGINATED(locationId, offset, limit);
    
    // Add date parameters only if dateFilter is provided and has both dates
    if (dateFilter?.startDate && dateFilter?.endDate) {
      const startDate = format(parseISO(dateFilter.startDate), 'yyyy-MM-dd');
      const endDate = format(parseISO(dateFilter.endDate), 'yyyy-MM-dd');

      url += `?startDate=${startDate}&endDate=${endDate}`;
    }

    return await apiRequest(
      url,
      'GET',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching Google reviews:', error);
    throw error;
  }
};


export const updateReviewResponse = async (
  locationId,
  reviewId,
  response,
  token
) => {
  try {
    const result = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.UPDATE_RESPONSE(locationId, reviewId),
      'PUT',
      { response },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return result;
  } catch (error) {
    console.error('Error updating review response:', error);
    throw error;
  }
};

export const regenerateReviewResponseGPT = async (locationId, reviewId, token, additionalInstructions = "") => {
  try {
    const result = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.REGENERATE_RESPONSE(locationId, reviewId),
      'POST',
      { additional_instructions: additionalInstructions },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return result;
  } catch (error) {
    console.error('Error regenerating review response:', error);
    throw error;
  }
};

export const postReviewResponse = async (locationId, reviewId, accessToken) => {
  try {
    return await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.POST_RESPONSE(locationId, reviewId),
      'PUT',
      null,
      {},
      { Authorization: `Bearer ${accessToken}` }
    );
  } catch (error) {
    console.error('Error posting review response:', error);
    throw error;
  }
};

export const getReviewById = async (locationId, reviewId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.BY_ID(locationId, reviewId),
      'GET',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error('Error fetching review by ID:', error);
    throw error;
  }
};

