import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../store/shared/auth/authSelectors";
import SuperAdminDashboard from "./dashboard/SuperAdminDashboard";
import Restaurants from './restaurants/Restaurants';
import Users from './users/Users';
import Locations from './locations/Locations';

// These will be placeholder components initially
const Analytics = () => <div>Analytics</div>;
const Subscriptions = () => <div>Subscriptions</div>;

const SuperAdmin = () => {
  const { userRole } = useSelector(selectAuth);

  return (
    <Routes>
      <Route index element={<SuperAdminDashboard />} />
      <Route path="restaurants" element={<Restaurants />} />
      <Route path="locations" element={<Locations />} />
      <Route path="users" element={<Users />} />
      <Route path="analytics" element={<Analytics />} />
      <Route path="subscriptions" element={<Subscriptions />} />
    </Routes>
  );
};

export default SuperAdmin;
