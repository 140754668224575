import React from 'react';
import DataTable from '../../../../components/common/DataTable';
import { useSelector } from 'react-redux';
import { selectLoading } from '../../../../store/shared/user/userSlice';
import { selectLocationName, selectLocations } from '../../../../store/shared/location/locationSelectors';
import { selectRestaurants } from '../../../../store/slices/restaurantSlice';
import { Pencil, Trash2 } from 'lucide-react';

// Separate component for location cell
const LocationCell = ({ locationId }) => {
  const locationName = useSelector(state => selectLocationName(state, locationId));
  return <span>{locationName}</span>;
};

// Component for restaurant cell
const RestaurantCell = ({ locationId }) => {
  const locations = useSelector(selectLocations);
  const restaurants = useSelector(selectRestaurants);
  
  const location = locations.find(loc => loc.id === locationId);
  const restaurant = restaurants.find(rest => rest.id === location?.restaurant_id);
  
  return <span>{restaurant?.name || 'Unknown'}</span>;
};

// New component for action buttons
const ActionButtons = ({ onEdit, onDelete }) => {
  const handleClick = (e, action) => {
    e.stopPropagation(); // Prevent row click when clicking buttons
    action();
  };

  return (
    <div className="flex gap-2">
      <button
        onClick={(e) => handleClick(e, onEdit)}
        className="text-blue-500 hover:text-blue-700 transition-colors p-1"
      >
        <Pencil size={16} />
      </button>
      <button
        onClick={(e) => handleClick(e, onDelete)}
        className="text-red-500 hover:text-red-700 transition-colors p-1"
      >
        <Trash2 size={16} />
      </button>
    </div>
  );
};

const UserList = ({ users, onEditUser }) => {
  const loading = useSelector(selectLoading);

  const columns = [
    { 
      key: 'username',
      label: 'Username',
      sortable: true
    },
    { 
      key: 'email',
      label: 'Email',
      sortable: true
    },
    { 
      key: 'name',
      label: 'Name',
      sortable: true
    },
    { 
      key: 'location',
      label: 'Location',
      sortable: true
    },
    { 
      key: 'restaurant',
      label: 'Restaurant',
      sortable: true
    },
    { 
      key: 'roles',
      label: 'Role(s)',
      sortable: false
    },
    {
      key: 'actions',
      label: 'Actions',
      sortable: false
    }
  ];

  const customCellRenderers = {
    name: (_, user) => `${user.firstname} ${user.lastname}`,
    location: (_, user) => <LocationCell locationId={user.location_id} />,
    restaurant: (_, user) => <RestaurantCell locationId={user.location_id} />,
    roles: (roles) => roles?.map(role => role.name).join(', ') || 'N/A',
    actions: (_, user) => (
      <ActionButtons 
        onEdit={() => onEditUser(user)}
        onDelete={() => {/* Add delete handler */}}
      />
    )
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <DataTable 
        data={users || []}
        columns={columns}
        customCellRenderers={customCellRenderers}
        defaultSortKey="username"
        defaultSortDirection="asc"
        searchPlaceholder="Search users..."
        noDataMessage="No users found"
        isLoading={loading}
        onRowClick={(user) => onEditUser(user)}
        rowClassName="cursor-pointer hover:bg-gray-50"
      />
    </div>
  );
};

export default UserList; 