import React, { useState } from 'react';
import { Check, AlertCircle, Bot, BadgeCheck, Crown, Zap, PlusCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

const PricingCard = ({ 
    tier, 
    price, 
    description, 
    features,
    isPopular,
    isEnterprise,
    isComingSoon
}) => {
    const [isAnnual, setIsAnnual] = useState(false);
    
    // Calculate monthly and annual prices - only if not Enterprise
    const monthlyPrice = price;
    const annualMonthlyPrice = price ? ((price * 12 * 0.9) / 12).toFixed(0) : null;
    const displayPrice = isAnnual ? annualMonthlyPrice : monthlyPrice;

    return (
        <div className={`
            bg-white rounded-lg shadow-sm p-8 relative 
            ${isPopular ? 'ring-2 ring-blue-600 shadow-lg' : ''}
        `}>
            {isComingSoon && (
                <div className="absolute inset-0 bg-white/60 backdrop-blur-[1px] rounded-lg z-20 flex flex-col items-center justify-center">
                    <span className="bg-gray-900/90 text-white px-4 py-2 rounded-full text-sm font-medium">
                        Coming Soon
                    </span>
                </div>
            )}
            <div className="relative">
                <h3 className="text-2xl font-bold mb-2">{tier}</h3>
                <div className="mb-4">
                    {isEnterprise ? (
                        <div className="text-4xl font-bold">Contact Sales</div>
                    ) : (
                        <>
                            <span className="text-4xl font-bold">${displayPrice}</span>
                            <span className="text-gray-600 ml-2">/month per location</span>
                            {isAnnual && (
                                <div className="text-sm text-green-600 mt-1">
                                    Save 10% annually
                                </div>
                            )}
                        </>
                    )}
                </div>
                <p className="text-gray-600 mb-6">{description}</p>
                
                {!isEnterprise && (
                    <div className="mb-6">
                        <div className="w-full max-w-xs mx-auto bg-gray-100 rounded-full p-1">
                            <div className="relative flex">
                                <button 
                                    onClick={() => setIsAnnual(false)}
                                    className={`flex-1 relative z-10 py-2 px-4 text-sm font-medium rounded-full transition-colors duration-200
                                        ${!isAnnual ? 'text-white' : 'text-gray-600'}`}
                                >
                                    Monthly
                                </button>
                                <button 
                                    onClick={() => setIsAnnual(true)}
                                    className={`flex-1 relative z-10 py-2 px-4 text-sm font-medium rounded-full transition-colors duration-200
                                        ${isAnnual ? 'text-white' : 'text-gray-600'}`}
                                >
                                    Annual (10% off)
                                </button>
                                <div 
                                    className={`absolute inset-y-0 w-1/2 bg-blue-600 rounded-full transition-transform duration-200 transform
                                        ${isAnnual ? 'translate-x-full' : 'translate-x-0'}`}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="space-y-4 mb-8">
                    {Object.entries(features).map(([category, items]) => (
                        <div key={category}>
                            <h4 className="font-semibold text-gray-900 mb-2">{category}</h4>
                            <ul className="space-y-2">
                                {items.map((feature) => (
                                    <li key={feature} className="flex items-start">
                                        <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                                        <span className="text-gray-700">{feature}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                {isEnterprise ? (
                    <Link 
                        to="/enterprise-quote"
                        className="w-full py-3 rounded-lg font-medium transition-colors duration-200 bg-gray-900 text-white hover:bg-gray-800 inline-block text-center"
                    >
                        Contact Sales
                    </Link>
                ) : (
                    <button 
                        disabled={isComingSoon}
                        onClick={() => !isComingSoon && (window.location.href = '/onboarding')}
                        className={`
                            w-full py-3 rounded-lg font-medium transition-colors duration-200
                            ${isPopular 
                                ? 'bg-blue-600 text-white hover:bg-blue-700' 
                                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'}
                            ${isComingSoon ? 'opacity-50 cursor-not-allowed' : ''}
                        `}
                    >
                        {isComingSoon ? 'Coming Soon' : 'Get Started'}
                    </button>
                )}
            </div>
        </div>
    );
};
  

const PricingPage = () => {
  const plans = [
    {
      tier: "Growth",
      price: 79,
      description: "Perfect for single restaurants and small chains looking to manage their online reputation effectively.",
      features: {
        "Review Management": [
          "Unlimited AI-generated replies",
          "Review metrics and analytics",
          "Location-based performance tracking"
        ],
        "Basic Alerts": [
          "1-star review notifications",
          "Basic alert customization"
        ],
        "Staff Insights": [
          "Staff performance tracking",
          "Employee mention monitoring",
          "Staff feedback analysis"
        ],
        "Menu Analytics": [
          "Menu item performance tracking",
          "Dish mention monitoring",
          "Menu feedback insights"
        ]
      }
    },
    {
      tier: "Essential",
      price: 119,
      isPopular: true,
      description: "Optimal for established restaurants looking to elevate their customer experience with advanced AI insights and analytics.",
      features: {
        "Everything in Growth": [
          "✨ All features from the Growth package"
        ],
        "Advanced Review Management": [
          "Advanced AI reply tone customization",
          "Comprehensive review analytics"
        ],
        "Enhanced Analytics": [
          "Service aspect keyword analysis (Food, Atmosphere, Service)",
          "Sentiment trend analysis"
        ],
        "Alert System": [
          "1-star review notifications"
        ],
        "Reporting & Insights": [
          "Monthly email performance reports",
          "Custom report generation"
        ],
        "Support": [
          "Priority customer support"
        ]
      }
    },
    {
      tier: "Pro",
      price: 179,
      description: "Ideal for growing restaurants requiring advanced analytics and multi-platform management.",
      isComingSoon: true,
      features: {
        "Everything in Growth:": [
          "✨ All features from the Essential package"
        ],
        "Advanced Review Management": [
          "Multi-platform integration (Yelp, TripAdvisor, RestoMontreal, etc.)",
        ],
        "Enhanced Analytics": [
          "Cross-platform performance metrics"
        ],
        "Advanced Alert System": [
          "Negative staff mentions alerts",
          "Poor menu item feedback alerts",
          "Google policy violation detection"
        ],
        "Reporting & Insights": [
          "Custom report scheduling (Daily/Weekly)",
        ],
        "Premium Features": [
          "Scarlet AI Agent access",
          "Early access to beta features"
        ]
      }
    },
    {
      tier: "Enterprise",
      isEnterprise: true,
      description: "Custom pricing based on needs. Ideal for franchisors to have an overview of each location's franchisees and performance.",
      features: {
        "Everything in Pro:": [
          "✨ All features from the Pro package"
        ],
        "Advanced Integrations": [
          "POS system integration",
          "Delivery app tracker integration",
          "Reservation system integration"
        ],
        "Enterprise Features": [
          "Custom feature development",
          "API access",
          "White-label options"
        ],
        "Premium Support": [
          "Dedicated account manager",
          "Personalized onboarding sessions",
          "24/7 priority support"
        ]
      }
    }
  ];

  // Separate regular plans from enterprise
  const regularPlans = plans.filter(plan => !plan.isEnterprise);
  const enterprisePlan = plans.find(plan => plan.isEnterprise);

  return (
    <div className="min-h-screen overflow-x-hidden">
      <div className="min-h-screen bg-gray-50">
        {/* Hero Section */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Simple, Transparent Pricing
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Choose the plan that's right for your restaurant. Scale as you grow.
            </p>
          </div>
        </div>

        {/* Regular Plans Grid */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid md:grid-cols-3 gap-8">
            {regularPlans.map((plan) => (
              <PricingCard key={plan.tier} {...plan} />
            ))}
          </div>

          {/* Enterprise Section */}
          <div className="mt-16 bg-white rounded-xl p-8 shadow-sm">
            <div className="text-center max-w-3xl mx-auto">
              <h3 className="text-2xl font-bold mb-4">{enterprisePlan.tier}</h3>
              <p className="text-gray-600 mb-6">{enterprisePlan.description}</p>
              
              <div className="grid md:grid-cols-2 gap-6 mb-8">
                {Object.entries(enterprisePlan.features).map(([category, items]) => (
                  items.map((feature) => (
                    <div key={feature} className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                      <span className="text-gray-700">{feature}</span>
                    </div>
                  ))
                ))}
              </div>

              <Link 
                to="/enterprise-quote"
                className="px-8 py-4 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors inline-block"
              >
                Contact Sales
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;