import { createSlice } from '@reduxjs/toolkit';
import { getCurrentDate,} from '../../../features/dateFilter/utils/dateFilterUtils';
import { DATE_CONFIGURATIONS, SECTIONS } from '../../../features/dateFilter/config/dateConfig';

// Initialize state with all sections
export const initialState = Object.entries(SECTIONS).reduce((acc, [section, config]) => {
  const preset = DATE_CONFIGURATIONS[config.defaultPreset];
  const dates = preset?.getDates(getCurrentDate());
  
  acc[section.toLowerCase()] = {
    current: dates?.current || dates,
    comparison: dates?.comparison || null,
    preset: config.defaultPreset
  };
  
  return acc;
}, {});

const normalizeSection = (section) => section?.toLowerCase?.() || section;

export const dateFilterSlice = createSlice({
  name: 'dateFilter',
  initialState,
  reducers: {
    setDateFilter: (state, action) => {
      const { section, dates, comparison } = action.payload;
      const normalizedSection = normalizeSection(section);
      
      if (!state[normalizedSection]) {
        state[normalizedSection] = {};
      }

      if (comparison) {
        // Comparison mode
        state[normalizedSection] = {
          current: dates.current || dates,
          comparison: comparison,
          preset: null
        };
      } else {
        // Simple mode
        state[normalizedSection] = {
          current: dates,
          comparison: null,
          preset: null
        };
      }
    },
    setDatePreset: (state, action) => {
      const { section, preset } = action.payload;

      const config = DATE_CONFIGURATIONS[preset];
      if (!config) {
        console.error(`Preset "${preset}" not found in DATE_CONFIGURATIONS.`);
        return;
      }

      const dates = config.getDates(getCurrentDate());

      if (!state[section]) {
        state[section] = {};
      }

      state[section] = {
        current: dates.current || dates,
        comparison: dates.comparison || null,
        preset
      };
    },
    resetDateFilter: (state, action) => {
      const section = normalizeSection(action.payload);
      if (initialState[section]) {
        state[section] = { ...initialState[section] };
      } else {
        console.warn(`No initial state found for section: ${section}`);
      }
    }
  }
});

export const { setDateFilter, setDatePreset, resetDateFilter } = dateFilterSlice.actions;
export const dateFilterReducer = dateFilterSlice.reducer;
export default dateFilterSlice.reducer;