// src/components/shadcn/SidebarItem.js
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const SidebarItem = ({ to, icon: Icon, label, description, className, onClick, children }) => {
  const baseClasses = "flex items-center justify-between py-2 px-3 text-sm rounded-md hover:bg-sidebar-accent hover:text-sidebar-accent-foreground w-full";
  
  const content = (
    <>
      <div className="flex items-center">
        {Icon && <Icon className="h-4 w-4 mr-2" />}
        <div className="font-medium">{label}</div>
      </div>
      {children}
    </>
  );

  if (onClick) {
    return (
      <button onClick={onClick} className={`${baseClasses} ${className}`}>
        {content}
      </button>
    );
  }

  return (
    <Link to={to} className={`${baseClasses} ${className}`}>
      {content}
    </Link>
  );
};

SidebarItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

SidebarItem.defaultProps = {
  icon: null,
  description: '',
  className: '',
  onClick: null,
  children: null,
};

export default SidebarItem;