import React from 'react';
import { MetricCard } from '../dashboard/MetricCard';
import SuperAdminSidebar from './SuperAdminSidebar';
import { 
  Store, 
  MapPin, 
  Users, 
  Star, 
  User, 
  Utensils 
} from 'lucide-react';

const SuperAdminDashboard = () => {
  return (
    <div className="flex min-h-screen bg-gray-50">
      <SuperAdminSidebar />
      <div className="flex-1 ml-64 p-8">
        <h1 className="text-2xl font-bold mb-6">System Overview</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <MetricCard 
            title="Active Restaurants"
            value="124"
            change="+12%"
            Icon={Store}
          />
          <MetricCard 
            title="Total Locations"
            value="342"
            change="+8%"
            Icon={MapPin}
          />
          <MetricCard 
            title="Active Users"
            value="1,234"
            change="+15%"
            Icon={Users}
          />
          <MetricCard 
            title="Total Reviews"
            value="5,678"
            change="+5%"
            Icon={Star}
          />
          <MetricCard 
            title="Staff Mentions"
            value="892"
            change="+3%"
            Icon={User}
          />
          <MetricCard 
            title="Menu Mentions"
            value="1,567"
            change="+7%"
            Icon={Utensils}
          />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
