import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { 
  selectSelectedRestaurant,
  selectAccessToken 
} from '../../../store/shared/auth/authSelectors';
import { 
  getSelfAlertConfig, 
  updateAlertConfig 
} from '../../../services/api/apiUtility';

export const useAccountSettings = () => {
  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const token = useSelector(selectAccessToken);
  const [alertConfig, setAlertConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAlertConfig = async () => {
    if (!selectedRestaurant?.id) {
      setLoading(false);
      return;
    }
    
    try {
      setLoading(true);
      const response = await getSelfAlertConfig(selectedRestaurant.id, token);
      setAlertConfig(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAlertConfig();
  }, [selectedRestaurant?.id, token]);

  const updateUserAlertConfig = async (newConfig) => {
    if (!selectedRestaurant?.id) return false;

    try {
      await updateAlertConfig(selectedRestaurant.id, newConfig, token);
      setAlertConfig(newConfig);
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    }
  };

  return {
    alertConfig,
    loading,
    error,
    updateUserAlertConfig,
  };
};
