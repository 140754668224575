import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  MapPin, Store, Search, PlusCircle, AlertCircle, ChevronDown
} from 'lucide-react';
import { MetricCard } from '../dashboard/MetricCard';
import SuperAdminSidebar from '../dashboard/SuperAdminSidebar';
import { Toaster, toast } from 'sonner';
import LocationCard from './LocationCard';
import LocationCreationModal from './LocationCreationModal';
import { 
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from '../../../../components/shadcn/collapsible/Collapsible';
import { 
  fetchLocations,
  addLocation,
  deleteLocation,
  updateLocation 
} from '../../../../store/shared/location/locationThunks';
import { 
  selectLocations,
  selectLocationLoading,
  selectLocationError 
} from '../../../../store/shared/location/locationSelectors';
import { selectRestaurants } from '../../../../store/slices/restaurantSlice';
import { fetchRestaurants } from '../../../../store/slices/restaurantSlice';

const Locations = () => {
  const dispatch = useDispatch();
  const locations = useSelector(selectLocations);
  const loading = useSelector(selectLocationLoading);
  const error = useSelector(selectLocationError);
  const restaurants = useSelector(selectRestaurants);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRestaurant, setSelectedRestaurant] = useState('');

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchRestaurants());
  }, [dispatch]);

  const handleAddLocation = () => {
    setIsModalOpen(true);
  };

  const handleDeleteLocation = async (id) => {
    try {
      await dispatch(deleteLocation(id)).unwrap();
      toast.success('Location deleted successfully!', {
        duration: 3000,
        className: 'bg-green-500',
      });
    } catch (error) {
      toast.error(error.message || 'Failed to delete location', {
        duration: 3000,
      });
    }
  };

  const handleEditLocation = async (id, updatedData) => {
    try {
      await dispatch(updateLocation({ id, locationData: updatedData })).unwrap();
      toast.success('Location updated successfully!', {
        duration: 3000,
        className: 'bg-green-500',
      });
    } catch (error) {
      toast.error(error.message || 'Failed to update location', {
        duration: 3000,
      });
    }
  };

  const filteredLocations = locations
    .filter(location => location.name !== "All")
    .filter(location => 
      location.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(location => 
      selectedRestaurant ? location.restaurant_id === selectedRestaurant : true
    );

  const totalLocations = locations.filter(location => location.name !== "All").length;
  const locationsWithReviews = locations.filter(l => l.review_count > 0).length;
  const locationsWithStaffMentions = locations.filter(l => l.staff_mention_count > 0).length;

  const handleModalClose = async () => {
    setIsModalOpen(false);
    await dispatch(fetchLocations());
  };

  if (loading) {
    return (
      <div className="flex">
        <SuperAdminSidebar />
        <div className="flex-1 p-8">
          <div className="animate-pulse">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-gray-50">
      <SuperAdminSidebar />
      <div className="flex-1 ml-64 p-8">
        <Toaster position="top-right" richColors />
        <h1 className="text-2xl font-bold mb-6">Location Management</h1>
        
        {/* Search, Filter, and Add Section */}
        <div className="mb-8 space-y-4">
          <div className="flex gap-4">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Search locations..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-3 pr-10 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-300 focus:border-blue-500 transition"
              />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
            <Collapsible className="w-64">
              <CollapsibleTrigger className="flex items-center justify-between w-full p-3 border rounded-lg shadow-sm hover:bg-gray-50">
                <span className="flex items-center gap-2">
                  <Store size={20} />
                  {selectedRestaurant 
                    ? restaurants.find(r => r.id === selectedRestaurant)?.name || 'All Restaurants'
                    : 'All Restaurants'
                  }
                </span>
                <ChevronDown className="h-4 w-4" />
              </CollapsibleTrigger>
              <CollapsibleContent className="absolute z-10 mt-1 w-64 bg-white border rounded-lg shadow-lg">
                <div className="p-2">
                  <button
                    onClick={() => setSelectedRestaurant('')}
                    className={`w-full text-left px-4 py-2 rounded hover:bg-gray-100 ${!selectedRestaurant ? 'bg-blue-50 text-blue-600' : ''}`}
                  >
                    All Restaurants
                  </button>
                  {restaurants.map((restaurant) => (
                    <button
                      key={restaurant.id}
                      onClick={() => setSelectedRestaurant(restaurant.id)}
                      className={`w-full text-left px-4 py-2 rounded hover:bg-gray-100 ${selectedRestaurant === restaurant.id ? 'bg-blue-50 text-blue-600' : ''}`}
                    >
                      {restaurant.name}
                    </button>
                  ))}
                </div>
              </CollapsibleContent>
            </Collapsible>
            <button 
              onClick={handleAddLocation}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300 ease-in-out flex items-center"
              disabled={loading}
            >
              <PlusCircle size={20} className="mr-2" />
              Add
            </button>
          </div>
          {error && (
            <div className="text-red-500 flex items-center">
              <AlertCircle className="w-4 h-4 mr-2" />
              {error}
            </div>
          )}
        </div>

        {/* Metrics Overview */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <MetricCard 
            title="Total Locations"
            value={totalLocations}
            Icon={MapPin}
          />
          <MetricCard 
            title="With Reviews"
            value={locationsWithReviews}
            Icon={Store}
          />
          <MetricCard 
            title="With Staff Mentions"
            value={locationsWithStaffMentions}
            Icon={Store}
          />
        </div>

        {/* Locations Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredLocations.map((location) => (
            <LocationCard 
              key={location.id}
              location={location}
              restaurant={restaurants.find(r => r.id === location.restaurant_id)}
              onDelete={handleDeleteLocation}
              onEdit={handleEditLocation}
            />
          ))}
        </div>

        <LocationCreationModal 
          open={isModalOpen}
          onClose={handleModalClose}
          restaurants={restaurants}
        />
      </div>
    </div>
  );
};

export default Locations;
