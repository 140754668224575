import { createSelector } from '@reduxjs/toolkit';
import { getCurrentDate } from '../../../features/dateFilter/utils/dateFilterUtils';
import { DATE_CONFIGURATIONS } from '../../../features/dateFilter/config/dateConfig';

// Base selector
const selectDateFilterState = state => state.dateFilter;

// Selector for specific section
export const selectDateFilter = (section) => createSelector(
  [selectDateFilterState],
  (dateFilterState) => {
    try {
      const normalizedSection = section?.toLowerCase?.() || section;
      const sectionFilter = dateFilterState[normalizedSection];

      if (!sectionFilter) {
        console.warn(`No date filter found for section: ${normalizedSection}`);
        return null;
      }

      if (!sectionFilter?.current) {
        const presetKey = sectionFilter?.preset;
        if (presetKey) {
          const preset = DATE_CONFIGURATIONS[presetKey];
          if (preset && preset.getDates) {
            const derivedDates = preset.getDates(getCurrentDate());
            return derivedDates;
          }
        }
        return null;
      }

      return sectionFilter.current;
    } catch (error) {
      console.error(`Error selecting date filter for section ${section}:`, error);
      return null;
    }
  }
);

// Memoized selector for comparison date filter
export const selectDateFilterWithComparison = (section) => createSelector(
  [selectDateFilterState],
  (dateFilterState) => {
    const normalizedSection = section?.toLowerCase?.() || section;
    const sectionFilter = dateFilterState[normalizedSection];
    if (!sectionFilter?.current || !sectionFilter?.comparison) return null;
    return {
      current: sectionFilter.current,
      comparison: sectionFilter.comparison
    };
  }
);

export const selectFilteredData = createSelector(
  [
    (state, section) => selectDateFilter(section)(state),
    (state, section) => state[section]?.data || []
  ],
  (dateFilter, data) => {
    if (!dateFilter || !data.length) return [];
    
    const startDate = new Date(dateFilter.startDate);
    const endDate = new Date(dateFilter.endDate);
    
    return data.filter(item => {
      const itemDate = new Date(item.date || item.review_date || item.created_at);
      return itemDate >= startDate && itemDate <= endDate;
    });
  }
);
