import React, { useRef, useEffect } from 'react';

const NetworkBackground = ({ 
  color = 'rgba(37, 99, 235, 0.15)', // Slightly increased opacity
  pointColor = 'rgba(37, 99, 235, 0.7)', // More visible points
  pointCount = 50,
  maxDistance = 250,
  speedFactor = 0.3,
  className = "",
  backgroundColor = "rgb(243, 244, 255)", // Light blue tinted background
  pointSize = 3, // New prop for point size
  lineWidth = 1, // New prop for line width
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let animationFrameId;

    const resizeCanvas = () => {
      const dpr = window.devicePixelRatio || 1;
      canvas.width = window.innerWidth * dpr;
      canvas.height = window.innerHeight * dpr;
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${window.innerHeight}px`;
      ctx.scale(dpr, dpr); // Scale for retina displays
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    // Add slight randomness to point sizes
    const points = Array.from({ length: pointCount }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      vx: (Math.random() - 0.5) * 2 * speedFactor,
      vy: (Math.random() - 0.5) * 2 * speedFactor,
      size: pointSize * (0.8 + Math.random() * 0.4), // Vary point sizes slightly
    }));

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Draw connections first (behind points)
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = color;

      points.forEach((point, i) => {
        point.x += point.vx;
        point.y += point.vy;

        // Bounce off edges
        if (point.x < 0 || point.x > window.innerWidth) point.vx *= -1;
        if (point.y < 0 || point.y > window.innerHeight) point.vy *= -1;

        // Draw connections
        for (let j = i + 1; j < points.length; j++) {
          const otherPoint = points[j];
          const dx = otherPoint.x - point.x;
          const dy = otherPoint.y - point.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < maxDistance) {
            ctx.beginPath();
            ctx.moveTo(point.x, point.y);
            ctx.lineTo(otherPoint.x, otherPoint.y);
            
            // Enhanced connection opacity calculation
            const opacity = Math.pow(1 - distance / maxDistance, 2); // Squared for smoother falloff
            ctx.globalAlpha = opacity;
            ctx.stroke();
          }
        }
      });

      // Draw points on top with a subtle glow effect
      points.forEach(point => {
        ctx.globalAlpha = 1;
        ctx.fillStyle = pointColor;
        
        // Add subtle glow
        const gradient = ctx.createRadialGradient(
          point.x, point.y, 0,
          point.x, point.y, point.size * 2
        );
        gradient.addColorStop(0, pointColor);
        gradient.addColorStop(1, 'rgba(37, 99, 235, 0)');
        
        ctx.beginPath();
        ctx.arc(point.x, point.y, point.size, 0, Math.PI * 2);
        ctx.fill();

        // Draw the actual point
        ctx.fillStyle = pointColor;
        ctx.beginPath();
        ctx.arc(point.x, point.y, point.size * 0.7, 0, Math.PI * 2);
        ctx.fill();
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, [color, pointColor, pointCount, maxDistance, speedFactor, pointSize, lineWidth]);

  return (
    <canvas 
      ref={canvasRef} 
      className={`fixed inset-0 -z-10 ${className}`}
      style={{ backgroundColor }}
    />
  );
};

export default NetworkBackground;