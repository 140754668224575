import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import {
  getLocationKeywordOccurrences,
  getLocationKeywordsByCategory,
  getLocationKeywordDetails,
  getRestaurantKeywordOccurrences,
  getRestaurantKeywordsByCategory,
  getRestaurantKeywordDetails,
} from '../../features/keywords/services/keywords.api';
import { startOfDay, parseISO, endOfDay, isWithinInterval } from 'date-fns';
import { selectDateFilter } from '../shared/dateFilter/dateFilterSelectors';


// Add cache duration constant
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Add cache key generator helper
export const generateCacheKey = (locationId, dateFilter) => {
  return `${locationId}-${dateFilter?.startDate}-${dateFilter?.endDate}`;
};

// Helper function to validate keyword occurrence
const isValidKeywordOccurrence = (occurrence) => {
  return Boolean(
    occurrence.keyword?.trim() && 
    occurrence.category?.trim() && 
    occurrence.snippet?.trim() && 
    occurrence.sentiment?.trim()
  );
};

export const fetchKeywordOccurrences = createAsyncThunk(
  'keywords/fetchOccurrences',
  async ({ locationId, dateFilter }, { getState, rejectWithValue }) => {
    const state = getState();
    const { accessToken } = state.auth;
    
    if (!accessToken) {
      return rejectWithValue('Authentication required. Please log in.');
    }

    // Check cache first
    const cacheKey = generateCacheKey(locationId, dateFilter);
    const cachedData = state.keywords.cache[cacheKey];
    
    if (cachedData) {
      const isExpired = Date.now() - cachedData.timestamp > CACHE_DURATION;
      if (!isExpired) {
        return { data: cachedData.data, cacheKey };
      }
    }

    try {
      const response = await getLocationKeywordOccurrences(
        locationId,
        accessToken,
        { dateFilter }
      );

      // Filter out invalid occurrences before storing
      const validData = response.data.filter(isValidKeywordOccurrence);

      return { data: validData, cacheKey };
    } catch (error) {
      const errorMessage = error.response?.status === 401 
        ? 'Your session has expired. Please log in again.'
        : error.response?.data?.message || error.message || 'Failed to fetch keywords';
      
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchKeywordByCategory = createAsyncThunk(
  'keywords/fetchByCategory',
  async ({ category, locationId, dateFilter }, {getState, rejectWithValue }) => {
    const state = getState();
    const { accessToken } = state.auth;
    if(!accessToken) return rejectWithValue("Access token required");
    
    try {
      const token = '';
      const response = await getLocationKeywordsByCategory(locationId, category, accessToken, { dateFilter });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchKeywordDetails = createAsyncThunk(
  'keywords/fetchDetails',
  async ({ locationId, keyword }, { getState, rejectWithValue }) => {
    const state = getState();
    const { accessToken } = state.auth;
    if(!accessToken) return rejectWithValue("Access token required");

    try {
      const response = await getLocationKeywordDetails(locationId, keyword, accessToken);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const keywordsSlice = createSlice({
  name: 'keywords',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    selectedKeywordDetails: null,
    cache: {},
    lastUpdated: null,
    detailsLoading: false,
    detailsError: null,
  },
  reducers: {
    setSelectedKeywordDetails(state, action) {
      state.selectedKeywordDetails = action.payload;
    },
    clearSelectedKeywordDetails(state) {
      state.selectedKeywordDetails = null;
    },
    clearCache: (state) => {
      state.cache = {};
    },
    invalidateCache: (state, action) => {
      const cacheKey = action.payload;
      delete state.cache[cacheKey];
    },
    clearKeywords: (state) => {
      state.data = [];
      state.status = 'idle';
      state.error = null;
      state.selectedKeywordDetails = null;
    },
    clearKeywordDetails: (state) => {
      state.selectedKeywordDetails = null;
    },
    setKeywordDetails: (state, action) => {
      state.selectedKeywordDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeywordOccurrences.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchKeywordOccurrences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { data, cacheKey } = action.payload;
        state.data = data;
        state.lastUpdated = Date.now();
        
        // Update cache
        if (cacheKey) {
          state.cache[cacheKey] = {
            data,
            timestamp: Date.now(),
          };
        }
      })
      .addCase(fetchKeywordOccurrences.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchKeywordByCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchKeywordByCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchKeywordByCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchKeywordDetails.pending, (state) => {
        state.detailsLoading = true;
        state.detailsError = null;
      })
      .addCase(fetchKeywordDetails.fulfilled, (state, action) => {
        state.detailsLoading = false;
        state.selectedKeywordDetails = action.payload;
      })
      .addCase(fetchKeywordDetails.rejected, (state, action) => {
        state.detailsLoading = false;
        state.detailsError = action.payload;
      });
  },
});

export const { setSelectedKeywordDetails, clearSelectedKeywordDetails, clearKeywords, setKeywordDetails } = keywordsSlice.actions;

export const selectKeywords = (state) => state.keywords.data;
export const selectKeywordStatus = (state) => state.keywords.status;
export const selectKeywordError = (state) => state.keywords.error;
export const selectSelectedKeywordDetails = (state) => state.keywords.selectedKeywordDetails;
export const selectKeywordsCache = (state) => state.keywords.cache;
export const selectKeywordsLastUpdated = (state) => state.keywords.lastUpdated;

export const selectKeywordsData = state => state.keywords.data;
export const selectKeywordsLoading = state => state.keywords.status === 'loading';
export const selectKeywordsError = state => state.keywords.error;

// Add this selector for filtered keywords
export const selectFilteredKeywords = createSelector(
  [
    selectKeywordsData,
    (_, category) => category,
    selectDateFilter('KEYWORDS')
  ],
  (keywords, category, dateFilter) => {
    if (!Array.isArray(keywords)) return [];

    let filtered = keywords;

    // Filter by category if not 'All'
    if (category && category !== 'All') {
      filtered = filtered.filter(item => item.category === category);
    }

    return filtered;
  }
);

export const selectKeywordDetails = (state) => state.keywords.selectedKeywordDetails;
export const selectKeywordDetailsLoading = (state) => state.keywords.detailsLoading;

export default keywordsSlice.reducer;
