import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { getGoogleReviews, getStaffMentions, getMenuInsights } from '../../services/api/apiUtility';
import { selectDateFilterWithComparison } from '../shared/dateFilter/dateFilterSelectors';
import { format } from 'date-fns';

const initialState = {
  reviews: {
    current: [],
    comparison: [],
    loading: false,
    error: null
  },
  staff: {
    current: [],
    comparison: [],
    loading: false,
    error: null
  },
  menu: {
    current: [],
    comparison: [],
    loading: false,
    error: null
  }
};

// Thunks
export const fetchTrendReviews = createAsyncThunk(
  'trend/fetchTrendReviews',
  async ({ current, comparison, locationId }, { getState, rejectWithValue }) => {
    const state = getState();
    const { accessToken } = state.auth;
    if(!accessToken) return rejectWithValue("Access token required");
    if(!current || !comparison || !locationId) return rejectWithValue("Missing required data");

    try {
      // Fetch both ranges in parallel
      const [currentReviews, comparisonReviews] = await Promise.all([
        getGoogleReviews(
          locationId || 'all',
          0,
          1000,
          accessToken,
          current.startDate,
          current.endDate
        ),
        getGoogleReviews(
          locationId || 'all',
          0,
          1000,
          accessToken,
          comparison.startDate,
          comparison.endDate
        )
      ]);

      return {
        current: currentReviews.data || [],
        comparison: comparisonReviews.data || []
      };
    } catch (error) {
      console.error('Error fetching trend reviews:', error);
      return rejectWithValue(error.message || "Failed to fetch trend reviews");
    }
  }
);

// Add similar thunks for staff and menu
export const fetchTrendStaff = createAsyncThunk(
  'trend/fetchTrendStaff',
  async ({ current, comparison, locationId }, { getState, rejectWithValue }) => {
    const state = getState();
    const { accessToken } = state.auth;

    if (!accessToken) return rejectWithValue("Access token required");
    if (!current || !comparison || !locationId) {
      return rejectWithValue("Missing required data");
    }

    try {
      const [currentStaff, comparisonStaff] = await Promise.all([
        getStaffMentions(
          locationId || 'all',
          0,
          1000,
          accessToken,
          current.startDate,
          current.endDate
        ),
        getStaffMentions(
          locationId || 'all',
          0,
          1000,
          accessToken,
          comparison.startDate,
          comparison.endDate
        )
      ]);

      return {
        current: currentStaff.data || [],
        comparison: comparisonStaff.data || []
      };
    } catch (error) {
      console.error('Error fetching trend staff:', error);
      return rejectWithValue(error.message || "Failed to fetch trend staff");
    }
  }
);

export const fetchTrendMenu = createAsyncThunk(
  'trend/fetchTrendMenu',
  async ({ current, comparison, locationId }, { getState, rejectWithValue }) => {
    const state = getState();
    const { accessToken } = state.auth;

    if (!accessToken) throw new Error("Access token required");
    if (!current || !comparison) {
      return rejectWithValue("Both current and comparison date ranges are required");
    }

    try {
      const [currentMenu, comparisonMenu] = await Promise.all([
        getMenuInsights(    
          locationId || 'all',
          0,
          1000,
          accessToken,
          current.startDate,
          current.endDate
        ),
        getMenuInsights(
          locationId || 'all',
          0,
          1000,
          accessToken,
          comparison.startDate,
          comparison.endDate
        )
      ]);

      return {
        current: currentMenu.data || [],
        comparison: comparisonMenu.data || []
      };
    } catch (error) {
      console.error('Error fetching menu insights:', error);
      return rejectWithValue(error.message || "Failed to fetch menu insights");
    }
  }
);

const trendSlice = createSlice({
  name: 'trend',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Reviews
      .addCase(fetchTrendReviews.pending, (state) => {
        state.reviews.loading = true;
        state.reviews.error = null;
      })
      .addCase(fetchTrendReviews.fulfilled, (state, action) => {
        state.reviews.loading = false;
        state.reviews.current = action.payload.current;
        state.reviews.comparison = action.payload.comparison;
      })
      .addCase(fetchTrendReviews.rejected, (state, action) => {
        state.reviews.loading = false;
        state.reviews.error = action.payload || action.error.message;
      })
      // Staff
      .addCase(fetchTrendStaff.pending, (state) => {
        state.staff.loading = true;
        state.staff.error = null;
      })
      .addCase(fetchTrendStaff.fulfilled, (state, action) => {
        state.staff.loading = false;
        state.staff.current = action.payload.current;
        state.staff.comparison = action.payload.comparison;
      })
      .addCase(fetchTrendStaff.rejected, (state, action) => {
        state.staff.loading = false;
        state.staff.error = action.payload || action.error.message;
      })
      // Menu
      .addCase(fetchTrendMenu.pending, (state) => {
        state.menu.loading = true;
        state.menu.error = null;
      })
      .addCase(fetchTrendMenu.fulfilled, (state, action) => {
        state.menu.loading = false;
        state.menu.current = action.payload.current;
        state.menu.comparison = action.payload.comparison;
      })
      .addCase(fetchTrendMenu.rejected, (state, action) => {
        state.menu.loading = false;
        state.menu.error = action.payload || action.error.message;
      });
  }
});

// Selectors
export const selectTrendState = (state) => state.trend;

export const selectTrendReviews = createSelector(
  [selectTrendState],
  (trend) => ({
    current: trend?.reviews?.current || [],
    comparison: trend?.reviews?.comparison || [],
    loading: trend?.reviews?.loading || false,
    error: trend?.reviews?.error || null
  })
);

export const selectTrendStaff = createSelector(
  [selectTrendState],
  (trend) => ({
    current: trend?.staff?.current || [],
    comparison: trend?.staff?.comparison || [],
    loading: trend?.staff?.loading || false,
    error: trend?.staff?.error || null
  })
);

export const selectTrendMenu = createSelector(
  [selectTrendState],
  (trend) => ({
    current: trend?.menu?.current || [],
    comparison: trend?.menu?.comparison || [],
    loading: trend?.menu?.loading || false,
    error: trend?.menu?.error || null
  })
);

// Add loading and error selectors
export const selectTrendLoading = createSelector(
  [selectTrendState],
  (trend) => trend?.reviews?.loading || trend?.staff?.loading || trend?.menu?.loading || false
);

export const selectTrendError = createSelector(
  [selectTrendState],
  (trend) => trend?.reviews?.error || trend?.staff?.error || trend?.menu?.error || null
);

export default trendSlice.reducer;