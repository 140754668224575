import React, { useState, useEffect, useRef } from "react";
import {
  Mail,
  MapPin,
  FileText,
  ChevronRight,
  ChevronLeft,
  Check,
  Search,
  Star,
  Clock,
  CheckCircle,
} from "lucide-react";
import Confetti from "react-confetti";
import GoogleBusinessGuide from './GoogleBusinessGuide';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

// Define the steps for the onboarding flow
const STEPS = {
  BUSINESS_INFO: "BUSINESS_INFO",
  PACKAGE_SELECTION: "PACKAGE_SELECTION",
  LOCATIONS: "LOCATIONS",
  TONE_SELECTION: "TONE_SELECTION",
  PERMISSIONS: "PERMISSIONS",
  NEXT_STEPS: "NEXT_STEPS",
};

// Define tone options for AI responses
const toneOptions = [
  {
    id: "friendly",
    label: "Friendly and Casual",
    example:
      "Thanks a ton for your awesome review! We're thrilled you loved the food and our friendly crew. Can't wait to see you again soon!",
    icon: "😊",
  },
  {
    id: "formal",
    label: "Formal and Professional",
    example:
      "Thank you for taking the time to share your positive experience. We are delighted to hear that you enjoyed both the cuisine and service. We look forward to welcoming you again soon.",
    icon: "👔",
  },
  {
    id: "fun",
    label: "Fun and Lighthearted",
    example:
      "Woohoo! We're so happy you had a great time! Our team was thrilled to serve you, and hope to see you back for more tasty bites and good vibes!",
    icon: "🎉",
  },
  {
    id: "neutral",
    label: "Neutral and Factual",
    example:
      "We appreciate your positive feedback. We are glad that you enjoyed your meal and found the service satisfactory. We hope to serve you again in the future.",
    icon: "📝",
  },
];

// Create a constant for libraries outside the component
const GOOGLE_MAPS_LIBRARIES = ['places'];

// Add this helper function near the top of the file, after the constants
const getLocationCount = (countString) => {
  switch (countString) {
    case "1":
      return 1;
    case "2-5":
      return 5;
    case "6-10":
      return 10;
    default:
      return 0;
  }
};

// Add the packages data (you can import this from your pricing component)
const packages = [
  {
    tier: "Growth",
    price: 79,
    description: "Perfect for single restaurants and small chains looking to manage their online reputation effectively.",
    features: {
      "Review Management": [
        "Unlimited AI-generated replies",
        "Review metrics and analytics",
        "Location-based performance tracking"
      ],
      "Basic Alerts": [
        "1-star review notifications",
        "Basic alert customization"
      ],
      "Staff Insights": [
        "Staff performance tracking",
        "Employee mention monitoring",
        "Staff feedback analysis"
      ],
      "Menu Analytics": [
        "Menu item performance tracking",
        "Dish mention monitoring",
        "Menu feedback insights"
      ]
    }
  },
  {
    tier: "Essential",
    price: 119,
    isPopular: true,
    description: "Perfect for established restaurants looking to elevate their customer experience with advanced AI insights and analytics.",
    features: {
      "Everything in Growth": [
        "✨ All features from the Growth package"
      ],
      "Advanced Review Management": [
        "Advanced AI reply tone customization",
        "Comprehensive review analytics"
      ],
      "Enhanced Analytics": [
        "Service aspect keyword analysis (Food, Atmosphere, Service)",
        "Sentiment trend analysis"
      ],
      "Alert System": [
        "1-star review notifications"
      ],
      "Reporting & Insights": [
        "Monthly email performance reports",
        "Custom report generation"
      ],
      "Support": [
        "Priority customer support"
      ]
    }
  },
  {
    tier: "Enterprise",
    isEnterprise: true,
    description: "Custom pricing based on needs. Ideal for franchisors to have an overview of each location's franchisees and performance.",
    features: {
      "Everything in Pro:": [
        "✨ All features from the Pro package"
      ],
      "Advanced Integrations": [
        "POS system integration",
        "Delivery app tracker integration",
        "Reservation system integration"
      ],
      "Enterprise Features": [
        "Custom feature development",
        "API access",
        "White-label options"
      ],
      "Premium Support": [
        "Dedicated account manager",
        "Personalized onboarding sessions",
        "24/7 priority support"
      ]
    }
  }
];

// Add this email validation function near the top of the file
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export default function OnboardingFlow() {
  const [currentStep, setCurrentStep] = useState(STEPS.BUSINESS_INFO);
  const [searchQuery, setSearchQuery] = useState("");
  const [formData, setFormData] = useState({
    businessName: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    selectedPackage: "",
    locationCount: "",
    locations: [], // Will hold array of location objects
    selectedTone: "",
    permissionsConfirmed: false,
  });
  const [showConfetti, setShowConfetti] = useState(false);
  const [showGuideModal, setShowGuideModal] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES
  });
  const autocompleteRefs = useRef([]);

  // Limit the confetti animation to a few seconds
  useEffect(() => {
    let timer;
    if (showConfetti) {
      timer = setTimeout(() => {
        setShowConfetti(false);
      }, 5000); // Confetti lasts for 5 seconds
    }
    return () => clearTimeout(timer);
  }, [showConfetti]);

  // Render the content for the current step
  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.BUSINESS_INFO:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">Basic Business Information</h2>
            <div className="space-y-4">
              {[
                { label: "Business Name", key: "businessName" },
                { label: "Primary Contact Name", key: "contactName" },
                { 
                  label: "Email Address", 
                  key: "contactEmail", 
                  type: "email",
                  validate: (value) => {
                    if (value && !isValidEmail(value)) {
                      return "Please enter a valid email address";
                    }
                    return null;
                  }
                },
                { label: "Phone Number", key: "contactPhone", type: "tel" },
              ].map((field) => (
                <div key={field.key}>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {field.label} <span className="text-red-500">*</span>
                  </label>
                  <input
                    type={field.type || "text"}
                    required
                    value={formData[field.key]}
                    onChange={(e) =>
                      setFormData({ ...formData, [field.key]: e.target.value })
                    }
                    className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 ${
                      field.validate && field.validate(formData[field.key])
                        ? 'border-red-500'
                        : 'border-gray-300'
                    }`}
                  />
                  {field.validate && field.validate(formData[field.key]) && (
                    <p className="mt-1 text-sm text-red-500">
                      {field.validate(formData[field.key])}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        );

      case STEPS.PACKAGE_SELECTION:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">Select Your Package</h2>
            <p className="text-gray-600">Choose the plan that best fits your needs</p>
            
            <div className="space-y-4">
              {packages.map((pkg) => (
                <label
                  key={pkg.tier}
                  className={`
                    relative block p-6 rounded-lg border-2 cursor-pointer
                    transition-all duration-200
                    ${formData.selectedPackage === pkg.tier
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:border-blue-200'
                    }
                  `}
                >
                  <input
                    type="radio"
                    name="package"
                    value={pkg.tier}
                    checked={formData.selectedPackage === pkg.tier}
                    onChange={(e) =>
                      setFormData({ ...formData, selectedPackage: e.target.value })
                    }
                    className="sr-only"
                  />
                  
                  <div className="flex justify-between items-start">
                    <div className="flex-1">
                      <div className="flex items-center space-x-2">
                        <h3 className="text-xl font-semibold">{pkg.tier}</h3>
                        {pkg.isPopular && (
                          <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">
                            Popular
                          </span>
                        )}
                      </div>
                      <p className="text-gray-600 mt-1">{pkg.description}</p>
                    </div>
                    <div className="text-right ml-6">
                      {pkg.isEnterprise ? (
                        <>
                          <div className="text-2xl font-bold">Custom Pricing</div>
                          <div className="text-sm text-gray-600">Contact Sales</div>
                        </>
                      ) : (
                        <>
                          <div className="text-3xl font-bold">${pkg.price}</div>
                          <div className="text-sm text-gray-600">/month per location</div>
                        </>
                      )}
                    </div>
                  </div>
                  
                  <div className="mt-6">
                    <h4 className="font-medium text-gray-900 mb-4">Key Features:</h4>
                    <div className="space-y-6">
                      {Object.entries(pkg.features).map(([category, items]) => (
                        <div key={category}>
                          <h5 className="text-sm font-medium text-gray-700 mb-2">{category}</h5>
                          <ul className="space-y-2">
                            {items.map((feature) => (
                              <li key={feature} className="flex items-start">
                                <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                                <span className="text-gray-600">{feature}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </div>
        );

      case STEPS.LOCATIONS:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">Location Information</h2>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                How many locations do you have? <span className="text-red-500">*</span>
              </label>
              <select
                value={formData.locationCount}
                onChange={(e) => setFormData({ ...formData, locationCount: e.target.value })}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select number of locations</option>
                <option value="1">1</option>
                <option value="2-5">2-5</option>
                <option value="6-10">6-10</option>
                <option value="11+">11+</option>
              </select>
            </div>

            {formData.locationCount && formData.locationCount !== "11+" && (
              <div className="space-y-6">
                {Array.from({ length: getLocationCount(formData.locationCount) }).map((_, index) => (
                  <div key={index} className="p-6 border rounded-lg space-y-4 bg-gray-50">
                    <h3 className="font-medium text-lg text-gray-900">Location {index + 1}</h3>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Search Business Name or Location <span className="text-red-500">*</span>
                      </label>
                      {isLoaded ? (
                        <Autocomplete
                          onLoad={autocomplete => {
                            autocompleteRefs.current[index] = autocomplete;
                            autocomplete.setTypes(['establishment']);
                            autocomplete.setComponentRestrictions({ country: ['us', 'ca'] });
                          }}
                          onPlaceChanged={() => {
                            const autocomplete = autocompleteRefs.current[index];
                            if (!autocomplete) return;
                            
                            const place = autocomplete.getPlace();
                            if (!place || !place.geometry) {
                              console.warn('No geometry found for this place');
                              return;
                            }
                            
                            // Update both name and address in one go
                            handleLocationChange(index, "name", place.name);
                            handleLocationChange(index, "place_id", place.place_id);
                            handleLocationChange(index, "address", {
                              formatted: place.formatted_address,
                              lat: place.geometry.location.lat(),
                              lng: place.geometry.location.lng()
                            });
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter business name (e.g., Walmart, Starbucks)"
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                          />
                        </Autocomplete>
                      ) : (
                        <div className="text-gray-500">Loading Google Places...</div>
                      )}
                    </div>

                    {formData.locations[index]?.name && (
                      <div className="bg-blue-50 p-4 rounded-lg">
                        <p className="font-medium text-blue-900">{formData.locations[index].name}</p>
                        <p className="text-sm text-blue-800">{formData.locations[index].address?.formatted}</p>
                      </div>
                    )}

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Website URL (Optional)
                      </label>
                      <input
                        type="url"
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => handleLocationChange(index, "website", e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      case STEPS.TONE_SELECTION:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">
              Select Response Tone
            </h2>
            <p className="text-gray-600">
              Choose how you'd like our AI to respond to your customers
            </p>
            <div className="grid gap-4">
              {toneOptions.map((tone) => (
                <label
                  key={tone.id}
                  className={`
                      relative block p-4 rounded-lg border-2 cursor-pointer
                      transition-all duration-200
                      ${
                        formData.selectedTone === tone.id
                          ? "border-blue-500 bg-blue-50"
                          : "border-gray-200 hover:border-blue-200"
                      }
                    `}
                >
                  <input
                    type="radio"
                    name="tone"
                    value={tone.id}
                    checked={formData.selectedTone === tone.id}
                    onChange={(e) =>
                      setFormData({ ...formData, selectedTone: e.target.value })
                    }
                    className="sr-only"
                  />
                  <div className="flex items-start space-x-4">
                    <span className="text-2xl">{tone.icon}</span>
                    <div>
                      <div className="font-medium">{tone.label}</div>
                      <p className="text-sm text-gray-600 mt-1">
                        {tone.example}
                      </p>
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </div>
        );

      case STEPS.PERMISSIONS:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">Permissions</h2>
            
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 space-y-6">
              <p className="text-blue-900">
                To allow us to manage your reviews, we'll need to be added as a manager for each location.
              </p>

              <div className="space-y-4">
                <label className="flex items-start space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.permissionsConfirmed}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        permissionsConfirmed: e.target.checked,
                      })
                    }
                    className="mt-1 h-4 w-4 text-blue-600 rounded focus:ring-blue-500"
                  />
                  <span className="text-blue-900">
                    I understand and will add automations@feedbacked.ca as a manager for my Google Business Profile locations.
                  </span>
                </label>

                <div className="pt-4 space-y-3">
                  <button
                    onClick={() => setShowGuideModal(true)}
                    className="block text-blue-600 hover:text-blue-800 font-medium"
                  >
                    <span className="flex items-center space-x-2">
                      <FileText size={20} />
                      <span>Show Me How</span>
                    </span>
                  </button>

                  <a
                    href="mailto:support@feedbacked.ai"
                    className="block text-blue-600 hover:text-blue-800 font-medium"
                  >
                    <span className="flex items-center space-x-2">
                      <Mail size={20} />
                      <span>Need Assistance? Contact Us</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <GoogleBusinessGuide 
              isOpen={showGuideModal}
              onClose={() => setShowGuideModal(false)}
            />
          </div>
        );

      case STEPS.NEXT_STEPS:
        return (
          
              <div className="mt-8 p-6 bg-blue-50 rounded-lg">
                <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                  <Clock className="w-6 h-6 mr-2 text-blue-600" />
                  What Happens Next
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start space-x-3">
                    <CheckCircle className="w-5 h-5 text-blue-600 mt-1" />
                    <span>FeedBacked will accept your invitation within 24 hours</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <CheckCircle className="w-5 h-5 text-blue-600 mt-1" />
                    <span>Our system will begin monitoring your reviews</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <CheckCircle className="w-5 h-5 text-blue-600 mt-1" />
                    <span>You'll receive a confirmation email with your login information once the setup is complete</span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <CheckCircle className="w-5 h-5 text-blue-600 mt-1" />
                    <span>You'll receive a link to activate your 30-day free trial</span>
                  </li>
                </ul>
              </div>
        );
      default:
        return null;
    }
  };

  // Update the canProceed function to include email validation
  const canProceed = () => {
    switch (currentStep) {
      case STEPS.BUSINESS_INFO:
        return formData.businessName && 
               formData.contactEmail && 
               isValidEmail(formData.contactEmail);
      case STEPS.PACKAGE_SELECTION:
        return formData.selectedPackage;
      case STEPS.LOCATIONS:
        return formData.locationCount && formData.locationCount !== "11+";
      case STEPS.TONE_SELECTION:
        return formData.selectedTone;
      case STEPS.PERMISSIONS:
        return formData.permissionsConfirmed;
      case STEPS.NEXT_STEPS:
        return true;
      default:
        return false;
    }
  };

  // Get the next step in the onboarding flow
  const getNextStep = (currentStep) => {
    const steps = Object.values(STEPS);
    const currentIndex = steps.indexOf(currentStep);
    return steps[currentIndex + 1];
  };

  // Get the previous step in the onboarding flow
  const getPrevStep = (currentStep) => {
    const steps = Object.values(STEPS);
    const currentIndex = steps.indexOf(currentStep);
    return steps[currentIndex - 1];
  };

  // Handle proceeding to the next step
  const handleNext = () => {
    if (currentStep === STEPS.NEXT_STEPS) {
      try {
        const onboardingData = formatOnboardingData(formData);
        
        // Create a clean JSON object
        const cleanData = JSON.parse(JSON.stringify(onboardingData));
        

        // Example of how you would send this to your API
        // await fetch('/api/onboarding', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(cleanData)
        // });
        
        // Show success animation and redirect
        setShowConfetti(true);
        setTimeout(() => {
          window.location.href = '/';
        }, 3500);
      } catch (error) {
        console.error('Error during onboarding submission:', error);
      }
      return;
    }
    
    const nextStep = getNextStep(currentStep);
    if (nextStep) {
      setCurrentStep(nextStep);
    }
  };

  // Handle going back to the previous step
  const handleBack = () => {
    const prevStep = getPrevStep(currentStep);
    if (prevStep) {
      setCurrentStep(prevStep);
    }
  };

  const handleLocationChange = (locationIndex, field, value) => {
    setFormData(prevData => {
      const updatedLocations = [...(prevData.locations || [])];
      
      // Initialize location object if it doesn't exist
      if (!updatedLocations[locationIndex]) {
        updatedLocations[locationIndex] = {};
      }
      
      // Update the specific field for this location
      updatedLocations[locationIndex] = {
        ...updatedLocations[locationIndex],
        [field]: value
      };
      
      return {
        ...prevData,
        locations: updatedLocations
      };
    });
  };

  // Update the formatOnboardingData function
  const formatOnboardingData = (formData) => {
    const onboardingData = {
      business: {
        name: formData.businessName,
        contact: {
          name: formData.contactName,
          email: formData.contactEmail,
          phone: formData.contactPhone
        }
      },
      locations: formData.locations.map(location => ({
        name: location.name,
        address: location.address?.formatted,
        place_id: location.place_id, 
        coordinates: {
          lat: location.address?.lat,
          lng: location.address?.lng
        },
        website: location.website || null
      })),
      preferences: {
        responseStyle: formData.selectedTone,
        permissionsGranted: formData.permissionsConfirmed,
        selectedPackage: formData.selectedPackage
      },
      metadata: {
        completedAt: new Date().toISOString(),
        steps: Object.values(STEPS)
      }
    };

    return onboardingData;
  };

  const findAddressComponent = (components, type) => {
    return components?.find(
      component => component.types[0] === type
    );
  };

  useEffect(() => {
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      {showConfetti && (
        <Confetti
          numberOfPieces={isMobile ? 100 : 200}
          recycle={false}
          colors={["#2563EB", "#3B82F6", "#60A5FA", "#93C5FD"]}
        />
      )}
      <div className="w-full max-w-3xl bg-white rounded-xl shadow-lg p-4 md:p-8">
        {/* Progress Bar */}
        <div className="mb-6 md:mb-8">
          <div className="flex justify-between mb-4 overflow-x-auto pb-2 md:pb-0">
            {Object.values(STEPS).map((step, index) => (
              <div key={step} className="text-center flex-1 min-w-[80px] px-1">
                <div
                  className={`
                    w-8 h-8 md:w-10 md:h-10 mx-auto rounded-full flex items-center justify-center text-base md:text-lg font-bold
                    ${
                      Object.values(STEPS).indexOf(currentStep) >= index
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-600"
                    }
                  `}
                >
                  {index + 1}
                </div>
                <div
                  className={`text-xs md:text-sm mt-2 ${
                    Object.values(STEPS).indexOf(currentStep) >= index
                      ? "text-blue-600 font-semibold"
                      : "text-gray-600"
                  }`}
                >
                  {step.split('_').join(' ')}
                </div>
              </div>
            ))}
          </div>
          <div className="relative h-2 bg-gray-200 rounded-full">
            <div
              className="absolute h-full bg-blue-600 rounded-full transition-all duration-300"
              style={{
                width: `${
                  ((Object.values(STEPS).indexOf(currentStep) + 1) /
                    Object.values(STEPS).length) *
                  100
                }%`,
              }}
            />
          </div>
        </div>

        {/* Step Content */}
        <div className="mb-6 md:mb-8">{renderStepContent()}</div>

        {/* Navigation */}
        <div className="flex justify-between mt-6">
          <button
            onClick={handleBack}
            className={`
              bg-white text-gray-600 px-4 md:px-6 py-2 md:py-3 rounded-lg border-2 border-gray-200 hover:border-blue-600 transition-all flex items-center
              ${
                currentStep === STEPS.BUSINESS_INFO
                  ? "invisible"
                  : "hover:shadow-md"
              }
            `}
          >
            <ChevronLeft size={isMobile ? 16 : 20} />
            <span className="ml-1">Back</span>
          </button>

          <button
            onClick={handleNext}
            disabled={!canProceed()}
            className={`
              bg-blue-600 text-white px-6 md:px-8 py-2 md:py-3 rounded-lg hover:bg-blue-700 transition-all transform hover:scale-105 hover:shadow-lg flex items-center
              ${
                !canProceed() &&
                "bg-gray-200 text-gray-400 cursor-not-allowed hover:scale-100 hover:shadow-none"
              }
            `}
          >
            <span>{currentStep === STEPS.NEXT_STEPS ? "Complete" : "Next"}</span>
            <ChevronRight size={isMobile ? 16 : 20} className="ml-1" />
          </button>
        </div>
      </div>
    </div>
  );
}
