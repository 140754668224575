import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../../../components/shadcn/dialog/Dialog';
import { addLocation } from '../../../../store/shared/location/locationThunks';

const LocationCreationModal = ({ open, onClose, restaurants }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
    restaurant_id: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.name || !formData.restaurant_id) {
      toast.error('Please fill in all required fields');
      return;
    }

    try {
      // Only send required fields in the request
      const locationData = {
        name: formData.name,
        restaurant_id: formData.restaurant_id
      };

      await dispatch(addLocation(locationData)).unwrap();
      onClose();
      setFormData({
        name: '',
        restaurant_id: '',
      });
      
      toast.success('Location created successfully!', {
        duration: 3000,
        className: 'bg-green-500',
      });
    } catch (error) {
      toast.error(error.message || 'Failed to create location', {
        duration: 3000,
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>Add New Location</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name *
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              placeholder="Enter location name"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Restaurant *
            </label>
            <select
              value={formData.restaurant_id}
              onChange={(e) => setFormData(prev => ({ ...prev, restaurant_id: e.target.value }))}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 bg-white"
              required
            >
              <option value="">Select Restaurant</option>
              {restaurants.map((restaurant) => (
                <option key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </option>
              ))}
            </select>
          </div>

          <DialogFooter>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Create Location
            </button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default LocationCreationModal; 