export const DOMAIN_BASE_URL = "https://restos.yarri.dev";
export const API_VERSION = "/api/v1";

export const LIBRO_BASE_URL = "https://api.libroreserve.com/restricted";

export const API_ENDPOINTS = {
  // Authentication
  LOGIN_PAGE: "/auth/login",
  LOGIN: "/auth/login",
  REFRESH: "/auth/refresh",
  LOGOUT: "/auth/logout",
  GET_ROLES: "/auth/roles",
  USERS: "/auth/users",
  USER: {
    GET: (id) => `/auth/user/${id}`,
    UPDATE: (id) => `/auth/user/${id}`,
    CREATE: "/auth/user",
    DELETE: (id) => `/auth/user/${id}`,
  },
  // Locations
  LOCATION: {
    ALL: "/location",
    REVIEWS: {
      GET: (locationId) => `/location/${locationId}/review`,
      GET_BY_ID: (locationId, reviewId) => `/location/${locationId}/review/${reviewId}`,
      PAGINATED: (locationId, offset, limit) =>
        `/location/${locationId}/review/offset/${offset}/limit/${limit}`,
    },
    GOOGLE_REVIEWS: {
      GET: (locationId) => locationId === 'all' ? 
        '/location/all/google-review' : 
        `/location/${locationId}/google-review`,
      PAGINATED: (locationId, offset, limit) => {
        const basePath = locationId === 'all' ? 
          '/location/all/google-review' : 
          `/location/${locationId}/google-review`;
        
        if (typeof offset === "number" && typeof limit === "number") {
          return `${basePath}/offset/${offset}/limit/${limit}`;
        }
        return basePath;
      },
      BY_ID: (locationId, reviewId) =>
        `/location/${locationId}/google-review/${reviewId}`,
      RESPOND: (locationId, reviewId) =>
        `/location/${locationId}/google-review/${reviewId}/response`,
      POST: (locationId) => `/location/${locationId}/google-review`,
      UPDATE_RESPONSE: (locationId, reviewId) =>
        `/location/${locationId}/google-review/${reviewId}/response`,
      REGENERATE_RESPONSE: (locationId, reviewId) =>
        `/location/${locationId}/google-review/${reviewId}/response/regenerate`,
      APPROVE_RESPONSE: (locationId, reviewId) =>
        `/location/${locationId}/google-review/${reviewId}/response/approve`,
      POST_RESPONSE: (locationId, reviewId) =>
        `/location/${locationId}/google-review/${reviewId}/response/approve`,
    },
    UPDATE: (locationId) => `/location/${locationId}`,
    CREATE: "/location",
    DELETE: (locationId) => `/location/${locationId}`,
    MENU_INSIGHTS: {
      GET: (locationId) => locationId === 'all' ? 
        '/location/all/menu-insight' : 
        `/location/${locationId}/menu-insight`,
      PAGINATED: (locationId, offset, limit) => {
        const basePath = locationId === 'all' ? 
          '/location/all/menu-insight' : 
          `/location/${locationId}/menu-insight`;
        return `${basePath}/offset/${offset}/limit/${limit}`;
      },
    },
    STAFF_MENTIONS: {
      GET: (locationId) => locationId === 'all' ? 
        '/location/all/staff-mention' : 
        `/location/${locationId}/staff-mention`,
      PAGINATED: (locationId, offset, limit) => {
        const basePath = locationId === 'all' ? 
          '/location/all/staff-mention' : 
          `/location/${locationId}/staff-mention`;
        return `${basePath}/offset/${offset}/limit/${limit}`;
      },
      PROFILES: {
        GET: (locationId) => {
          return locationId === 'all' ? 
            '/location/all/staff-mention/profile' : 
            `/location/${locationId}/staff-mention/profile`;
        }
      },
      PERFORMANCE: {
        BY_LOCATION: (locationId, startDate, endDate) => 
          locationId === 'all' 
            ? `/location/all/staff-mention/performance/from/${startDate}/to/${endDate}`
            : `/location/${locationId}/staff-mention/performance/from/${startDate}/to/${endDate}`,
        BY_RESTAURANT: (restaurantId, startDate, endDate) => 
          `/restaurant/${restaurantId}/staff-mention/performance/from/${startDate}/to/${endDate}`
      }
    },
    KEYWORDS: {
      LOCATION: {
        ALL_OCCURRENCES: (locationId, offset, limit) => 
          limit ? 
          `/location/${locationId}/keywords/all/occurrences/offset/${offset}/limit/${limit}` :
          `/location/${locationId}/keywords/all/occurrences`,
        CATEGORY_OCCURRENCES: (locationId, category, offset, limit) =>
          limit ?
          `/location/${locationId}/keywords/category/${category}/occurrences/offset/${offset}/limit/${limit}` :
          `/location/${locationId}/keywords/category/${category}/occurrences`,
        KEYWORD_DETAILS: (locationId, keyword) =>
          `/location/${locationId}/keywords/${keyword}/details`,
      },
      RESTAURANT: {
        ALL_OCCURRENCES: (restaurantId, offset, limit) =>
          limit ?
          `/restaurant/${restaurantId}/keywords/all/occurrences/offset/${offset}/limit/${limit}` :
          `/restaurant/${restaurantId}/keywords/all/occurrences`,
        CATEGORY_OCCURRENCES: (restaurantId, category, offset, limit) =>
          limit ?
          `/restaurant/${restaurantId}/keywords/category/${category}/occurrences/offset/${offset}/limit/${limit}` :
          `/restaurant/${restaurantId}/keywords/category/${category}/occurrences`,
        KEYWORD_DETAILS: (restaurantId, keyword) =>
          `/restaurant/${restaurantId}/keywords/${keyword}/details`,
      },
    },
    STAFF_ROLES: {
      GET: (locationId) => locationId === 'all' ? 
        '/location/all/staff-role' : 
        `/location/${locationId}/staff-role`,
      PAGINATED: (locationId, offset, limit) => {
        const basePath = locationId === 'all' ? 
          '/location/all/staff-role' : 
          `/location/${locationId}/staff-role`;
        return `${basePath}/offset/${offset}/limit/${limit}`;
      },
      DELETE: (locationId, roleId) => 
        `/location/${locationId}/staff-role/${roleId}`,
    },
  },
  // Restaurants
  RESTAURANT: {
    ALL: "/restaurant",
    CREATE: "/restaurant",
    UPDATE: (restaurantId) => `/restaurant/${restaurantId}`,
    DELETE: (restaurantId) => `/restaurant/${restaurantId}`,
    INTEGRATIONS: (restaurantId) => `/restaurant/${restaurantId}/integrations`,
    GOOGLE_REVIEWS: {
      GET: (restaurantId) => `/restaurant/${restaurantId}/google-review`,
      PAGINATED: (restaurantId, offset, limit) => 
        `/restaurant/${restaurantId}/google-review/offset/${offset}/limit/${limit}`,
    },
    STAFF_MENTIONS: {
      GET: (restaurantId) => `/restaurant/${restaurantId}/staff-mention`,
      PAGINATED: (restaurantId, offset, limit) => 
        `/restaurant/${restaurantId}/staff-mention/offset/${offset}/limit/${limit}`,
      PROFILES: {
        GET: (restaurantId, offset, limit) => 
          `/restaurant/${restaurantId}/staff-mention/profile/offset/${offset}/limit/${limit}`,
      }
    },
    MENU_INSIGHTS: {
      GET: (restaurantId) => `/restaurant/${restaurantId}/menu-insight`,
      PAGINATED: (restaurantId, offset, limit) => 
        `/restaurant/${restaurantId}/menu-insight/offset/${offset}/limit/${limit}`,
    },
    REPORTS: {
      GET: (restaurantId) => `/restaurant/${restaurantId}/report`,
      PAGINATED: (restaurantId, offset, limit) => 
        `/restaurant/${restaurantId}/report/offset/${offset}/limit/${limit}`,
      DELETE: (restaurantId, reportId) => 
        `/restaurant/${restaurantId}/report/${reportId}`,
      CREATE_STAFF: (restaurantId) => `/restaurant/${restaurantId}/report/staff`,
      CREATE_MENU: (restaurantId) => `/restaurant/${restaurantId}/report/menu`,
    },
    SELF: "/restaurant/self",
    ADDITIONAL_INSTRUCTIONS: (restaurantId) => 
      `/restaurant/${restaurantId}/additional-instructions`,
    AUTO_APPROVAL: (restaurantId) => 
      `/restaurant/${restaurantId}/auto-approval`,
    STAFF_ROLES: {
      GET: (restaurantId) => `/restaurant/${restaurantId}/staff-role`,
      PAGINATED: (restaurantId, offset, limit) => 
        `/restaurant/${restaurantId}/staff-role/offset/${offset}/limit/${limit}`,
    },
  },
  SUBSCRIPTION_TIER: {
    ALL: '/subscription-tier',
    CREATE: '/subscription-tier',
    UPDATE: (tierId) => `/subscription-tier/${tierId}`,
    DELETE: (tierId) => `/subscription-tier/${tierId}`,
  },
  // Add this new section for alerts
  ALERTS: {
    GET: (restaurantId) => `/restaurant/${restaurantId}/user-alert`,
    CONFIG: {
      ALL: (restaurantId) => `/restaurant/${restaurantId}/user-alert/config`,
      SELF: (restaurantId) => `/restaurant/${restaurantId}/user-alert/config/self`,
      UPDATE: (restaurantId) => `/restaurant/${restaurantId}/user-alert/config`,
    },
    BAD_REVIEW: {
      MARK_SEEN: (restaurantId, reviewId) => 
        `/restaurant/${restaurantId}/user-alert/bad-review/${reviewId}/seen`,
      MARK_MANY_SEEN: (restaurantId) => 
        `/restaurant/${restaurantId}/user-alert/bad-review/many/seen`,
    }
  },
};

export const LIBRO_ENDPOINTS = {
  AUTHENTICATION: "/authentication/sessions",
  BOOKINGS: "/restaurant/bookings",
  BOOKING: (id) => `/restaurant/bookings/${id}`,
};

export const getApiUrl = (endpoint) =>
  `${DOMAIN_BASE_URL}${API_VERSION}${endpoint}`;
export const getLibroApiUrl = (endpoint) => `${LIBRO_BASE_URL}${endpoint}`;

export const LIBRO_API_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/vnd.libro-restricted-v2+json",
};
