import { useState } from 'react';
import { MapPin, Trash2, Edit, Building2 } from 'lucide-react';
import LocationEditModal from './LocationEditModal';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../../../components/shadcn/dialog/Dialog';

const LocationCard = ({ location, restaurant, onDelete, onEdit }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmationName, setConfirmationName] = useState('');

  const handleConfirmDelete = () => {
    if (confirmationName.trim() === location.name.trim()) {
      onDelete(location.id);
      setShowDeleteConfirm(false);
      setConfirmationName('');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all">
      <div className="flex items-center justify-between mb-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            <MapPin className="w-6 h-6 text-gray-500 mr-3" />
            <h3 className="text-lg font-semibold text-gray-800">{location.name}</h3>
          </div>
          <span className="text-xs text-gray-400 ml-9">ID: {location.id}</span>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setShowEditModal(true)}
            className="text-blue-500 hover:text-blue-700 transition-colors"
          >
            <Edit className="w-5 h-5" />
          </button>
          <button
            onClick={() => setShowDeleteConfirm(true)}
            className="text-red-500 hover:text-red-700 transition-colors"
          >
            <Trash2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="flex items-center text-gray-600 mb-4">
        <Building2 className="w-4 h-4 mr-2" />
        <span className="text-sm">
          Restaurant: {restaurant?.name || 'Unknown'}
        </span>
      </div>

      {/* Edit Modal */}
      <LocationEditModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        location={location}
        onEdit={onEdit}
      />

      {/* Delete Confirmation Modal */}
      <Dialog open={showDeleteConfirm} onOpenChange={setShowDeleteConfirm}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
          </DialogHeader>
          
          <div className="py-4">
            <p className="mb-4">
              This action cannot be undone. Please type <strong>{location.name}</strong> to confirm deletion.
            </p>
            <input
              type="text"
              value={confirmationName}
              onChange={(e) => setConfirmationName(e.target.value)}
              placeholder="Type location name"
              className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <DialogFooter>
            <button
              onClick={() => {
                setShowDeleteConfirm(false);
                setConfirmationName('');
              }}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmDelete}
              disabled={confirmationName.trim() !== location.name.trim()}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Delete Location
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LocationCard; 