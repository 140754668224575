// src/store/slices/locationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchLocations, addLocation, updateLocation, deleteLocation } from "./locationThunks";
import * as locationSelectors from "./locationSelectors";

const initialState = {
  locations: [],
  selectedLocation: null,
  isLoading: true,
  error: null,
  offsets: {
    offset: 0,
    staffOffset: 0,
    menuOffset: 0,
  },
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setSelectedLocation: (state, action) => {
      if (!action.payload) {
        state.selectedLocation = null;
        return;
      }
      if (action.payload.name === "All") {
        state.selectedLocation = { 
          ...action.payload, 
          id: "all"
        };
      } else {
        state.selectedLocation = action.payload;
      }
    },
    setOffsets: (state, action) => {
      state.offsets = action.payload;
    },
    resetOffsets: (state) => {
      state.offsets = initialState.offsets;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locations = action.payload;
        state.initialized = true;  
        
        // Set default location if none selected
        if (!state.selectedLocation && action.payload.length > 0) {
          // Find the "All" location if it exists
          const allLocation = action.payload.find(loc => loc.name === "All");
          
          // Set to "All" if it exists, otherwise first location
          state.selectedLocation = allLocation || action.payload[0];
          
          // If it's the "All" location, ensure it has the correct id
          if (state.selectedLocation.name === "All") {
            state.selectedLocation = {
              ...state.selectedLocation,
              id: "all"
            };
          }
        }
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addLocation.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export {
  locationSelectors,
  fetchLocations,
  addLocation,
  updateLocation,
  deleteLocation
};

export const { setSelectedLocation, setOffsets, resetOffsets } = locationSlice.actions;
export default locationSlice.reducer;
