import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../../../components/common/Loading';

// Lazy load route components
const Home = React.lazy(() => import('../../home/components/Home'));
const Alerts = React.lazy(() => import('../../alerts/components/Alerts'));
const Agent = React.lazy(() => import('../../agent/components/Agent'));
const Trends = React.lazy(() => import('../../trend/components/Trends'));
const Reviews = React.lazy(() => import('../../reviews/components/Reviews'));
const Staff = React.lazy(() => import('../../staff/components/Staff'));
const Menu = React.lazy(() => import('../../menu/components/Menu'));
const Keywords = React.lazy(() => import('../../keywords/components/Keywords'));
const Administration = React.lazy(() => import('../../administration/component/Administration'));
const Analytics = React.lazy(() => import('../../graphs/components/DashboardCharts.js'));
const Reports = React.lazy(() => import('../../reports/components/Reports'));
const Profile = React.lazy(() => import('../../profile/components/Profile'));

const DashboardRoutes = ({
  userRole,
  selectedLocation,
}) => {
  return (
    <Suspense fallback={<Loading.ContentLoader />}>
      <Routes>
        {/* Routes available to all roles */}
        <Route 
          path="home" 
          element={
            <Home 
              selectedLocation={selectedLocation}
            />
          } 
        />
        <Route 
          path="alerts" 
          element={
            <Alerts 
              selectedLocation
            />
          } 
        />

        {/* Admin and SuperAdmin only routes */}
        {(userRole === "SuperAdmin" || userRole === "Admin") && (
          <Route 
            path="agent" 
            element={
              <Agent 
                selectedLocation={selectedLocation}
              />
            } 
          />
        )}

        {(userRole === "SuperAdmin" || userRole === "Admin" || userRole === "Manager") && (
          <>
            <Route 
              path="trends" 
              element={
                <Trends 
                  selectedLocation={selectedLocation}
                />
              } 
            />
            <Route 
              path="reviews" 
              element={
                <Reviews 
                  selectedLocation={selectedLocation}
                />
              } 
            />
            <Route 
              path="staff" 
              element={
                <Staff 
                  selectedLocation={selectedLocation}
                />
              } 
            />
            <Route 
              path="staff/profiles" 
              element={
                <Profile 
                  selectedLocation={selectedLocation}
                />
              } 
            />
            <Route
               path="graphs"  // Changed from "analytics" to "graphs"
               element={
                <Analytics 
                  selectedLocation={selectedLocation}
                />
               }
            />
            <Route 
              path="reports" 
              element={
                <Reports 
                  selectedLocation={selectedLocation}
                />
              } 
            />
          </>
        )}
        
        {(userRole === "SuperAdmin" || userRole === "Admin") && (
          <>
            <Route 
              path="keywords" 
              element={
                <Keywords 
                  selectedLocation={selectedLocation}
                />
              } 
            />
            <Route 
              path="administration" 
              element={
                <Administration 
                  selectedLocation={selectedLocation}
                />
              } 
            />
          </>
        )}
        
        {(userRole === "SuperAdmin" || userRole === "Admin" || userRole === "BOH") && (
          <Route 
            path="menu" 
            element={
              <Menu 
                selectedLocation={selectedLocation}
              />
            } 
          />
        )}
      </Routes>
    </Suspense>
  );
};

DashboardRoutes.propTypes = {
  userRole: PropTypes.string.isRequired,
  selectedLocation: PropTypes.object,
  locations: PropTypes.array,
  selectedRestaurant: PropTypes.object
};

export default DashboardRoutes;