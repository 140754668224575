import React from 'react';
import MainPageNavbar from './MainPageNavbar';

const navLinks = [
  { to: '/', label: 'Home' },
  { to: '/enterprise', label: 'Enterprise' },
  { to: '/solutions', label: 'Solutions' },
//   { to: '/partners', label: 'Partners' },
//   { to: '/resources', label: 'Resources' },
  { to: '/blog', label: 'Blog' },
  { to: '/pricing', label: 'Pricing' }
];

const PublicLayout = ({ children }) => {
  return (
    <div className="min-h-screen overflow-x-hidden">
      <MainPageNavbar brand="FeedBacked" links={navLinks} />
      {children}
    </div>
  );
};

export default PublicLayout;
