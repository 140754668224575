import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, fetchRoles, selectUsers } from '../../../../store/shared/user/userSlice';
import { fetchLocations } from '../../../../store/shared/location/locationThunks';
import { fetchRestaurants } from '../../../../store/slices/restaurantSlice';
import { selectAccessToken } from '../../../../store/shared/auth/authSelectors';
import UserList from './UserList';
import UserFormModal from './UserFormModal';
import { Button } from '../../../../components/shadcn/button/Button';
import { Plus } from 'lucide-react';
import SuperAdminSidebar from '../dashboard/SuperAdminSidebar';

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const token = useSelector(selectAccessToken);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(fetchUsers(token));
      dispatch(fetchRoles(token));
      dispatch(fetchLocations());
      dispatch(fetchRestaurants());
    }
  }, [dispatch, token]);

  const handleCreateUser = () => {
    setSelectedUser(null);
    setIsModalOpen(true);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      <SuperAdminSidebar />
      <div className="flex-1 ml-64 p-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">User Management</h1>
          <Button onClick={handleCreateUser}>
            <Plus className="mr-2 h-4 w-4" />
            Add User
          </Button>
        </div>
        
        <UserList users={users} onEditUser={handleEditUser} />
        
        <UserFormModal 
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          user={selectedUser}
        />
      </div>
    </div>
  );
};

export default Users;
