// src/features/superadmin/components/MetricCard.js
import React from 'react';

export const MetricCard = ({ title, value, change, Icon }) => {
  const isPositive = change?.startsWith('+');
  
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-4">
        <Icon className="w-6 h-6 text-blue-600" />
        {change && (
          <span className={`text-sm font-semibold ${
            isPositive ? 'text-green-500' : 'text-red-500'
          }`}>
            {change}
          </span>
        )}
      </div>
      <h3 className="text-gray-600 text-sm">{title}</h3>
      <p className="text-2xl font-bold mt-2">{value}</p>
    </div>
  );
};

MetricCard.defaultProps = {
  change: '',
};