export const selectLocationsInitialized = (state) => state.location.initialized;
export const selectLocations = (state) => state.location.locations;
export const selectSelectedLocation = (state) => state.location.selectedLocation;
export const selectLocationLoading = (state) => state.location.isLoading;
export const selectLocationError = (state) => state.location.error;
export const selectOffsets = (state) => state.location.offsets;
export const selectLocationById = (state, id) =>
  state.location.locations.find((loc) => loc.id === id);
export const selectLocationName = (state, id) =>
  state.location.locations.find((loc) => loc.id === id)?.name || "Unknown";
export const selectEffectiveLocationId = (state) => state.location.effectiveLocationId;
