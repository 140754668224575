import React from 'react';
import { Shield, Users, BarChart, Bot, Check, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const EnterprisePage = () => {
  const supportEmail = 'support@feedbacked.ai';

  const features = [
    {
      icon: Shield,
      title: "Enterprise-Grade Security",
      description: "SOC 2 Type II compliance, end-to-end encryption, and advanced access controls"
    },
    {
      icon: Users,
      title: "Dedicated Support",
      description: "24/7 priority support and dedicated customer success manager"
    },
    {
      icon: BarChart,
      title: "Advanced Analytics",
      description: "Custom reporting, AI-powered insights, and competitive benchmarking"
    },
    {
      icon: Bot,
      title: "Custom AI Training",
      description: "Tailored AI models trained on your brand voice and preferences"
    }
  ];

  const benefits = [
    "Custom SLAs and uptime guarantees",
    "Unlimited user seats and locations",
    "Priority feature development",
    "Custom integrations",
    "Data retention policies",
    "Bulk data import/export",
    "White-label options",
    "API access"
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative bg-white overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-indigo-50 opacity-50" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Enterprise Solutions for
              <br />
              <span className="text-blue-600">Restaurant Chains</span>
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
              Scalable review management solutions for multi-location restaurants
              and enterprise organizations
            </p>
            <div className="flex justify-center space-x-4">
              <Link 
                to="/enterprise-quote"
                className="px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all flex items-center"
              >
                Contact Sales
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <button className="px-8 py-4 border-2 border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 transition-all">
                Schedule Demo
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="grid md:grid-cols-2 gap-8">
          {features.map((feature) => (
            <div 
              key={feature.title} 
              className="bg-white p-8 rounded-xl shadow-sm hover:shadow-xl transition-all duration-300"
            >
              <div className="h-12 w-12 bg-blue-100 rounded-lg flex items-center justify-center mb-6">
                <feature.icon className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Benefits Section */}
      <div className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Enterprise Benefits</h2>
            <p className="text-xl text-gray-600">
              Everything you need to manage reviews at scale
            </p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-y-6 max-w-4xl mx-auto">
            {benefits.map((benefit) => (
              <div 
                key={benefit} 
                className="flex items-start p-4 hover:bg-gray-50 rounded-lg transition-colors duration-200"
              >
                <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                <span className="text-gray-700">{benefit}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-8">Ready to Scale Your Review Management?</h2>
          <p className="text-xl mb-8 text-blue-100">
            Let's discuss how we can help your enterprise organization succeed
          </p>
          <Link 
            to="/enterprise-quote"
            className="bg-white text-blue-600 px-8 py-4 rounded-lg hover:bg-blue-50 transition-all transform hover:scale-105 hover:shadow-lg inline-block"
          >
            Get Enterprise Quote
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EnterprisePage;