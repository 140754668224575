import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { FaSort, FaSortUp, FaSortDown, FaSearch } from "react-icons/fa";
import { Loader } from "lucide-react";
import useMediaQuery from "../../hooks/useMediaQuery";

const DataTable = ({
  columns,
  data,
  defaultSortKey,
  defaultSortDirection = "asc",
  filters = [],
  searchPlaceholder = "",
  onRowClick,
  customCellRenderers = {},
  theme = "light",
  noDataMessage = "No data found.",
  // New props for infinite scrolling
  isLoading = false,
  hasMore = false,
  onLoadMore = () => {},
  loadingMore = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: defaultSortKey,
    direction: defaultSortDirection,
  });
  const [filterValues, setFilterValues] = useState({});
  const observerRef = useRef();
  const loadMoreRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 640px)");

  // Intersection Observer setup for infinite scrolling
  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasMore && !loadingMore && !isLoading) {
        onLoadMore();
      }
    },
    [hasMore, loadingMore, isLoading, onLoadMore]
  );

  useEffect(() => {
    const element = loadMoreRef.current;

    if (!element) return;

    observerRef.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "100px",
      threshold: 0.1,
    });

    observerRef.current.observe(element);

    return () => {
      if (observerRef.current && element) {
        observerRef.current.unobserve(element);
      }
    };
  }, [handleObserver]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key !== key)
      return <FaSort className="ml-1 text-gray-400" />;
    return sortConfig.direction === "asc" ? (
      <FaSortUp className="ml-1 text-blue-500" />
    ) : (
      <FaSortDown className="ml-1 text-blue-500" />
    );
  };

  const filteredAndSortedData = useMemo(() => {
    // Create a copy of the data first
    let filteredData = [...data];

    // Apply search filter
    if (searchTerm) {
      filteredData = filteredData.filter((item) =>
        columns.some((column) => {
          const value = item[column.key];
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );
        })
      );
    }

    // Apply custom filters
    filters.forEach((filter) => {
      const filterValue = filterValues[filter.key];
      if (filterValue) {
        filteredData = filteredData.filter((item) =>
          filter.filterFunction(item, filterValue)
        );
      }
    });

    // Sort data
    if (sortConfig.key) {
      const { key, direction } = sortConfig;
      return filteredData.sort((a, b) => {
        const aValue = a[key];
        const bValue = b[key];

        // Handle date comparison for both review_date and period
        if (key === 'review_date' || key === 'period') {
          const dateA = new Date(key === 'period' ? a.date : aValue);
          const dateB = new Date(key === 'period' ? b.date : bValue);
          return direction === 'asc' ? dateA - dateB : dateB - dateA;
        }

        // Regular value comparison
        if (aValue === bValue) return 0;
        if (direction === 'asc') {
          return aValue > bValue ? 1 : -1;
        }
        return aValue < bValue ? 1 : -1;
      });
    }

    return filteredData;
  }, [data, searchTerm, filterValues, sortConfig, columns, filters]);

  // Loading states
  const renderLoadingState = () => (
    <div className="flex items-center justify-center p-4">
      <Loader className="w-6 h-6 animate-spin text-blue-500" />
    </div>
  );

  // Render mobile view
  if (isMobile) {
    return (
      <div
        className={`${
          theme === "dark" ? "bg-gray-800" : "bg-white"
        } rounded-lg shadow-md transition-colors duration-200`}
      >
        {/* Search and Filters Section */}
        <div
          className={`p-4 ${
            theme === "dark" ? "bg-gray-700" : "bg-gray-50"
          } border-b ${
            theme === "dark" ? "border-gray-600" : "border-gray-200"
          } sticky top-0 z-30`} // Updated z-index
        >
          <div className="flex flex-col gap-4">
            {/* Search */}
            {searchPlaceholder && (
              <div className="relative">
                <input
                  type="text"
                  placeholder={searchPlaceholder}
                  className={`w-full pl-10 pr-4 py-2 border rounded-lg 
            focus:outline-none focus:ring-2 focus:ring-blue-500 
            ${
              theme === "dark"
                ? "bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400"
                : "bg-white text-gray-900 border-gray-300 placeholder-gray-500"
            }`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            )}
            {/* Filters */}
            {filters.length > 0 && (
              <div className="flex flex-col gap-2">
                {filters.map((filter) => (
                  <select
                    key={filter.key}
                    value={filterValues[filter.key] || ""}
                    onChange={(e) =>
                      setFilterValues((prev) => ({
                        ...prev,
                        [filter.key]: e.target.value,
                      }))
                    }
                    className={`border rounded-lg px-4 py-2 
                      focus:outline-none focus:ring-2 focus:ring-blue-500 
                      ${
                        theme === "dark"
                          ? "bg-gray-700 text-gray-200 border-gray-600"
                          : "bg-white text-gray-900 border-gray-300"
                      }`}
                  >
                    <option value="">{filter.placeholder}</option>
                    {filter.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Cards List */}
        <div className="divide-y">
          {filteredAndSortedData.length > 0
            ? filteredAndSortedData.map((item, index) => (
                <div
                  key={index}
                  className={`p-4 ${
                    onRowClick ? "cursor-pointer" : ""
                  } hover:bg-gray-50 dark:hover:bg-gray-700`}
                  onClick={() => onRowClick && onRowClick(item)}
                >
                  {columns.map((column) => (
                    <div key={column.key} className="mb-2">
                      <span className="font-semibold">{column.label}: </span>
                      {customCellRenderers[column.key]
                        ? customCellRenderers[column.key](
                            item[column.key],
                            item
                          )
                        : item[column.key]}
                    </div>
                  ))}
                </div>
              ))
            : !isLoading && (
                <div className="p-8 text-center">
                  <p
                    className={`${
                      theme === "dark" ? "text-gray-300" : "text-gray-500"
                    }`}
                  >
                    {noDataMessage}
                  </p>
                </div>
              )}
        </div>

        {/* Loading States */}
        {isLoading && renderLoadingState()}

        {/* Infinite Scroll Trigger */}
        {hasMore && !loadingMore && (
          <div ref={loadMoreRef} className="h-4" aria-hidden="true" />
        )}
      </div>
    );
  }

  // Desktop view
  return (
    <div
      className={`${
        theme === "dark" ? "bg-gray-800" : "bg-white"
      } rounded-lg shadow-md transition-colors duration-200`}
    >
      {/* Search and Filters */}
      <div
        className={`p-4 ${
          theme === "dark" ? "bg-gray-700" : "bg-gray-50"
        } border-b ${
          theme === "dark" ? "border-gray-600" : "border-gray-200"
        } sticky top-0 z-${zIndexConfig.tableHeader}`}
      >
        {/* Remove the overflow-x-auto div wrapper and fix the flex layout */}
        <div className="flex items-center justify-between gap-4">
          {/* Search */}
          <div className="flex-shrink-0 w-64">
            {" "}
            {/* Fixed width for search */}
            {searchPlaceholder && (
              <div className="relative">
                <input
                  type="text"
                  placeholder={searchPlaceholder}
                  className={`w-full pl-10 pr-4 py-2 border rounded-lg 
              focus:outline-none focus:ring-2 focus:ring-blue-500 
              ${
                theme === "dark"
                  ? "bg-gray-700 text-gray-200 border-gray-600"
                  : "bg-white text-gray-900 border-gray-300"
              }`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            )}
          </div>
          {/* Filters */}
          {filters.length > 0 && (
            <div className="flex items-center gap-2 flex-shrink-0">
              {filters.map((filter) => (
                <select
                  key={filter.key}
                  value={filterValues[filter.key] || ""}
                  onChange={(e) =>
                    setFilterValues((prev) => ({
                      ...prev,
                      [filter.key]: e.target.value,
                    }))
                  }
                  className={`border rounded-lg px-4 py-2 
              focus:outline-none focus:ring-2 focus:ring-blue-500 
              ${
                theme === "dark"
                  ? "bg-gray-700 text-gray-200 border-gray-600"
                  : "bg-white text-gray-900 border-gray-300"
              }`}
                >
                  <option value="">{filter.placeholder}</option>
                  {filter.options.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className={
                        theme === "dark" ? "text-gray-200" : "text-gray-900"
                      }
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Table */}
      <div className="relative overflow-x-auto">
      <div className="overflow-x-auto">
        <table className="min-w-full table-fixed divide-y">
          {/* Table Header */}
          <thead
            className={`${
              theme === "dark"
                ? "bg-gray-700 text-gray-200"
                : "bg-gray-50 text-gray-700"
            }`}
          >
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  onClick={() => column.sortable && handleSort(column.key)}
                  className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider 
                    ${
                      theme === "dark"
                        ? "text-gray-200 hover:text-gray-100"
                        : "text-gray-500 hover:text-gray-700"
                    } 
                    ${column.sortable ? "cursor-pointer" : ""}`}
                >
                  <div className="flex items-center">
                    {column.label}
                    {column.sortable && renderSortIcon(column.key)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Body */}
          <tbody
            className={`${
              theme === "dark" ? "bg-gray-800" : "bg-white"
            } divide-y ${
              theme === "dark" ? "divide-gray-600" : "divide-gray-200"
            }`}
          >
            {filteredAndSortedData.length > 0
              ? filteredAndSortedData.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => onRowClick && onRowClick(item)}
                    className={`${
                      onRowClick ? "cursor-pointer" : ""
                    } hover:bg-gray-100 dark:hover:bg-gray-700`}
                  >
                    {columns.map((column) => (
                      <td
                        key={column.key}
                        className={`px-6 py-4 text-sm whitespace-normal break-words
                     ${theme === "dark" ? "text-gray-200" : "text-gray-900"}`}
                      >
                        {customCellRenderers[column.key]
                          ? customCellRenderers[column.key](
                              item[column.key],
                              item
                            )
                          : item[column.key]}
                      </td>
                    ))}
                  </tr>
                ))
              : !isLoading && (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className="px-6 py-4 text-center text-sm"
                    >
                      <p
                        className={`${
                          theme === "dark" ? "text-gray-300" : "text-gray-500"
                        }`}
                      >
                        {noDataMessage}
                      </p>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>

        {/* Loading States */}
        {isLoading && renderLoadingState()}

        {/* Infinite Scroll Trigger */}
        {hasMore && !loadingMore && (
          <div ref={loadMoreRef} className="h-4" aria-hidden="true" />
        )}

        {/* Empty State */}
        {filteredAndSortedData.length === 0 && !isLoading && (
          <div className="text-center p-8 text-gray-500">{noDataMessage}</div>
        )}
      </div>
    </div>
    </div>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  defaultSortKey: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(["asc", "desc"]),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ).isRequired,
      filterFunction: PropTypes.func.isRequired,
    })
  ),
  searchPlaceholder: PropTypes.string,
  onRowClick: PropTypes.func,
  customCellRenderers: PropTypes.object,
  theme: PropTypes.oneOf(["light", "dark"]),
  noDataMessage: PropTypes.string,
  // New props for infinite scrolling
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
};

export const zIndexConfig = {
  datePickerDropdown: 50,
  tableHeader: 30,
  tableContent: 20,
};


export default React.memo(DataTable);
