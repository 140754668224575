import React, {
  useContext,
  useMemo,
  Suspense,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ThemeContext } from "../../../contexts/ThemeContext";
import DashboardLayout from "./DashboardLayout";
import DashboardRoutes from "./DashboardRoutes";
import { getNavigationItems } from "../../../utils/navigationConfig";
import Loading from "../../../components/common/Loading";
import {
  selectDashboardLoading,
  selectDashboardError,
  locationChanged,
} from "../../../store/slices/dashboardSlice";

import { locationSelectors } from "../../../store/shared/location/locationSlice";
import { selectUserRole } from "../../../store/shared/auth/authSelectors";
import { format, startOfMonth } from "date-fns";

const LoadingWrapper = ({ children, isLoading, error = null }) => {
  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-2">
            Error Loading Dashboard
          </h2>
          <p className="text-gray-600 dark:text-gray-300">{error}</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <Loading.Spinner fullScreen />;
  }

  return children;
};

const DashboardContent = ({ selectedRestaurant }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);

  // Request tracking refs
  const requestTracker = useRef({
    lastRequest: null,
    inProgress: false,
    timeoutId: null,
    lastLocation: null,
  });

  // Essential selectors
  const selectedLocation = useSelector(
    locationSelectors.selectSelectedLocation
  );
  const locations = useSelector(locationSelectors.selectLocations);
  const locationLoading = useSelector(locationSelectors.selectLocationLoading);
  const userRole = useSelector(selectUserRole);

  // Navigation setup
  const navigationItems = useMemo(
    () => getNavigationItems(userRole),
    [userRole]
  );
  const defaultRoute = useMemo(
    () => navigationItems[0]?.path || "/dashboard",
    [navigationItems]
  );

  // Add these selectors
  const isLoading = useSelector(selectDashboardLoading);
  const error = useSelector(selectDashboardError);

  // Fetch data with request tracking
  const today = new Date();
  const thisMonth = {
    startDate: format(startOfMonth(today), "yyyy-MM-dd"),
    endDate: format(today, "yyyy-MM-dd"),
  };

 
  // Then modify the useEffect to use the memoized object
  useEffect(() => {
    if (!selectedLocation) return;

    // Clear any pending timeouts
    if (requestTracker.current.timeoutId) {
      clearTimeout(requestTracker.current.timeoutId);
    }

    const locationId = selectedLocation.id || "all";

    // Only dispatch locationChanged if location actually changed
    if (requestTracker.current.lastLocation !== locationId) {
      dispatch(locationChanged(locationId));
      requestTracker.current.lastLocation = locationId;
    }
  }, [selectedLocation]);

  return (
    <LoadingWrapper
      isLoading={locationLoading || !selectedLocation}
      error={error}
    >
      <DashboardLayout
        navigationItems={navigationItems}
        brandName={selectedRestaurant?.name || "FeedBacked"}
      >
        <div className="relative">
          {isLoading && (
            <div className="fixed top-4 right-4">
              <Loading.Spinner size="small" />
            </div>
          )}
          <Suspense fallback={<Loading.Spinner />}>
            <Routes>
              <Route
                path="/*"
                element={
                  <DashboardRoutes
                    userRole={userRole}
                    selectedLocation={selectedLocation}
                    locations={locations}
                    selectedRestaurant={selectedRestaurant}
                  />
                }
              />
              <Route
                path="*"
                element={<Navigate to={defaultRoute} replace />}
              />
            </Routes>
          </Suspense>
        </div>
      </DashboardLayout>
    </LoadingWrapper>
  );
};

DashboardContent.propTypes = {
  selectedRestaurant: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default DashboardContent;
