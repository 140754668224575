import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../../../components/shadcn/dialog/Dialog';
import UserForm from './UserForm';

const UserFormModal = ({ isOpen, onClose, user }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{user ? 'Edit User' : 'Create New User'}</DialogTitle>
        </DialogHeader>
        <UserForm user={user} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default UserFormModal; 