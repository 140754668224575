import { createSlice, createAsyncThunk, createSelector} from "@reduxjs/toolkit";
import { getGoogleReviews, updateReviewResponse as updateReviewResponseApi, postReviewResponse, regenerateReviewResponseGPT, getReviewById,} from "../../../features/reviews/services/reviews.api";
import { locationSelectors } from "../location/locationSlice";
import { startOfDay, endOfDay, parseISO, isWithinInterval, startOfMonth, format  } from "date-fns";
import { selectDateFilter } from "../dateFilter/dateFilterSelectors";


const initialState = {
  data: [],
  loading: false,
  hasMore: true,
  error: null,
  approvingReviews: {},
  lastUpdated: null,
  cache: {},
  CACHE_DURATION: 5 * 60 * 1000, // 5 minutes
};

// Export the cache key generator helper before using it
export const generateCacheKey = (locationId, offset, dateFilter) => {
  return `${locationId}-${offset}-${dateFilter?.startDate}-${dateFilter?.endDate}`;
};

export const fetchReviews = createAsyncThunk(
  "reviews/fetchReviews",
  async ({ locationId, offset = 0, dateFilter }, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;
    const cache = state.reviews.cache;
    const cacheKey = generateCacheKey(locationId, offset, dateFilter);

    // Check cache first
    if (cache[cacheKey]) {
      const { data, timestamp } = cache[cacheKey];
      const isExpired = Date.now() - timestamp > state.reviews.CACHE_DURATION;

      if(!isExpired){
        return {data, offset, hasMore: data.length === 50, cacheKey};
      }
    }

    // Fetch new data if cache miss or expired
    const response = await getGoogleReviews(
      locationId,
      offset,
      50,
      accessToken,
      dateFilter
    );

    return {
      data: response.data,
      offset,
      hasMore: response.data.length === 50,
      cacheKey,
    };
  }
);



export const approveReview = createAsyncThunk(
  "reviews/approveReview",
  async ({ locationId, reviewId }, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;

    try {
      const response = await postReviewResponse(
        locationId,
        reviewId,
        accessToken
      );

      if (response.status === "success") {
        return { reviewId, status: "approved", location_id: locationId };
      }

      throw new Error(response.message || "Failed to approve review");
    } catch (error) {
      throw error;
    }
  }
);

export const updateReviewResponse = createAsyncThunk(
  "reviews/updateReviewResponse",
  async ({ locationId, reviewId, response }, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;

    try {
      const result = await updateReviewResponseApi(
        locationId,
        reviewId,
        response,
        accessToken
      );

      if (result.status === "success") {
        return { reviewId, response, locationId };
      }
      throw new Error(result.message || "Failed to update review response");
    } catch (error) {
      throw error;
    }
  }
);

export const regenerateReview = createAsyncThunk(
  "reviews/regenerateReview",
  async ({ locationId, reviewId, additionalInstructions }, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;

    try {
      const result = await regenerateReviewResponseGPT(
        locationId,
        reviewId,
        accessToken,
        additionalInstructions
      );

      if (result.status === "success") {
        return {
          reviewId,
          locationId,
          response: result.data.response,
        };
      }
      throw new Error(result.message || "Failed to regenerate response");
    } catch (error) {
      throw error;
    }
  }
);

export const fetchReviewById = createAsyncThunk(
  "reviews/fetchReviewById",
  async ({ locationId, reviewId }, { getState, rejectWithValue }) => {
    const state = getState();
    const { accessToken } = state.auth;

    try {
      const response = await getReviewById(locationId, reviewId, accessToken);
      if (response.status !== 'success') {
        return rejectWithValue(response.message || 'Failed to fetch review');
      }
      return response.data; // Return the actual review object
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    clearReviews: (state) => {
      state.data = [];
      state.hasMore = true;
      state.loading = false;
      state.error = null;
    },
    clearCache: (state) => {
      state.cache = {};
    },
    invalidateCache: (state, action) => {
      const cacheKey = action.payload;
      delete state.cache[cacheKey];
    },
    updateReviewStatus: (state, action) => {
      const { reviewId, status } = action.payload;
      const review = state.data.find(r => r.id === reviewId);
      if (review) {
        review.status = status;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReviews.fulfilled, (state, action) => {
        const { data, offset, hasMore, cacheKey } = action.payload;

        // Update cache
        state.cache[cacheKey] = {
          data,
          timestamp: Date.now(),
        };

        state.data = offset === 0 ? data : [...state.data, ...data];
        state.hasMore = hasMore;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Reviews fetch failed:", action.error);
      })
      .addCase(fetchReviewById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReviewById.fulfilled, (state, action) => {
        state.loading = false;
        const review = action.payload; // This is the review object

        if (review) {
          const exists = state.data.some(r => r.id === review.id);
          if (!exists) {
            state.data.push(review);
          } else {
            state.data = state.data.map(r => r.id === review.id ? review : r);
          }
        } else {
          state.error = 'Review data is undefined';
        }
      })
      .addCase(fetchReviewById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(approveReview.fulfilled, (state, action) => {
        const { reviewId, status } = action.payload;
        const review = state.data.find(r => r.id === reviewId);
        if (review) {
          review.status = status;
        }
      })
      .addCase(updateReviewResponse.fulfilled, (state, action) => {
        const { reviewId, response } = action.payload;
        const review = state.data.find(r => r.id === reviewId);
        if (review) {
          review.response = response;
          review.status = 'edited';
        }
      });
  },
});

// Selectors
export const selectReviewsState = (state) => state.reviews;
export const selectReviews = (state) => state.reviews.data;
export const selectReviewsLoading = (state) => state.reviews.loading;
export const selectReviewsHasMore = (state) => state.reviews.hasMore;
export const selectReviewsError = (state) => state.reviews.error;

export const selectFilteredReviews = createSelector(
  [
    selectReviews,
    state => state.location.selectedLocation?.id,
    selectDateFilter("reviews"),
  ],
  // Output Selector:
  (reviews, locationId, dateFilter) => {
    if (!Array.isArray(reviews)) return [];

    let filtered = reviews;

    // Filter by location if needed
    if (locationId && locationId !== "all") {
      filtered = filtered.filter( (review) => review.location_id === locationId);
    }

    // Filter by date range if present
    if (dateFilter?.startDate && dateFilter?.endDate) {
      filtered = filtered.filter((review) => {
        const reviewDate = startOfDay(parseISO(review.review_date));
        const startDate = startOfDay(parseISO(dateFilter.startDate));
        const endDate = endOfDay(parseISO(dateFilter.endDate));

        return isWithinInterval(reviewDate, { start: startDate, end: endDate });
      });
    }
    return filtered;
  }
);


export const selectReviewsDateFilter = createSelector(
  [selectDateFilter("reviews")],
  (dateFilter) => {
    if (dateFilter?.startDate && dateFilter?.endDate) {
      return dateFilter;
    }
    return {
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
    };
  }
);

export const selectReviewApproving = (state, reviewId) =>
  state.reviews?.approvingReviews[reviewId] || false;

export const { clearReviews, clearCache, invalidateCache, updateReviewStatus } =
  reviewSlice.actions;

export const selectTrendReviews = (state) =>
  state.reviews?.trends?.reviews || [];

export const selectTrendData = createSelector(
  [selectTrendReviews],
  (reviews) => {
    if (!reviews) return [];

    return reviews;
  }
);

export const selectReviewsCache = createSelector(
  [(state) => state.reviews?.cache],
  (cache) => cache || {}
);

export const selectReviewById = createSelector(
  [selectReviews, (_, reviewId) => reviewId],
  (reviews, reviewId) => reviews.find(review => review.id === reviewId)
);

export default reviewSlice.reducer;
