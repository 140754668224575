import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../../../components/shadcn/dialog/Dialog';
import { useSelector } from 'react-redux';
import { selectRestaurants } from '../../../../store/slices/restaurantSlice';

const LocationEditModal = ({ open, onClose, location, onEdit }) => {
  const restaurants = useSelector(selectRestaurants);
  const [formData, setFormData] = useState({
    name: '',
    restaurant_id: '',
  });

  useEffect(() => {
    if (location) {
      setFormData({
        name: location.name || '',
        restaurant_id: location.restaurant_id || '',
      });
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await onEdit(location.id, formData);
      onClose();
    } catch (error) {
      console.error('Failed to update location:', error);
    }
  };

  if (!open) return null;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>Edit Location</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name *
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Restaurant *
            </label>
            <select
              value={formData.restaurant_id}
              onChange={(e) => setFormData(prev => ({ ...prev, restaurant_id: e.target.value }))}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 bg-white"
              required
            >
              <option value="">Select Restaurant</option>
              {restaurants.map((restaurant) => (
                <option key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </option>
              ))}
            </select>
          </div>

          <DialogFooter>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Save Changes
            </button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default LocationEditModal; 