import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { getAllReports, generateAReport, deleteReport as apiDeleteReport } from '../../features/reports/services/reports.api';
import { selectDateFilter } from '../shared/dateFilter/dateFilterSelectors';
import { startOfDay, endOfDay, parseISO, isWithinInterval, startOfMonth, format } from 'date-fns';

const initialState = {
  data: [],
  loading: false,
  error: null,
  hasMore: true,
  cache: {},
  CACHE_DURATION: 5 * 60 * 1000, // 5 minutes
};

export const generateCacheKey = (offset, dateFilter, reportType) => {
  return `${offset}-${dateFilter?.startDate}-${dateFilter?.endDate}-${reportType}`;
};

export const fetchReports = createAsyncThunk(
  'reports/fetchReports',
  async ({ offset = 0, dateFilter, reportType }, { getState }) => {
    const state = getState();
    const { accessToken, selectedRestaurant } = state.auth;
    
    if (!selectedRestaurant?.id) {
      throw new Error('No restaurant selected');
    }

    const cache = state.reports.cache;
    const cacheKey = generateCacheKey(offset, dateFilter, reportType);

    if (cache[cacheKey]) {
      const { data, timestamp } = cache[cacheKey];
      const isExpired = Date.now() - timestamp > state.reports.CACHE_DURATION;

      if (!isExpired) {
        return { data, offset, hasMore: data.length === 50, cacheKey };
      }
    }

    const response = await getAllReports(
      selectedRestaurant.id,
      offset,
      50,
      accessToken,
      dateFilter,
      reportType
    );

    return {
      data: response.data,
      offset,
      hasMore: response.data.length === 50,
      cacheKey
    };
  }
);

export const deleteReport = createAsyncThunk(
  'reports/deleteReport',
  async ({ reportId }, { getState }) => {
    const state = getState();
    const { accessToken, selectedRestaurant } = state.auth;
    
    if (!selectedRestaurant?.id) {
      throw new Error('No restaurant selected');
    }

    await apiDeleteReport(selectedRestaurant.id, reportId, accessToken);
    return reportId;
  }
);

export const generateReport = createAsyncThunk(
  'reports/generateReport',
  async ({ reportType, dateFilter }, { getState }) => {
    const state = getState();
    const { accessToken, selectedRestaurant } = state.auth;
    
    if (!selectedRestaurant?.id) {
      throw new Error('No restaurant selected');
    }

    try {
      const response = await generateAReport(
        selectedRestaurant.id,
        reportType,
        dateFilter,
        accessToken
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearReports: (state) => {
      state.data = [];
      state.hasMore = true;
      state.loading = false;
      state.error = null;
    },
    clearCache: (state) => {
      state.cache = {};
    },
    invalidateCache: (state, action) => {
      const cacheKey = action.payload;
      delete state.cache[cacheKey];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        const { data, offset, hasMore, cacheKey } = action.payload;

        state.cache[cacheKey] = {
          data,
          timestamp: Date.now(),
        };

        state.data = offset === 0 ? data : [...state.data, ...data];
        state.hasMore = hasMore;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch reports.';
      })
      .addCase(deleteReport.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to delete report.';
      })
      .addCase(generateReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateReport.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [action.payload, ...state.data];
        state.error = null;
        state.cache = {};
      })
      .addCase(generateReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        const reportId = action.payload;
        state.data = state.data.filter(report => report.id !== reportId);
        state.error = null;
      });
  }
});

// Selectors
export const selectReportsState = (state) => state.reports;
export const selectReports = (state) => state.reports.data;
export const selectReportsLoading = (state) => state.reports.loading;
export const selectReportsHasMore = (state) => state.reports.hasMore;
export const selectReportsError = (state) => state.reports.error;

export const selectFilteredReports = createSelector(
  [
    selectReports,
    selectDateFilter('reports'),
    (_, reportType) => reportType
  ],
  (reports, dateFilter, reportType) => {
    if (!Array.isArray(reports)) return [];

    let filtered = reports;

    // Filter by report type
    if (reportType) {
      filtered = filtered.filter(report => report.report_type === reportType);
    }

    // Filter by date range - Add null checks here
    if (dateFilter?.startDate && dateFilter?.endDate) {
      filtered = filtered.filter((report) => {
        // Add null check for report.date
        if (!report.date) return false;
        
        try {
          const reportDate = startOfDay(parseISO(report.date));
          const startDate = startOfDay(parseISO(dateFilter.startDate));
          const endDate = endOfDay(parseISO(dateFilter.endDate));

          return isWithinInterval(reportDate, { start: startDate, end: endDate });
        } catch (error) {
          console.warn('Date parsing error:', error);
          return false;
        }
      });
    }

    return filtered;
  }
);

export const selectReportsCache = createSelector(
  [(state) => state.reports?.cache],
  (cache) => cache || {}
);

export const selectReportsDateFilter = createSelector(
  [selectDateFilter('reports')],
  (dateFilter) => {
    if (dateFilter?.startDate && dateFilter?.endDate) {
      return dateFilter;
    }
    return {
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
    };
  }
);

export const selectReportsByType = createSelector(
    [selectReports, (_, type) => type],
    (reports, type) => reports.filter(report => report.type === type)
  );
  
  export const selectReportsByPeriod = createSelector(
    [selectReports, (_, period) => period],
    (reports, period) => reports.filter(report => {
      const reportDate = new Date(report.period);
      return format(reportDate, 'yyyy-MM') === format(period, 'yyyy-MM');
    })
  );

export const { clearReports, clearCache, invalidateCache } = reportsSlice.actions;

export default reportsSlice.reducer;
