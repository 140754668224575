import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  getGoogleReviews, 
  getStaffMentions, 
  getMenuInsights 
} from '../../services/api/apiUtility';

// Initial state
const initialState = {
  metrics: null,
  loading: false,
  error: null,
  recentReviews: [],
  recentStaff: [],
  recentMenu: [],
  cache: {},
  CACHE_DURATION: 5 * 60 * 1000, // 5 minutes
  config: {
    recentItemsLimit: 3
  }
};

// Generate cache key
const generateCacheKey = (locationId, dateRange) => {
  return `${locationId}-${dateRange.startDate}-${dateRange.endDate}`;
};

// Async thunks
export const fetchReviews = createAsyncThunk(
  'home/fetchReviews',
  async ({ locationId, current, comparison }, { getState, rejectWithValue }) => {
    const state = getState();
    const { accessToken } = state.auth;
    
    // Check cache first
    const currentCacheKey = generateCacheKey(locationId, current);
    const previousCacheKey = generateCacheKey(locationId, comparison);
    
    const cache = state.home.cache;
    const currentCache = cache[currentCacheKey];
    const previousCache = cache[previousCacheKey];
    
    if (currentCache && previousCache) {
      const now = Date.now();
      if (now - currentCache.timestamp < state.home.CACHE_DURATION &&
          now - previousCache.timestamp < state.home.CACHE_DURATION) {
        return {
          current: currentCache.data,
          previous: previousCache.data
        };
      }
    }

    try {
      const [currentData, previousData] = await Promise.all([
        getGoogleReviews(locationId, 0, 1000, accessToken, current.startDate, current.endDate),
        getGoogleReviews(locationId, 0, 1000, accessToken, comparison.startDate, comparison.endDate)
      ]);

      if (!currentData || !previousData) {
        return rejectWithValue('Failed to fetch reviews data');
      }
      
      return {
        current: currentData.data || [],
        previous: previousData.data || []
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchStaffMentions = createAsyncThunk(
  'home/fetchStaffMentions',
  async ({ locationId, current, comparison }, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;

    const [currentData, previousData] = await Promise.all([
      getStaffMentions(locationId, 0, 1000, accessToken, current.startDate, current.endDate),
      getStaffMentions(locationId, 0, 1000, accessToken, comparison.startDate, comparison.endDate)
    ]);

    return {
      current: currentData.data || [],
      previous: previousData.data || []
    };
  }
);

export const fetchMenuInsights = createAsyncThunk(
  'home/fetchMenuInsights',
  async ({ locationId, current, comparison }, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;

    const [currentData, previousData] = await Promise.all([
      getMenuInsights(locationId, 0, 1000, accessToken, current.startDate, current.endDate),
      getMenuInsights(locationId, 0, 1000, accessToken, comparison.startDate, comparison.endDate)
    ]);

    return {
      current: currentData.data || [],
      previous: previousData.data || []
    };
  }
);

// Slice
const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    clearHomeData: (state) => {
      return { ...initialState, cache: state.cache };
    },
    clearCache: (state) => {
      state.cache = {};
    },
    invalidateCache: (state, action) => {
      const cacheKey = action.payload;
      delete state.cache[cacheKey];
    },
    setRecentItemsLimit: (state, action) => {
      state.config.recentItemsLimit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Reviews
      .addCase(fetchReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReviews.fulfilled, (state, action) => {
        const { current, previous } = action.payload;
        if (!state.metrics) {
          state.metrics = { currentMonth: {}, previousMonth: {} };
        }
        state.metrics.currentMonth.reviews = current;
        state.metrics.previousMonth.reviews = previous;
        state.recentReviews = current.slice(0, state.config.recentItemsLimit);
        
        // Update cache
        const currentCacheKey = generateCacheKey(action.meta.arg.locationId, action.meta.arg.current);
        const previousCacheKey = generateCacheKey(action.meta.arg.locationId, action.meta.arg.comparison);
        
        state.cache[currentCacheKey] = {
          data: current,
          timestamp: Date.now()
        };
        state.cache[previousCacheKey] = {
          data: previous,
          timestamp: Date.now()
        };
        
        state.loading = false;
      })
      .addCase(fetchReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      
      // Staff Mentions
      .addCase(fetchStaffMentions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStaffMentions.fulfilled, (state, action) => {
        const { current, previous } = action.payload;
        if (!state.metrics) {
          state.metrics = { currentMonth: {}, previousMonth: {} };
        }
        state.metrics.currentMonth.staffMentions = current;
        state.metrics.previousMonth.staffMentions = previous;
        state.recentStaff = current.slice(0, state.config.recentItemsLimit);
        state.loading = false;
      })
      .addCase(fetchStaffMentions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      
      // Menu Insights
      .addCase(fetchMenuInsights.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMenuInsights.fulfilled, (state, action) => {
        const { current, previous } = action.payload;
        if (!state.metrics) {
          state.metrics = { currentMonth: {}, previousMonth: {} };
        }
        state.metrics.currentMonth.menuMentions = current;
        state.metrics.previousMonth.menuMentions = previous;
        state.recentMenu = current.slice(0, state.config.recentItemsLimit);
        state.loading = false;
      })
      .addCase(fetchMenuInsights.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

// Selectors
export const selectHomeState = (state) => state.home;
export const selectHomeMetrics = (state) => state.home.metrics;
export const selectRecentReviews = (state) => state.home.recentReviews;
export const selectRecentStaff = (state) => state.home.recentStaff;
export const selectRecentMenu = (state) => state.home.recentMenu;
export const selectHomeLoading = (state) => state.home.loading;
export const selectHomeError = (state) => state.home.error;

// Actions
export const { clearHomeData, clearCache, invalidateCache } = homeSlice.actions;

export default homeSlice.reducer;
