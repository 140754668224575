import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getStaffProfiles } from '../../features/profile/services/profile.api';

const initialState = {
  profiles: [],
  loading: false,
  error: null,
};

export const fetchProfiles = createAsyncThunk(
  'profiles/fetchProfiles',
  async ({ token, dateFilter }, { rejectWithValue }) => {
    try {
      const response = await getStaffProfiles(token, dateFilter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const profileSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    clearProfiles: (state) => {
      state.profiles = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        state.loading = false;
        state.profiles = action.payload;
      })
      .addCase(fetchProfiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearProfiles } = profileSlice.actions;
export default profileSlice.reducer;
