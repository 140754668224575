import {
  addDays,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfYear,
  subYears
} from "date-fns";
import { formatDate, getCurrentDate } from '../utils/dateFilterUtils';

export const FILTER_TYPES = {
  SINGLE: "single",
  COMPARISON: "comparison",
};

export const SECTIONS = {
  REVIEWS: {
    id: "reviews",
    type: FILTER_TYPES.SINGLE,
    defaultPreset: "last_30_days",
  },
  STAFF: {
    id: "staff",
    type: FILTER_TYPES.SINGLE,
    defaultPreset: "last_30_days",
  },
  MENU: {
    id: "menu",
    type: FILTER_TYPES.SINGLE,
    defaultPreset: "last_30_days",
  },
  ANALYTICS: {
    id: "analytics",
    type: FILTER_TYPES.SINGLE,
    defaultPreset: "last_30_days",
  },
  REPORTS: {
    id: "reports",
    type: FILTER_TYPES.SINGLE,
    defaultPreset: "last_30_days",
  },
  TRENDS: {
    id: "trends",
    type: FILTER_TYPES.COMPARISON,
    defaultPreset: "last_7_days_vs_previous_7_days",
  },
  KEYWORDS: {
    id: "keywords",
    type: FILTER_TYPES.SINGLE,
    defaultPreset: "last_30_days",
  },
};

export const DATE_CONFIGURATIONS = {
  // Single date range presets
  last_7_days: {
    key: "last_7_days",
    label: "Last 7 days",
    type: FILTER_TYPES.SINGLE,
    getDates: (today) => ({
      startDate: formatDate(subDays(today, 6)),
      endDate: formatDate(today)
    }),
    withComparison: false,
  },
  last_30_days: {
    key: "last_30_days",
    label: "Last 30 days",
    type: FILTER_TYPES.SINGLE,
    getDates: (today) => ({
      startDate: formatDate(subDays(today, 29)),
      endDate: formatDate(today)
    }),
    withComparison: false,
  },
  this_month: {
    key: "this_month",
    label: "This month",
    type: FILTER_TYPES.SINGLE,
    getDates: (today) => ({
      startDate: formatDate(startOfMonth(today)),
      endDate: formatDate(today)
    }),
    withComparison: false,
  },
  last_month: {
    key: "last_month",
    label: "Last month",
    type: FILTER_TYPES.SINGLE,
    getDates: (today) => ({
      startDate: formatDate(startOfMonth(subMonths(today, 1))),
      endDate: formatDate(endOfMonth(subMonths(today, 1))),
    }),
    withComparison: false,
  },
  today_vs_yesterday:{
    key: "today_vs_yesterday",
    label: "Today vs Yesterday",
    type: FILTER_TYPES.COMPARISON,
    getDates: (today) => ({
      current: {
        startDate: formatDate(today),
        endDate: formatDate(today),
      },
      comparison: {
        startDate: formatDate(subDays(today, 1)),
        endDate: formatDate(subDays(today, 1)),
      },
    }),
  }, 
  // Comparison presets
  last_7_days_vs_previous_7_days: {
    key: "last_7_days_vs_previous_7_days",
    label: "Last 7 days vs Previous 7 days",
    type: FILTER_TYPES.COMPARISON,
    getDates: (today) => ({
      current: {
        startDate: formatDate(subDays(today, 6)),
        endDate: formatDate(today),
      },
      comparison: {
        startDate: formatDate(subDays(today, 13)),
        endDate: formatDate(subDays(today, 7)),
      },
    }),
    withComparison: true,
  },
  last_30_days_vs_previous_30_days: {
    key: "last_30_days_vs_previous_30_days",
    label: "Last 30 days vs Previous 30 days",
    type: FILTER_TYPES.COMPARISON,
    getDates: (today) => ({
      current: {
        startDate: formatDate(subDays(today, 29)),
        endDate: formatDate(today),
      },
      comparison: {
        startDate: formatDate(subDays(today, 59)),
        endDate: formatDate(subDays(today, 30)),
      },
    }),
    withComparison: true,
  },
  this_month_vs_last_month: {
    key: "this_month_vs_last_month",
    label: "This month vs Last month",
    type: FILTER_TYPES.COMPARISON,
    getDates: (today) => ({
      current: {
        startDate: formatDate(startOfMonth(today)),
        endDate: formatDate(today),
      },
      comparison: {
        startDate: formatDate(startOfMonth(subMonths(today, 1))),
        endDate: formatDate(endOfMonth(subMonths(today, 1))),
      },
    }),
    withComparison: true,
  },
  last_month_vs_month_before:{
    key: "last_month_vs_month_before",
    label: "Last month vs Month before",
    type: FILTER_TYPES.COMPARISON,
    getDates: (today) => ({
      current: {
        startDate: formatDate(startOfMonth(subMonths(today, 1))),
        endDate: formatDate(endOfMonth(subMonths(today, 1))),
      },
      comparison: {
        startDate: formatDate(startOfMonth(subMonths(today, 2))),
        endDate: formatDate(endOfMonth(subMonths(today, 2))),
      },
    }),
  },
  this_year_vs_last_year: {
    key: "this_year_vs_last_year",
    label: "This year vs Last year",
    type: FILTER_TYPES.COMPARISON,
    getDates: (today) => ({
      current: {
        startDate: formatDate(startOfYear(today)),
        endDate: formatDate(today),
      },
      comparison: {
        startDate: formatDate(startOfYear(subYears(today, 1))),
        endDate: formatDate(endOfYear(subYears(today, 1))),
      },
    }),
    withComparison: true,
  },
};

// Helper functions
export const getPresetsByType = (type) => {
  return Object.values(DATE_CONFIGURATIONS).filter(
    (preset) => preset.type === type
  );
};

export const getSectionPresets = (sectionId) => {
  const section = SECTIONS[sectionId];
  return getPresetsByType(section.type);
};

export const getDefaultPreset = (sectionId) => {
  const section = SECTIONS[sectionId];
  return DATE_CONFIGURATIONS[section.defaultPreset];
};

export const getSinglePresets = () => getPresetsByType(FILTER_TYPES.SINGLE);
export const getComparisonPresets = () => getPresetsByType(FILTER_TYPES.COMPARISON);
// For backward compatibility
export const PRESETS = DATE_CONFIGURATIONS;

export const getPresetDates = (preset, today = getCurrentDate()) => {
  const config = DATE_CONFIGURATIONS[preset];
  if (!config?.getDates) return null;
  try {
    return config.getDates(today);
  } catch (error) {
    console.error(`Error getting dates for preset ${preset}:`, error);
    return null;
  }
};

