import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLocations,
  createLocation,
  updateLocation as updateLocationApi,
  deleteLocation as deleteLocationApi,
} from "../../../services/api/apiUtility";

export const fetchLocations = createAsyncThunk(
  "location/fetchLocations",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { accessToken, userRole, selectedRestaurant },
      } = getState();

      if (!accessToken) {
        throw new Error("No access token available");
      }

      const response = await getLocations(accessToken);

      if (response.status === "success") {
        let fetchedLocations = response.data;

        if (userRole === "SuperAdmin" && selectedRestaurant) {
          fetchedLocations = fetchedLocations.filter(
            (location) => location.restaurant_id === selectedRestaurant.id
          );
        }

        if (["SuperAdmin", "Admin", "BOH"].includes(userRole)) {
          fetchedLocations.unshift({ name: "All", id: "all" });
        }

        return fetchedLocations;
      } else {
        throw new Error(response.message || "Failed to fetch locations");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addLocation = createAsyncThunk(
  "location/addLocation",
  async (locationData, { getState, rejectWithValue, dispatch }) => {
    try {
      const { auth: { accessToken } } = getState();
      const response = await createLocation(locationData, accessToken);
      
      if (response.status === "success") {
        await dispatch(fetchLocations());
        return response.data;
      }
      throw new Error(response.message || "Failed to add location");
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateLocation = createAsyncThunk(
  "location/updateLocation",
  async ({ id, locationData }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { auth: { accessToken } } = getState();
      const response = await updateLocationApi(id, locationData, accessToken);
      
      if (response.status === "success") {
        await dispatch(fetchLocations());
        return response.data;
      }
      throw new Error(response.message || "Failed to update location");
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "location/deleteLocation",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const { auth: { accessToken } } = getState();
      await deleteLocationApi(id, accessToken);
      await dispatch(fetchLocations());
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
