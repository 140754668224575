import { apiRequest } from "../../../services/api/apiUtility";
import { API_ENDPOINTS } from "../../../services/api/config";
import { parseISO, format } from 'date-fns';
/**
 * Staff-related API functions
 */

export const getStaffMentions = async (locationId, offset = 0, limit = 50, token, dateFilter = null) => {
  try {
    // Build base URL
    let url = API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PAGINATED(locationId, offset, limit)

    // Add date parameters only if dateFilter is provided and has both dates
    if (dateFilter?.startDate && dateFilter?.endDate) {
      const startDate = format(parseISO(dateFilter.startDate), "yyyy-MM-dd");
      const endDate = format(parseISO(dateFilter.endDate), "yyyy-MM-dd");
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }

    return await apiRequest(
      url,
      "GET",
      null,
      {}, // Empty params since we're adding dates to URL
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error("Error fetching staff mentions:", error);
    throw error;
  }
};

// Add additional staff-related API functions here
