import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSubscriptionTiers,
  createSubscriptionTier,
  updateSubscriptionTier,
  deleteSubscriptionTier
} from '../../services/api/apiUtility';

// Initial state
const initialState = {
  tiers: [],
  loading: false,
  error: null,
};

// Async thunks
export const fetchSubscriptionTiers = createAsyncThunk(
  'subscription/fetchSubscriptionTiers',
  async (token, { rejectWithValue }) => {
    try {
      const response = await getSubscriptionTiers(token);
      if (response.status === 'success') {
        return response.data;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch subscription tiers');
    }
  }
);

export const addSubscriptionTier = createAsyncThunk(
  'subscription/addSubscriptionTier',
  async ({ tierData, token }, { rejectWithValue }) => {
    try {
      const response = await createSubscriptionTier(tierData, token);
      if (response.status === 'success') {
        return response.data;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to add subscription tier');
    }
  }
);

export const modifySubscriptionTier = createAsyncThunk(
  'subscription/modifySubscriptionTier',
  async ({ tierId, tierData, token }, { rejectWithValue }) => {
    try {
      const response = await updateSubscriptionTier(tierId, tierData, token);
      if (response.status === 'success') {
        return response.data;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update subscription tier');
    }
  }
);

export const removeSubscriptionTier = createAsyncThunk(
  'subscription/removeSubscriptionTier',
  async ({ tierId, token }, { rejectWithValue }) => {
    try {
      const response = await deleteSubscriptionTier(tierId, token);
      if (response.status === 'success') {
        return tierId;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to delete subscription tier');
    }
  }
);

// Slice
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionTiers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubscriptionTiers.fulfilled, (state, action) => {
        state.loading = false;
        state.tiers = action.payload;
      })
      .addCase(fetchSubscriptionTiers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addSubscriptionTier.fulfilled, (state, action) => {
        state.tiers.push(action.payload);
      })
      .addCase(modifySubscriptionTier.fulfilled, (state, action) => {
        const index = state.tiers.findIndex(tier => tier.id === action.payload.id);
        if (index !== -1) {
          state.tiers[index] = action.payload;
        }
      })
      .addCase(removeSubscriptionTier.fulfilled, (state, action) => {
        state.tiers = state.tiers.filter(tier => tier.id !== action.payload);
      });
  }
});

// Selectors
export const selectSubscriptionTiers = (state) => state.subscription.tiers;
export const selectSubscriptionLoading = (state) => state.subscription.loading;
export const selectSubscriptionError = (state) => state.subscription.error;

export default subscriptionSlice.reducer;
