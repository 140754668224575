import { apiRequest } from '../../../services/api/apiUtility';
import { API_ENDPOINTS } from '../../../services/api/config';
import { parseISO, format } from 'date-fns';

/**
 * Keywords-related API functions
 */

export const getLocationKeywordOccurrences = async (locationId, token, { offset, limit, dateFilter } = {}) => {
  try {
    let params = {};
    
    // Add date parameters if provided
    if (dateFilter?.startDate && dateFilter?.endDate) {
      params.startDate = format(parseISO(dateFilter.startDate), 'yyyy-MM-dd');
      params.endDate = format(parseISO(dateFilter.endDate), 'yyyy-MM-dd');
    }

    const endpoint = API_ENDPOINTS.LOCATION.KEYWORDS.LOCATION.ALL_OCCURRENCES(
      locationId,
      offset,
      limit
    );
    
    return await apiRequest(
      endpoint,
      'GET',
      null,
      params,
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching location keyword occurrences:', error);
    throw error;
  }
};

export const getLocationKeywordsByCategory = async (locationId, category, token, { offset, limit, dateFilter } = {}) => {
  try {
    let params = {};
    
    if (dateFilter?.startDate && dateFilter?.endDate) {
      params.startDate = format(parseISO(dateFilter.startDate), 'yyyy-MM-dd');
      params.endDate = format(parseISO(dateFilter.endDate), 'yyyy-MM-dd');
    }

    const endpoint = API_ENDPOINTS.LOCATION.KEYWORDS.LOCATION.CATEGORY_OCCURRENCES(
      locationId,
      category,
      offset,
      limit
    );

    return await apiRequest(
      endpoint,
      'GET',
      null,
      params,
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching location keywords by category:', error);
    throw error;
  }
};

export const getLocationKeywordDetails = async (locationId, keyword, token) => {
  try {
    const endpoint = API_ENDPOINTS.LOCATION.KEYWORDS.LOCATION.KEYWORD_DETAILS(
      locationId, 
      keyword
    );
    
    return await apiRequest(
      endpoint,
      'GET',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching location keyword details:', error);
    throw error;
  }
};

// Restaurant-level keyword functions
export const getRestaurantKeywordOccurrences = async (restaurantId, token, { offset, limit, dateFilter } = {}) => {
  try {
    let params = {};
    
    if (dateFilter?.startDate && dateFilter?.endDate) {
      params.startDate = format(parseISO(dateFilter.startDate), 'yyyy-MM-dd');
      params.endDate = format(parseISO(dateFilter.endDate), 'yyyy-MM-dd');
    }

    const endpoint = API_ENDPOINTS.LOCATION.KEYWORDS.RESTAURANT.ALL_OCCURRENCES(
      restaurantId,
      offset,
      limit
    );
    
    return await apiRequest(
      endpoint,
      'GET',
      null,
      params,
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching restaurant keyword occurrences:', error);
    throw error;
  }
};

export const getRestaurantKeywordsByCategory = async (restaurantId, category, token, { offset, limit, dateFilter } = {}) => {
  try {
    let params = {};
    
    if (dateFilter?.startDate && dateFilter?.endDate) {
      params.startDate = format(parseISO(dateFilter.startDate), 'yyyy-MM-dd');
      params.endDate = format(parseISO(dateFilter.endDate), 'yyyy-MM-dd');
    }

    const endpoint = API_ENDPOINTS.LOCATION.KEYWORDS.RESTAURANT.CATEGORY_OCCURRENCES(
      restaurantId,
      category,
      offset,
      limit
    );

    return await apiRequest(
      endpoint,
      'GET',
      null,
      params,
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching restaurant keywords by category:', error);
    throw error;
  }
};

export const getRestaurantKeywordDetails = async (restaurantId, keyword, token) => {
  try {
    const endpoint = API_ENDPOINTS.LOCATION.KEYWORDS.RESTAURANT.KEYWORD_DETAILS(
      restaurantId,
      keyword
    );
    
    return await apiRequest(
      endpoint,
      'GET',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching restaurant keyword details:', error);
    throw error;
  }
}; 