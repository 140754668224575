import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  createUser, 
  updateUser, 
  selectRoles 
} from '../../../../store/shared/user/userSlice';
import { selectAccessToken } from '../../../../store/shared/auth/authSelectors';
import UserRoleSelector from './UserRoleSelector';

const UserForm = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles);
  const token = useSelector(selectAccessToken);
  
  const [formData, setFormData] = useState({
    username: user?.username || '',
    email: user?.email || '',
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    roles: user?.roles || [],
    location_id: user?.location_id || '',
    enabled: user?.enabled ?? true
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user) {
      dispatch(updateUser({ id: user.id, userData: formData, token }));
    } else {
      dispatch(createUser({ userData: formData, token }));
    }
    onClose();
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          required
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          required
        />
      </div>

      <UserRoleSelector
        selectedRoles={formData.roles}
        onChange={(roles) => setFormData({ ...formData, roles })}
        availableRoles={roles}
      />

      <div>
        <label className="block text-sm font-medium text-gray-700">Status</label>
        <select
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border rounded-md"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          {user ? 'Update' : 'Create'} User
        </button>
      </div>
    </form>
  );
};

export default UserForm; 