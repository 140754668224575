import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { getMenuInsights } from '../../../features/menu/services/menu.api';
import { selectDateFilter } from '../../shared/dateFilter/dateFilterSelectors';
import { startOfDay, endOfDay, parseISO, isWithinInterval, startOfMonth, format } from 'date-fns';

const initialState = {
  data: [],
  loading: false,
  error: null,
  hasMore: true,
  cache: {},
  CACHE_DURATION: 5 * 60 * 1000,
};

export const generateCacheKey = (locationId, offset, dateFilter) => {
  return `${locationId}-${offset}-${dateFilter?.startDate}-${dateFilter?.endDate}`;
};

export const fetchMenuInsights = createAsyncThunk(
  'menu/fetchInsights',
  async ({ locationId, offset = 0, dateFilter }, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;
    const cache = state.menu.cache;
    const cacheKey = generateCacheKey(locationId, offset, dateFilter);

    if (cache[cacheKey]) {
      const { data, timestamp } = cache[cacheKey];
      const isExpired = Date.now() - timestamp > state.menu.CACHE_DURATION;

      if (!isExpired) {
        return { data, offset, hasMore: data.length === 50, cacheKey };
      }
    }

    // Fetch new data if cache miss or expired
    const response = await getMenuInsights(
      locationId,
      offset,
      50,
      accessToken,
      dateFilter
    );

    return {
      data: response.data,
      offset,
      hasMore: response.data.length === 50,
      cacheKey
    };
  }
);

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    clearMenuItems: (state) => {
      state.data = [];
      state.hasMore = true;
      state.loading = false;
      state.error = null;
    },
    clearCache: (state) => {
      state.cache = {};
    },
    invalidateCache: (state, action) => {
      const cacheKey = action.payload;
      delete state.cache[cacheKey];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuInsights.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenuInsights.fulfilled, (state, action) => {
        const { data, offset, hasMore, cacheKey } = action.payload;
        
        // Update cache
        state.cache[cacheKey] = {
          data,
          timestamp: Date.now(),
        };

        state.data = offset === 0 ? data : [...state.data, ...data];
        state.hasMore = hasMore;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMenuInsights.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

// Selectors
export const selectMenuState = (state) => state.menu;
export const selectMenuItems = (state) => state.menu.data;
export const selectMenuLoading = (state) => state.menu.loading;
export const selectMenuHasMore = (state) => state.menu.hasMore;
export const selectMenuError = (state) => state.menu.error;

export const selectFilteredMenuItems = createSelector(
  [
    selectMenuItems,                                 // 1. Gets all menu items
    state => state.location.selectedLocation?.id,    // 2. Gets the selected location ID
    selectDateFilter('menu')                         // 3. Gets the date filter for the menu
  ],
  // Output Selector:
  (menuItems, locationId, dateFilter) => {
    if (!Array.isArray(menuItems)) return [];
    
    let filtered = menuItems;

    // Filter by location if needed
    if (locationId && locationId !== "all") {
      filtered = filtered.filter(item => item.location_id === locationId);
    }
    
    // Filter by date range if present
    if (dateFilter?.startDate && dateFilter?.endDate) {
      filtered = filtered.filter((menuItem) => {
        const itemDate = startOfDay(parseISO(menuItem.review_date));
        const startDate = startOfDay(parseISO(dateFilter.startDate));
        const endDate = endOfDay(parseISO(dateFilter.endDate));
        
        return isWithinInterval(itemDate, { start: startDate, end: endDate });
      });
    }

    return filtered;
  }
);

export const selectMenuCache = createSelector(
  [(state) => state.menu?.cache],
  (cache) => cache || {}
);

export const selectMenuDateFilter = createSelector(
  [selectDateFilter('menu')],
  (dateFilter) => {
    if (dateFilter?.startDate && dateFilter?.endDate) {
      return dateFilter;
    }
    return {
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
    };
  }
);

export const { clearMenuItems, clearCache, invalidateCache } = menuSlice.actions;

export default menuSlice.reducer;
