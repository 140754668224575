import React from 'react';
import PropTypes from 'prop-types';

const SidebarGroup = ({ children, className }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      {children}
    </div>
  );
};

SidebarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SidebarGroup.defaultProps = {
  className: '',
};

export default SidebarGroup;
