import { useState } from 'react';
import { Building2, Star, Calendar, Settings, Trash2, Edit, Link, MapPin } from 'lucide-react';
import RestaurantEditModal from './RestaurantEditModal';
import { useSelector } from 'react-redux';
import { selectLocations } from '../../../../store/shared/location/locationSelectors';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../../../components/shadcn/dialog/Dialog';

const RestaurantCard = ({ restaurant, onDelete, subscriptionTiers, onEdit }) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [confirmationName, setConfirmationName] = useState('');
    const allLocations = useSelector(selectLocations);
    const restaurantLocations = allLocations.filter(
      location => location.restaurant_id === restaurant.id
    );
  
    const getTierName = (tierId) => {
      if (!tierId) return 'No Tier';
      const tier = subscriptionTiers.find(t => t.id === tierId);
      return tier ? tier.name : 'No Tier';
    };
  
    // Parse integrations
    const getIntegrations = () => {
      const integrations = Array.isArray(restaurant.integrations)
        ? restaurant.integrations[0]?.includes(',')
          ? restaurant.integrations[0].split(',').map(i => i.trim())
          : restaurant.integrations
        : restaurant.integrations?.split(',').map(i => i.trim()) || [];
      
      return integrations.filter(i => i); // Remove empty strings
    };
  
    const formatDelay = (minutes) => {
      if (!minutes) return 'No delay';
      if (minutes < 60) return `${minutes} minutes`;
      if (minutes < 1440) return `${minutes / 60} hours`;
      if (minutes < 10080) return `${minutes / 1440} days`;
      return `${minutes / 10080} weeks`;
    };
  
    const handleConfirmDelete = () => {
      // Trim both strings to remove any extra spaces
      const normalizedInput = confirmationName.trim();
      const normalizedName = restaurant.name.trim();
      
      if (normalizedInput === normalizedName) {
        onDelete(restaurant.id);
        setShowDeleteConfirm(false);
        setConfirmationName('');
      }
    };
  
    return (
      <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all">
        <div className="flex items-center justify-between mb-4">
          <div className="flex flex-col">
            <div className="flex items-center">
              <Building2 className="w-6 h-6 text-gray-500 mr-3" />
              <h3 className="text-lg font-semibold text-gray-800">{restaurant.name}</h3>
            </div>
            <span className="text-xs text-gray-400 ml-9">ID: {restaurant.id}</span>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowEditModal(true)}
              className="text-blue-500 hover:text-blue-700 transition-colors"
            >
              <Edit className="w-5 h-5" />
            </button>
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="text-red-500 hover:text-red-700 transition-colors"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex items-center text-gray-600">
            <Star className="w-4 h-4 mr-2" />
            <span className="text-sm">
              {getTierName(restaurant.subscription_tier_id)}
            </span>
          </div>
          <div className="flex items-center text-gray-600">
            <Calendar className="w-4 h-4 mr-2" />
            <span className="text-sm">
              Report Day: {restaurant.weekly_report_day || 'Not Set'}
            </span>
          </div>
        </div>

        {/* Integrations Section */}
        <div className="mb-4">
          <div className="flex items-center text-gray-600 mb-2">
            <Link className="w-4 h-4 mr-2" />
            <span className="text-sm font-medium">Integrations</span>
          </div>
          <div className="flex flex-wrap gap-2">
            {getIntegrations().length > 0 ? (
              getIntegrations().map((integration, index) => (
                <span 
                  key={index}
                  className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full"
                >
                  {integration}
                </span>
              ))
            ) : (
              <span className="text-sm text-gray-500">No integrations</span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center text-gray-600">
            <Settings className="w-4 h-4 mr-2" />
            <span className="text-sm">
              {restaurant.main_open_ai_model ? 'AI Enabled' : 'AI Disabled'}
            </span>
          </div>
          <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors">
            Manage
          </button>
        </div>
  
        {/* Auto Approval Section */}
        <div className="mt-4 pt-4 border-t border-gray-200">
          <div className="flex items-center justify-between text-sm">
            <div className="flex items-center text-gray-600">
              <Settings className="w-4 h-4 mr-2" />
              <span>
                Auto Approval: {restaurant.auto_approve_reviews ? 'Enabled' : 'Disabled'}
              </span>
            </div>
            {restaurant.auto_approve_reviews && (
              <span className="text-gray-500">
                Delay: {formatDelay(restaurant.auto_approval_minutes_delay)}
              </span>
            )}
          </div>
        </div>
  
        {/* Edit Modal */}
        <RestaurantEditModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          restaurant={restaurant}
          subscriptionTiers={subscriptionTiers}
          onEdit={onEdit}
        />
  
        {/* Delete Confirmation Modal */}
        <Dialog open={showDeleteConfirm} onOpenChange={setShowDeleteConfirm}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Deletion</DialogTitle>
            </DialogHeader>
            
            <div className="py-4">
              <p className="mb-4">
                This action cannot be undone. Please type <strong>{restaurant.name}</strong> to confirm deletion.
              </p>
              <input
                type="text"
                value={confirmationName}
                onChange={(e) => setConfirmationName(e.target.value)}
                placeholder="Type restaurant name"
                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <div className="text-xs text-gray-500 mt-2">
                Typed: "{confirmationName}"
                <br />
                Expected: "{restaurant.name}"
              </div>
            </div>

            <DialogFooter>
              <button
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setConfirmationName('');
                }}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                disabled={confirmationName.trim() !== restaurant.name.trim()}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Delete Restaurant
              </button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Add Locations count section */}
        <div className="flex items-center text-gray-600 mt-2">
          <MapPin className="w-4 h-4 mr-2" />
          <span className="text-sm">
            {restaurantLocations.length} Location{restaurantLocations.length !== 1 ? 's' : ''}
          </span>
        </div>
      </div>
    );
  };

export default RestaurantCard;