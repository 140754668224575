// src/App.js
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { 
  selectIsAuthenticated, 
  selectAuthInitialized,
  selectUserRole,
  selectSelectedRestaurant 
} from './store/shared/auth/authSelectors';
import Loading from "./components/common/Loading";
import Login from "./features/auth/components/Login";
import DashboardClient from "./features/dashboard/components/DashboardClient";
import LandingPage from "./features/public/components/LandingPage";
import Blog from "./features/public/components/Blog";
import Enterprise from "./features/public/components/Enterprise";
import Pricing from "./features/public/components/Pricing";
import Solutions from "./features/public/components/Solutions";
import { ThemeProvider } from "./contexts/ThemeContext";
import "./assets/styles/index.css";
import "./assets/styles/App.css";
import OnboardingFlow from "./features/public/onboarding/components/OnboardingFlow";
import { refreshAuth } from './store/shared/auth/authThunks';
import { setAuthInitialized } from './store/shared/auth/authSlice';
import PublicLayout from "./components/layout/PublicLayout";
import EnterpriseQuote from "./features/public/components/EnterpriseQuote";
import SuperAdmin from "./features/superadmin/components/SuperAdmin";

// Protected Route Component
const ProtectedRoute = ({ children, allowedRoles = [], requireRestaurant = false }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const authInitialized = useSelector(selectAuthInitialized);
  const userRole = useSelector(selectUserRole);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);

  if (!authInitialized) {
    return <Loading.Spinner fullScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Check role access first
  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
    return <Navigate to="/dashboard" />;
  }

  // SuperAdmin bypass for restaurant requirement
  if (userRole === "SuperAdmin") {
    return children;
  }

  // Handle restaurant requirement for non-SuperAdmin users
  if (requireRestaurant && !selectedRestaurant) {
    return <Navigate to="/dashboard/home" />;
  }

  return children;
};

// Public Route Component
const PublicRoute = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userRole = useSelector(selectUserRole);
  
  if (isAuthenticated) {
    // Redirect based on role
    if (userRole === "SuperAdmin") {
      return <Navigate to="/superadmin" replace />;
    }
    return <Navigate to="/dashboard/home" replace />;
  }
  
  return children;
};

function App() {
  const dispatch = useDispatch();
  const authInitialized = useSelector(selectAuthInitialized);

  // Try to initialize auth on app load
  useEffect(() => {
    if (!authInitialized) {
      dispatch(refreshAuth())
        .finally(() => {
          // Ensure authInitialized is set even if refresh fails
          dispatch(setAuthInitialized(true));
        });
    }
  }, [dispatch, authInitialized]);

  if (!authInitialized) {
    return <Loading.Spinner fullScreen />;
  }

  return (
    <ThemeProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <PublicRoute>
                <PublicLayout>
                  <LandingPage />
                </PublicLayout>
              </PublicRoute>
            }
          />
          <Route 
            path="/blog" 
            element={
              <PublicLayout>
                <Blog />
              </PublicLayout>
            } 
          />
          <Route 
            path="/enterprise" 
            element={
              <PublicLayout>
                <Enterprise />
              </PublicLayout>
            } 
          />
          <Route 
            path="/solutions" 
            element={
              <PublicLayout>
                <Solutions />
              </PublicLayout>
            } 
          />
          <Route 
            path="/partners" 
            element={
              <PublicLayout>
                {/* <Partners /> */}
              </PublicLayout>
            } 
          />
          <Route 
            path="/resources" 
            element={
              <PublicLayout>
                {/* <Resources /> */}
              </PublicLayout>
            } 
          />
          <Route 
            path="/pricing" 
            element={
              <PublicLayout>
                <Pricing />
              </PublicLayout>
            } 
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route 
            path="/onboarding" 
            element={
                <OnboardingFlow />
            } 
          />
          <Route 
            path="/enterprise-quote" 
            element={
              <PublicLayout>
                <EnterpriseQuote />
              </PublicLayout>
            } 
          />

          {/* Protected Routes */}
          <Route
            path="/superadmin/*"
            element={
              <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                <SuperAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute requireRestaurant>
                <DashboardClient />
              </ProtectedRoute>
            }
          />

          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;