import React from 'react';
import { Bot, MessageSquare, Users, Star, BarChart4, Search, Settings2, Zap, Clock } from 'lucide-react';

const SolutionCard = ({ icon: Icon, title, description, features }) => (
  <div className="p-8 bg-white rounded-xl shadow-sm hover:shadow-xl transition-all duration-300">
    <div className="bg-blue-100 w-16 h-16 rounded-full flex items-center justify-center mb-6 transform hover:rotate-12 transition-transform">
      <Icon className="h-8 w-8 text-blue-600" />
    </div>
    <h3 className="text-xl font-semibold mb-4">{title}</h3>
    <p className="text-gray-600 mb-6">{description}</p>
    <ul className="space-y-3">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          <Zap className="h-5 w-5 text-blue-500 mr-2 mt-1 flex-shrink-0" />
          <span className="text-gray-700">{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

const Solutions = () => {
  const solutions = [
    {
      icon: MessageSquare,
      title: "Smart Review Responses",
      description: "AI-powered response generation that maintains your brand voice",
      features: [
        "Personalized response templates",
        "Sentiment analysis",
        "Multi-language support",
        "Brand voice customization"
      ]
    },
    {
      icon: Users,
      title: "Team Performance Insights",
      description: "Track and improve staff performance through customer feedback",
      features: [
        "Staff mention tracking",
        "Performance analytics",
        "Recognition systems",
        "Training recommendations"
      ]
    },
    {
      icon: BarChart4,
      title: "Analytics & Reporting",
      description: "Comprehensive insights into your customer feedback and performance",
      features: [
        "Customizable dashboards",
        "Trend analysis",
        "Competitive benchmarking",
        "Export capabilities"
      ]
    },
    {
      icon: Search,
      title: "Review Monitoring",
      description: "Centralized platform for managing all your review sources",
      features: [
        "Real-time monitoring",
        "Multi-platform integration",
        "Automated alerts",
        "Review consolidation"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-50 to-transparent" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-gray-900 mb-8">
              Complete Solutions for
              <br />
              <span className="text-blue-600">Restaurant Review Management</span>
            </h1>
            <p className="max-w-3xl mx-auto text-xl text-gray-600 mb-8">
              Everything you need to manage, analyze, and improve your restaurant's online presence
              and customer experience in one powerful platform.
            </p>
            <div className="flex justify-center space-x-4">
              <button className="bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition-all transform hover:scale-105 hover:shadow-lg">
                Get Started
              </button>
              <button className="bg-white text-gray-900 px-8 py-4 rounded-lg border-2 border-gray-200 hover:border-blue-600 transition-all">
                View Demo
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Solutions Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid md:grid-cols-2 gap-8">
          {solutions.map((solution) => (
            <SolutionCard key={solution.title} {...solution} />
          ))}
        </div>
      </div>

      {/* Features Overview */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Why Choose Our Platform?
            </h2>
            <p className="text-xl text-gray-600">
              Comprehensive features designed specifically for restaurants
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Settings2,
                title: "Easy Integration",
                description: "Seamlessly connects with all major review platforms"
              },
              {
                icon: Clock,
                title: "Time-Saving",
                description: "Reduce response time by up to 75% with AI assistance"
              },
              {
                icon: Star,
                title: "Improved Ratings",
                description: "Average rating increase of 1.2 stars within 3 months"
              }
            ].map((feature) => (
              <div key={feature.title} className="text-center p-6">
                <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
                  <feature.icon className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-8">
            Ready to Transform Your Restaurant's Online Presence?
          </h2>
          <p className="text-xl mb-8 text-blue-100">
            Join thousands of restaurants already using our platform to improve their customer experience
          </p>
          <button className="bg-white text-blue-600 px-8 py-4 rounded-lg hover:bg-blue-50 transition-all transform hover:scale-105 hover:shadow-lg">
            Start Free Trial
          </button>
        </div>
      </div>
    </div>
  );
};

export default Solutions;