import { apiRequest } from "../../../services/api/apiUtility";
import { API_ENDPOINTS } from "../../../services/api/config";
import { getGoogleReviews,  } from "../../../features/reviews/services/reviews.api";
import { getMenuInsights } from "../../../features/menu/services/menu.api";

const EXCLUDED_TERMS = [
  'food',
  'drinks',
  'dishes',
  'starter',
  'drink',
  'dish',
  'starters',
  'veggies',
  'veggie',
  'foods',
  'take',
  'takes'
];

/**
 * Checks if an item name is a generic term that should be excluded
 * @param {string} itemName - The menu item name to check
 * @returns {boolean} - True if the item should be excluded
 */
const isGenericTerm = (itemName) => {
  const trimmedItem = itemName.trim();
  // Convert to lowercase for case-insensitive comparison
  const lowercaseItem = trimmedItem.toLowerCase();
  
  // Check if the entire trimmed item exactly matches any excluded term
  return EXCLUDED_TERMS.some(term => 
    // Use word boundary \b to match whole words only
    new RegExp(`^\\s*${term}\\s*$`, 'i').test(trimmedItem)
  );
};

/**
 * Fetch all Google Reviews for the specified location and date range.
 * This function handles pagination to retrieve all relevant reviews.
 * @param {string} locationId - ID of the location or 'all' for all locations.
 * @param {string} startDate - Start date in 'yyyy-MM-dd' format.
 * @param {string} endDate - End date in 'yyyy-MM-dd' format.
 * @param {string} token - Authorization token.
 * @returns {Promise<Array>} - Array of all fetched reviews.
 */
export const fetchAllGoogleReviews = async (
  locationId,
  startDate,
  endDate,
  token
) => {
  let allReviews = [];
  let offset = 0;
  const limit = 1000; // Adjust based on API limits or performance considerations

  while (true) {
    const response = await getGoogleReviews(locationId, offset, limit, token, {startDate, endDate});
    const reviews = response.data;

    if (!reviews || reviews.length === 0) {
      break;
    }

    allReviews = allReviews.concat(reviews);

    if (reviews.length < limit) {
      break; // No more pages to fetch
    }

    offset += limit;
  }

  return allReviews;
};

/**
 * Fetch menu insights for charts
 * @param {string} locationId - ID of the location or 'all' for all locations
 * @param {string} startDate - Start date in 'yyyy-MM-dd' format
 * @param {string} endDate - End date in 'yyyy-MM-dd' format
 * @param {string} token - Authorization token
 * @returns {Promise<Array>} - Array of menu insights
 */
export const fetchMenuInsightsForCharts = async (
  locationId,
  startDate,
  endDate,
  token
) => {
  let allInsights = [];
  let offset = 0;
  const limit = 1000;

  while (true) {
    const response = await getMenuInsights(locationId, offset, limit, token, { startDate, endDate });
    const insights = response.data;

    if (!insights || insights.length === 0) {
      break;
    }

    // Filter out generic terms before adding to allInsights
    const filteredInsights = insights.filter(insight => !isGenericTerm(insight.item));
    allInsights = allInsights.concat(filteredInsights);

    if (insights.length < limit) {
      break;
    }

    offset += limit;
  }

  return allInsights;
};

/**
 * Fetch staff performance data
 * @param {string} locationId - ID of the location or 'all' for all locations
 * @param {string} startDate - Start date in 'yyyy-MM-dd' format
 * @param {string} endDate - End date in 'yyyy-MM-dd' format
 * @param {string} token - Authorization token
 * @returns {Promise<Array>} - Array of staff performance data
 */
export const getStaffPerformance = async (locationId, startDate, endDate, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PERFORMANCE.BY_LOCATION(locationId, startDate, endDate),
      'GET',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error('Error fetching staff performance:', error);
    throw error;
  }
};
