import { apiRequest } from '../../../services/api/apiUtility';
import { API_ENDPOINTS } from '../../../services/api/config';
import { parseISO, format } from 'date-fns';

/**
 * Report-related API functions
 */

export const getAllReports = async (restaurantId, offset = 0, limit = 50, token, dateFilter, reportType) => {
  try {
    const url = API_ENDPOINTS.RESTAURANT.REPORTS.PAGINATED(restaurantId, offset, limit);
    const params = {};

    if (dateFilter?.startDate && dateFilter?.endDate) {
      params.start = format(parseISO(dateFilter.startDate), 'yyyy-MM-dd');
      params.end = format(parseISO(dateFilter.endDate), 'yyyy-MM-dd');
    }

    if (reportType) {
      params.type = reportType;
    }

    return await apiRequest(
      url,
      'GET',
      null,
      params,
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching all reports:', error);
    throw error;
  }
};

export const generateAReport = async (restaurantId, reportType, dateFilter, token) => {
  try {
    const url = API_ENDPOINTS.RESTAURANT.REPORTS[reportType === 'staff_report' ? 'CREATE_STAFF' : 'CREATE_MENU'](restaurantId);
    const payload = {
      start: format(parseISO(dateFilter.startDate), "yyyy-MM-dd'T'HH:mm:ssxxx"),
      end: format(parseISO(dateFilter.endDate), "yyyy-MM-dd'T'HH:mm:ssxxx"),
    };

    return await apiRequest(
      url,
      'POST',
      payload,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error(`Error generating ${reportType}:`, error);
    throw error;
  }
};

export const deleteReport = async (restaurantId, reportId, token) => {
  try {
    const url = API_ENDPOINTS.RESTAURANT.REPORTS.DELETE(restaurantId, reportId);
    return await apiRequest(
      url,
      'DELETE',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error deleting report:', error);
    throw error;
  }
};
