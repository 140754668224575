import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Building2, Store, Star, Calendar, Settings, AlertCircle, 
  Search, PlusCircle, Trash2, Link
} from 'lucide-react';
import { MetricCard } from '../dashboard/MetricCard';
import SuperAdminSidebar from '../dashboard/SuperAdminSidebar';
import {
  fetchRestaurants,
  removeRestaurant,
  clearError,
  selectRestaurants,
  selectRestaurantLoading,
  selectRestaurantError,
  updateRestaurant,
} from '../../../../store/slices/restaurantSlice';
import {
  fetchSubscriptionTiers,
  selectSubscriptionTiers,
} from '../../../../store/slices/subscriptionSlice';
import { selectAccessToken } from '../../../../store/shared/auth/authSelectors';
import RestaurantCreationModal from './RestaurantCreationModal';
import { Toaster, toast } from 'sonner';
import RestaurantCard from './RestaurantCard';
import { fetchLocations } from '../../../../store/shared/location/locationThunks';



const Restaurants = () => {
  const dispatch = useDispatch();
  const restaurants = useSelector(selectRestaurants);
  const loading = useSelector(selectRestaurantLoading);
  const error = useSelector(selectRestaurantError);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const accessToken = useSelector(selectAccessToken);
  const subscriptionTiers = useSelector(selectSubscriptionTiers);

  useEffect(() => {
    dispatch(fetchRestaurants());
    dispatch(fetchSubscriptionTiers(accessToken));
    dispatch(fetchLocations());
  }, [dispatch, accessToken]);

  const handleAddRestaurant = () => {
    setIsModalOpen(true);
  };

  const handleDeleteRestaurant = async (id) => {
    try {
      await dispatch(removeRestaurant(id)).unwrap();
      toast.success('Restaurant deleted successfully!', {
        duration: 3000,
        className: 'bg-green-500',
      });
    } catch (error) {
      toast.error(error.message || 'Failed to delete restaurant', {
        duration: 3000,
      });
    }
  };

  const handleEditRestaurant = async (id, updatedData) => {
    try {
      await dispatch(updateRestaurant({ id, ...updatedData })).unwrap();
      await dispatch(fetchRestaurants());
      toast.success('Restaurant updated successfully!', {
        duration: 3000,
        className: 'bg-green-500',
      });
    } catch (error) {
      toast.error(error.message || 'Failed to update restaurant', {
        duration: 3000,
      });
    }
  };

  const filteredRestaurants = restaurants.filter(restaurant =>
    restaurant?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalRestaurants = restaurants.length;
  const activeAIRestaurants = restaurants.filter(r => r.main_open_ai_model).length;
  const subscribedRestaurants = restaurants.filter(r => r.subscription_tier_id).length;
  const integratedRestaurants = restaurants.filter(r => {
    const integrations = Array.isArray(r.integrations)
      ? r.integrations[0]?.includes(',')
        ? r.integrations[0].split(',').filter(i => i.trim())
        : r.integrations
      : r.integrations?.split(',').filter(i => i.trim()) || [];
    return integrations.length > 0;
  }).length;

  const handleModalClose = async () => {
    setIsModalOpen(false);
    await dispatch(fetchRestaurants());
  };

  if (loading) {
    return (
      <div className="flex">
        <SuperAdminSidebar />
        <div className="flex-1 p-8">
          <div className="animate-pulse">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-gray-50">
      <SuperAdminSidebar />
      <div className="flex-1 ml-64 p-8">
        <Toaster position="top-right" richColors />
        <h1 className="text-2xl font-bold mb-6">Restaurant Management</h1>
        
        {/* Search and Add Section */}
        <div className="mb-8 space-y-4">
          <div className="flex gap-4">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Search restaurants..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-3 pr-10 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-300 focus:border-blue-500 transition"
              />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
            <button 
              onClick={handleAddRestaurant}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300 ease-in-out flex items-center"
              disabled={loading}
            >
              <PlusCircle size={20} className="mr-2" />
              Add
            </button>
          </div>
          {error && (
            <div className="text-red-500 flex items-center">
              <AlertCircle className="w-4 h-4 mr-2" />
              {error}
            </div>
          )}
        </div>

        {/* Metrics Overview */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <MetricCard 
            title="Total Restaurants"
            value={totalRestaurants}
            Icon={Store}
          />
          <MetricCard 
            title="AI Enabled"
            value={activeAIRestaurants}
            Icon={Settings}
          />
          <MetricCard 
            title="Subscribed"
            value={subscribedRestaurants}
            Icon={Star}
          />
          <MetricCard 
            title="With Integrations"
            value={integratedRestaurants}
            Icon={Link}
          />
        </div>

        {/* Restaurants Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredRestaurants.map((restaurant) => (
            <RestaurantCard 
              key={restaurant.id} 
              restaurant={restaurant}
              onDelete={handleDeleteRestaurant}
              onEdit={handleEditRestaurant}
              subscriptionTiers={subscriptionTiers}
            />
          ))}
        </div>

        <RestaurantCreationModal 
          open={isModalOpen} 
          onClose={handleModalClose} 
        />
      </div>
    </div>
  );
};

export default Restaurants;
