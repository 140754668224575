// src/features/superadmin/components/SuperAdminSidebar.js
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Store, 
  MapPin, 
  Users, 
  BarChart2, 
  CreditCard,
  ChevronDown,
  LogOut,
  Sun,
  Moon,
  User,
  X
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../../../contexts/ThemeContext';
import { useAuth } from '../../../auth/hooks/useAuth';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import {
  selectUserRole,
  selectUserFirstName,
  selectUserLastName,
  selectUserEmail,
} from '../../../../store/shared/auth/authSelectors';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '../../../../components/shadcn/dropdown/DropdownMenu';
import Sidebar from "../../../../components/shadcn/sidebar/Sidebar";
import SidebarItem from "../../../../components/shadcn/sidebar/SidebarItem";
import SidebarGroup from "../../../../components/shadcn/sidebar/SidebarGroup";
import logoImage from '../../../../assets/images/FeedBacked4_NBG.png';

const DROPDOWN_WIDTH = "w-56";
const MOBILE_DROPDOWN_WIDTH = "w-[calc(100vw-2rem)]";

const SuperAdminSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { logout } = useAuth();
  const isDark = theme === 'dark';
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);

  // Redux selectors for user info
  const userRole = useSelector(selectUserRole);
  const firstName = useSelector(selectUserFirstName);
  const lastName = useSelector(selectUserLastName);
  const email = useSelector(selectUserEmail);

  const avatarUrl = `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=${
    isDark ? "2563eb" : "3b82f6"
  }&color=fff&bold=true&format=svg`;

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };

  const navItems = [
    { 
      path: '/superadmin', 
      label: 'Dashboard', 
      icon: LayoutDashboard,
      description: 'System overview and metrics'
    },
    { 
      path: '/superadmin/restaurants', 
      label: 'Restaurants', 
      icon: Store,
      description: 'Manage all restaurants'
    },
    { 
      path: '/superadmin/locations', 
      label: 'Locations', 
      icon: MapPin,
      description: 'View all locations'
    },
    { 
      path: '/superadmin/users', 
      label: 'Users', 
      icon: Users,
      description: 'Manage system users'
    },
    { 
      path: '/superadmin/analytics', 
      label: 'Analytics', 
      icon: BarChart2,
      description: 'System-wide analytics'
    },
    { 
      path: '/superadmin/subscriptions', 
      label: 'Subscriptions', 
      icon: CreditCard,
      description: 'Manage subscription tiers'
    }
  ];

  return (
    <Sidebar className="w-64 bg-white shadow-lg fixed top-0 left-0 h-full">
      <div className="flex flex-col h-full">
        <div className="p-6 border-b flex items-center gap-3">
          <img 
            src={logoImage} 
            alt="FeedBacked Logo" 
            className="h-8 w-8 object-contain"
          />
          <div>
            <h2 className="text-xl font-bold text-blue-600">FeedBacked</h2>
          </div>
        </div>
        
        <div className="flex-1 overflow-y-auto">
          <SidebarGroup>
            {navItems.map((link) => (
              <SidebarItem
                key={link.path}
                to={link.path}
                icon={link.icon}
                label={link.label}
                description={link.description}
                className={`flex items-center p-4 rounded-md hover:bg-gray-100 ${
                  location.pathname === link.path
                    ? "text-blue-600"
                    : "text-gray-600"
                }`}
              />
            ))}
          </SidebarGroup>
        </div>
        
        <SidebarGroup className="mt-auto p-2 border-t border-gray-200 dark:border-gray-800">
          <DropdownMenu onOpenChange={setIsUserMenuOpen}>
            <DropdownMenuTrigger asChild>
              <button className="flex flex-col w-full px-2 py-1.5 rounded-lg transition-colors hover:bg-gray-100 dark:hover:bg-gray-800">
                <div className="flex items-center justify-between w-full space-x-2">
                  <div className="flex items-center gap-2 min-w-0">
                    <div className={`h-7 w-7 rounded-lg overflow-hidden flex items-center justify-center bg-${isDark ? "blue-600" : "blue-500"} ring-1 ring-gray-200 dark:ring-gray-800`}>
                      <img src={avatarUrl} alt={`${firstName} ${lastName}`} className="h-full w-full" />
                    </div>
                    <div className="flex flex-col text-left leading-tight min-w-0">
                      <span className="text-[11px] text-gray-500 dark:text-gray-400">{userRole}</span>
                      <span className="font-medium text-sm truncate">{firstName} {lastName}</span>
                      <span className="text-xs text-gray-500 dark:text-gray-400 truncate">{email}</span>
                    </div>
                  </div>
                  <ChevronDown size={16} className={`shrink-0 opacity-50 transform transition-transform duration-200 ${isUserMenuOpen ? "-rotate-90" : ""}`} />
                </div>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side={isMobile ? "top" : "right"}
              align={isMobile ? "center" : "start"}
              sideOffset={8}
              className={`${isMobile ? MOBILE_DROPDOWN_WIDTH : DROPDOWN_WIDTH} max-h-[300px] overflow-y-auto mb-4`}
            >
              <div className="flex items-center gap-2 p-2">
                <div className={`h-8 w-8 rounded-lg overflow-hidden flex items-center justify-center bg-${isDark ? "blue-600" : "blue-500"} ring-1 ring-gray-200 dark:ring-gray-800`}>
                  <img src={avatarUrl} alt={`${firstName} ${lastName}`} className="h-full w-full" />
                </div>
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">{firstName} {lastName}</p>
                  <p className="text-xs leading-none text-gray-500 dark:text-gray-400">{email}</p>
                </div>
              </div>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={toggleTheme}>
                <div className="flex items-center">
                  {isDark ? <Sun className="mr-2 h-4 w-4" /> : <Moon className="mr-2 h-4 w-4" />}
                  <span>{isDark ? "Light Mode" : "Dark Mode"}</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleLogout} className="text-red-600 dark:text-red-400">
                <div className="flex items-center">
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarGroup>
      </div>
    </Sidebar>
  );
};

export default SuperAdminSidebar;