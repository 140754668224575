// src/components/shadcn/Sidebar.js
import React from 'react';
import PropTypes from 'prop-types';

const Sidebar = ({ children, className }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      {children}
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;