import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectIsAuthenticated,
  selectAuthInitialized
} from '../../../store/shared/auth/authSelectors';
import { 
  loginUser, 
  logout 
} from '../../../store/shared/auth/authThunks';

export const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const authInitialized = useSelector(selectAuthInitialized);

  const login = useCallback(async (email, password) => {
    try {
      const resultAction = await dispatch(loginUser({ email, password }));
      return loginUser.fulfilled.match(resultAction);
    } catch (error) {
      return false;
    }
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return {
    isAuthenticated,
    authInitialized,
    login,
    logout: handleLogout,
  };
};