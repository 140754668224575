import { createSelector } from '@reduxjs/toolkit';
import { selectReviews } from '../reviews/reviewSlice';
import { selectSelectedRestaurant } from '../auth/authSelectors';
import { selectLocationById, selectLocations } from '../location/locationSelectors';

// Basic selectors
export const selectAlerts = (state) => state.alerts.items;
export const selectAlertsLoading = (state) => state.alerts.loading;
export const selectAlertsError = (state) => state.alerts.error;

// Enriched Alerts Selector
export const selectEnrichedAlerts = createSelector(
  [
    selectAlerts,
    selectReviews,
    selectSelectedRestaurant,
    selectLocations, // Add selectLocations as an input selector
  ],
  (alerts, reviews, selectedRestaurant, locations) => {

    if (!Array.isArray(alerts)) return [];

    return alerts.map((alert) => {
      if (alert.type === 'bad_review') {
        const review = reviews.find((r) => r.id === alert.reviewId);
        const location = review ? locations.find((loc) => loc.id === review.location_id) : null;

        return {
          ...alert,
          review: review || null,
          locationName: location ? location.name : 'Unknown Location',
        };
      }
      return alert;
    });
  }
);

// Add new selector for alert count
export const selectActiveAlertsCount = createSelector(
  [selectEnrichedAlerts],
  (alerts) => alerts.filter(alert => !alert.seen).length
);