import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllGoogleReviews, fetchMenuInsightsFiltered, fetchStaffInsights, fetchMenuInsights, fetchMenuInsightsForCharts } from '../../features/graphs/services/charts.api';
import { selectAccessToken } from '../shared/auth/authSelectors';
import { getStaffPerformance } from '../../features/graphs/services/charts.api';
import { selectLocationById } from '../shared/location/locationSelectors';
import { createSelector } from 'reselect';

/**
 * Initial state for charts.
 */
const initialState = {
  metrics: null,
  menuInsights: [],
  loading: false,
  menuLoading: false,
  error: null,
  menuError: null,
  cache: {},
  CACHE_DURATION: 5 * 60 * 1000, // 5 minutes
  locationColors: JSON.parse(localStorage.getItem('locationColors')) || {},
  staffPerformance: null,
  staffPerformanceLoading: false,
  staffPerformanceError: null,
};

/**
 * Async thunk to fetch and compute reviews metrics.
 */
export const fetchReviewsMetricsThunk = createAsyncThunk(
  'charts/fetchReviewsMetrics',
  async ({ locationId, startDate, endDate }, { getState, rejectWithValue }) => {
    const state = getState();
    const token = selectAccessToken(state);

    if (!token) {
      return rejectWithValue('No access token available');
    }

    try {
      const reviews = await fetchAllGoogleReviews(locationId, startDate, endDate, token);

      const totalReviewsByLocation = {};
      const ratingsByLocation = {};
      let positiveReviews = 0;
      let negativeReviews = 0;
      let totalRating = 0;
      
      reviews.forEach(review => {
        const { location_id, rating } = review;
        
        // Count total reviews by location_id
        totalReviewsByLocation[location_id] = (totalReviewsByLocation[location_id] || 0) + 1;
        
        // Track positive and negative
        if (rating >= 4) {
          positiveReviews += 1;
        } else if (rating <= 3) {
          negativeReviews += 1;
        }
        
        // Total rating for global average
        totalRating += rating;
        
        // Ratings by location
        if (!ratingsByLocation[location_id]) {
          ratingsByLocation[location_id] = { totalRating: 0, count: 0 };
        }
        ratingsByLocation[location_id].totalRating += rating;
        ratingsByLocation[location_id].count += 1;
      });
      
      const averageRating = reviews.length > 0 ? (totalRating / reviews.length) : 0;
      
      const data = {
        totalReviewsByLocation,
        positiveReviews,
        negativeReviews,
        averageRating,
        ratingsByLocation,
      };
      
      return { 
        data,
        locationId,
        timestamp: Date.now()
      };
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch reviews metrics');
    }
  }
);

/**
 * Async thunk to fetch menu insights for charts
 */
export const fetchMenuInsightsThunk = createAsyncThunk(
  'charts/fetchMenuInsights',
  async ({ locationId, startDate, endDate }, { getState, rejectWithValue }) => {
    const state = getState();
    const token = selectAccessToken(state);

    if (!token) {
      return rejectWithValue('No access token available');
    }

    try {
      const insights = await fetchMenuInsightsForCharts(locationId, startDate, endDate, token);
      return insights;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch menu insights');
    }
  }
);

/**
 * Async thunk to fetch staff performance
 */
export const fetchStaffPerformanceThunk = createAsyncThunk(
  'charts/fetchStaffPerformance',
  async ({ locationId, startDate, endDate }, { getState, rejectWithValue }) => {
    const state = getState();
    const token = selectAccessToken(state);

    if (!token) {
      return rejectWithValue('No access token available');
    }

    try {
      const response = await getStaffPerformance(locationId, startDate, endDate, token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch staff performance');
    }
  }
);

/**
 * Charts slice.
 */
const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    clearChartsData: (state) => {
      state.metrics = null;
      state.error = null;
    },
    setLocationColor: (state, action) => {
      const { locationId, color } = action.payload;
      state.locationColors[locationId] = color;
      localStorage.setItem('locationColors', JSON.stringify(state.locationColors));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewsMetricsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReviewsMetricsThunk.fulfilled, (state, action) => {
        const { data, locationId, timestamp } = action.payload;
        state.metrics = data;
        state.cache[locationId] = {
          data,
          timestamp,
        };
        state.loading = false;
      })
      .addCase(fetchReviewsMetricsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchMenuInsightsThunk.pending, (state) => {
        state.menuLoading = true;
        state.menuError = null;
      })
      .addCase(fetchMenuInsightsThunk.fulfilled, (state, action) => {
        state.menuInsights = action.payload;
        state.menuLoading = false;
      })
      .addCase(fetchMenuInsightsThunk.rejected, (state, action) => {
        state.menuLoading = false;
        state.menuError = action.payload;
      })
      .addCase(fetchStaffPerformanceThunk.pending, (state) => {
        state.staffPerformanceLoading = true;
        state.staffPerformanceError = null;
      })
      .addCase(fetchStaffPerformanceThunk.fulfilled, (state, action) => {
        state.staffPerformance = action.payload;
        state.staffPerformanceLoading = false;
      })
      .addCase(fetchStaffPerformanceThunk.rejected, (state, action) => {
        state.staffPerformanceLoading = false;
        state.staffPerformanceError = action.payload;
      });
  },
});

/**
 * Selectors.
 */
export const selectChartsMetrics = (state) => state.charts.metrics;
export const selectChartsLoading = (state) => state.charts.loading;
export const selectChartsError = (state) => state.charts.error;
export const selectLocationColors = (state) => state.charts.locationColors;
export const selectMenuInsights = (state) => state.charts.menuInsights;
export const selectMenuInsightsLoading = (state) => state.charts.menuLoading;
export const selectMenuInsightsError = (state) => state.charts.menuError;
export const selectStaffPerformance = (state) => state.charts.staffPerformance;
export const selectStaffPerformanceLoading = (state) => state.charts.staffPerformanceLoading;
export const selectStaffPerformanceError = (state) => state.charts.staffPerformanceError;

export const selectEnrichedStaffPerformance = createSelector(
  [
    selectStaffPerformance,
    (state) => state,
  ],
  (staffPerformance, state) => {
    if (!staffPerformance) return [];
    
    return staffPerformance.map(staff => {
      const location = selectLocationById(state, staff.location_id);
      return {
        ...staff,
        locationName: location ? location.name : 'Unknown Location'
      };
    });
  }
);

/**
 * Export actions and reducer.
 */
export const { clearChartsData, setLocationColor } = chartsSlice.actions;
export default chartsSlice.reducer;