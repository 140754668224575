import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation as useRouterLocation,
} from "react-router-dom";
import { ThemeContext } from "../../../contexts/ThemeContext";
import {
  locationSelectors,
  setSelectedLocation,
} from "../../../store/shared/location/locationSlice";
import { locationChanged } from "../../../store/slices/dashboardSlice";
import {
  LogOut,
  MapPin,
  ChevronDown,
  Sun,
  Moon,
  ChevronUp,
  User,
  MapPinned,
} from "lucide-react";
import { useAuth } from "../../auth/hooks/useAuth";
import PropTypes from "prop-types";
import useMediaQuery from "../../../hooks/useMediaQuery";
import AccountSettingsModal from "../../account/components/AccountSettingsModal";

// Import shadcn Sidebar components
import Sidebar from "../../../components/shadcn/sidebar/Sidebar";
import SidebarItem from "../../../components/shadcn/sidebar/SidebarItem";
import SidebarGroup from "../../../components/shadcn/sidebar/SidebarGroup";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../../../components/shadcn/dropdown/DropdownMenu";
import {
  selectUserRole,
  selectUserFirstName,
  selectUserLastName,
  selectUserEmail,
} from "../../../store/shared/auth/authSelectors";
import { selectActiveAlertsCount } from "../../../store/shared/alerts/alertSelectors";
import logoImage from '../../../assets/images/FeedBacked4_NBG.png';

const DROPDOWN_WIDTH = "w-56";
const MOBILE_DROPDOWN_WIDTH = "w-[calc(100vw-2rem)]";

const Logo = ({ className = "" }) => (
  <img 
    src={logoImage} 
    alt="FeedBacked Logo" 
    className={`h-7 w-7 ${className} object-contain`}
  />
);

const DashboardIntegratedSidebar = ({ navigationItems = [] }) => {
  const dispatch = useDispatch();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { logout, selectedRestaurant } = useAuth();
  const navigate = useNavigate();
  const routerLocation = useRouterLocation();
  const isDark = theme === "dark";
  const [isLocationExpanded, setIsLocationExpanded] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);

  // Redux selectors
  const locations = useSelector(locationSelectors.selectLocations);
  const selectedLocation = useSelector(
    locationSelectors.selectSelectedLocation
  );
  const userRole = useSelector(selectUserRole);
  const firstName = useSelector(selectUserFirstName);
  const lastName = useSelector(selectUserLastName);
  const email = useSelector(selectUserEmail);
  const activeAlertsCount = useSelector(selectActiveAlertsCount);

  // UI Avatars URL with customization
  const avatarUrl = `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=${
    isDark ? "2563eb" : "3b82f6"
  }&color=fff&bold=true&format=svg`;

  const handleLocationSelect = async (loc) => {
    if (selectedLocation?.id === loc.id) return;

    // Dispatch both actions
    await dispatch(setSelectedLocation(loc));
    await dispatch(locationChanged(loc?.id || "all"));
  };

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };

  return (
    <>
      <Sidebar
        className={`w-64 h-full overflow-y-auto ${
          isDark ? "bg-gray-900" : "bg-white"
        } border-r border-gray-200 dark:border-gray-800`}
      >
        {/* Brand Header */}
        <SidebarGroup>
          <div className="flex items-center space-x-3 p-4">
            <Logo />
            <div className="flex flex-col">
              <span className="text-lg font-bold text-blue-600">FeedBacked</span>
              {selectedRestaurant?.name && (
                <span
                  className={`text-sm ${
                    isDark ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  {selectedRestaurant.name}
                </span>
              )}
            </div>
          </div>
        </SidebarGroup>

        {/* Divider */}
        <hr
          className={`${
            theme === "dark" ? "border-gray-800" : "border-gray-200"
          } my-2`}
        />

        {/* Location Section */}
        <SidebarGroup>
          <DropdownMenu onOpenChange={setIsLocationOpen}>
            <DropdownMenuTrigger asChild>
              <button className="flex items-center justify-between w-full px-4 py-2.5 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors">
                <div className="flex items-center gap-2">
                  <MapPinned
                    size={16}
                    className="text-gray-500 dark:text-gray-400"
                  />
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    Current Location
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="font-medium">
                    {selectedLocation?.name || "All"}
                  </span>
                  <ChevronDown
                    size={16}
                    className={`shrink-0 opacity-50 transform transition-transform duration-200 ${
                      isLocationOpen ? "-rotate-90" : ""
                    }`}
                  />
                </div>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side={isMobile ? "bottom" : "right"}
              align={isMobile ? "center" : "start"}
              sideOffset={8}
              className={`${
                isMobile ? MOBILE_DROPDOWN_WIDTH : DROPDOWN_WIDTH
              } max-h-[300px] overflow-y-auto`}
            >
              {locations.map((loc) => (
                <DropdownMenuItem
                  key={loc.id}
                  onClick={() => handleLocationSelect(loc)}
                  className={`${
                    selectedLocation?.id === loc.id
                      ? "bg-blue-50 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400"
                      : ""
                  } ${isMobile ? "py-3" : ""} flex items-center gap-2`}
                >
                  <MapPin
                    size={14}
                    className={
                      selectedLocation?.id === loc.id
                        ? "text-blue-500"
                        : "text-gray-400"
                    }
                  />
                  {loc.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarGroup>

        {/* Primary Navigation Items (without section title) */}
        <SidebarGroup>
          {navigationItems
            .filter((item) => ["Home", "Agent", "Alerts"].includes(item.label))
            .sort((a, b) => {
              const order = ["Home", "Agent", "Alerts"];
              return order.indexOf(a.label) - order.indexOf(b.label);
            })
            .map((link) => (
              <SidebarItem
                key={link.path}
                to={link.path}
                icon={link.icon}
                label={link.label}
                description={link.description}
                className={`flex items-center p-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 ${
                  routerLocation.pathname === link.path
                    ? "text-blue-600 dark:text-blue-400"
                    : "text-gray-600 dark:text-gray-400"
                }`}
              >
                {link.label === "Alerts" && (
                  <div className="ml-auto">
                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400 px-2 py-0.5 text-xs font-medium rounded-full">
                      {activeAlertsCount}
                    </span>
                  </div>
                )}
              </SidebarItem>
            ))}
        </SidebarGroup>

        {/* Section with titles */}
        <SidebarGroup className="space-y-6">
          {/* Feedback Section */}
          <div className="pt-6">
            <h3 className="mb-3 px-4 text-xs font-semibold text-gray-400 dark:text-gray-400">
              Feedback
            </h3>
            {navigationItems
              .filter((item) => ["Reviews", "Staff", "Profiles", "Menu"].includes(item.label))
              .map((link) => (
                <SidebarItem
                  key={link.path}
                  to={link.path}
                  icon={link.icon}
                  label={link.label}
                  description={link.description}
                  className={`flex items-center p-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 ${
                    routerLocation.pathname === link.path
                      ? "text-blue-600 dark:text-blue-400"
                      : "text-gray-600 dark:text-gray-400"
                  }`}
                />
              ))}
          </div>

          {/* Analytics Section */}
          <div>
            <h3 className="mb-3 px-4 text-xs font-semibold text-gray-400 dark:text-gray-400">
              Analytics
            </h3>
            {navigationItems
              .filter((item) =>
                ["Trends", "Analytics", "Keywords", "Reports"].includes(
                  item.label
                )
              )
              .map((link) => (
                <SidebarItem
                  key={link.path}
                  to={link.path}
                  icon={link.icon}
                  label={link.label}
                  description={link.description}
                  className={`flex items-center p-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 ${
                    routerLocation.pathname === link.path
                      ? "text-blue-600 dark:text-blue-400"
                      : "text-gray-600 dark:text-gray-400"
                  }`}
                />
              ))}
          </div>

          {/* Integrations Section */}
          <div>
            <h3 className="mb-3 px-4 text-xs font-semibold text-gray-400 dark:text-gray-400">
              Integrations
            </h3>
            {navigationItems
              .filter((item) => ["Reservations"].includes(item.label))
              .map((link) => (
                <SidebarItem
                  key={link.path}
                  to={link.path}
                  icon={link.icon}
                  label={link.label}
                  description={link.description}
                  className={`flex items-center p-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 ${
                    routerLocation.pathname === link.path
                      ? "text-blue-600 dark:text-blue-400"
                      : "text-gray-600 dark:text-gray-400"
                  }`}
                />
              ))}
          </div>

          {/* Settings Section */}
          <div>
            <h3 className="mb-3 px-4 text-xs font-semibold text-gray-400 dark:text-gray-400">
              Settings
            </h3>
            {navigationItems
              .filter((item) => ["Settings"].includes(item.label))
              .map((link) => (
                <SidebarItem
                  key={link.path}
                  to={link.path}
                  icon={link.icon}
                  label={link.label}
                  description={link.description}
                  className={`flex items-center p-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 ${
                    routerLocation.pathname === link.path
                      ? "text-blue-600 dark:text-blue-400"
                      : "text-gray-600 dark:text-gray-400"
                  }`}
                />
              ))}
          </div>
        </SidebarGroup>

        {/* User Controls in Footer */}
        <SidebarGroup className="mt-auto p-2 border-t border-gray-200 dark:border-gray-800">
          <DropdownMenu onOpenChange={setIsUserMenuOpen}>
            <DropdownMenuTrigger asChild>
              <button className="flex flex-col w-full px-2 py-1.5 rounded-lg transition-colors hover:bg-gray-100 dark:hover:bg-gray-800">
                <div className="flex items-center justify-between w-full space-x-2">
                  <div className="flex items-center gap-2 min-w-0">
                    <div
                      className={`h-7 w-7 rounded-lg overflow-hidden flex items-center justify-center bg-${
                        isDark ? "blue-600" : "blue-500"
                      } ring-1 ring-gray-200 dark:ring-gray-800`}
                    >
                      <img
                        src={avatarUrl}
                        alt={`${firstName} ${lastName}`}
                        className="h-full w-full"
                      />
                    </div>
                    <div className="flex flex-col text-left leading-tight min-w-0">
                      <span className="text-[11px] text-gray-500 dark:text-gray-400">
                        {userRole}
                      </span>
                      <span className="font-medium text-sm truncate">
                        {firstName} {lastName}
                      </span>
                      <span className="text-xs text-gray-500 dark:text-gray-400 truncate">
                        {email}
                      </span>
                    </div>
                  </div>
                  <ChevronDown
                    size={16}
                    className={`shrink-0 opacity-50 transform transition-transform duration-200 ${
                      isUserMenuOpen ? "-rotate-90" : ""
                    }`}
                  />
                </div>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side={isMobile ? "top" : "right"}
              align={isMobile ? "center" : "start"}
              sideOffset={8}
              className={`${
                isMobile ? MOBILE_DROPDOWN_WIDTH : DROPDOWN_WIDTH
              } max-h-[300px] overflow-y-auto mb-4`}
            >
              {/* Account Info Header */}
              <div className="flex items-center gap-2 p-2">
                <div
                  className={`h-8 w-8 rounded-lg overflow-hidden flex items-center justify-center bg-${
                    isDark ? "blue-600" : "blue-500"
                  } ring-1 ring-gray-200 dark:ring-gray-800`}
                >
                  <img
                    src={avatarUrl}
                    alt={`${firstName} ${lastName}`}
                    className="h-full w-full"
                  />
                </div>
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {firstName} {lastName}
                  </p>
                  <p className="text-xs leading-none text-gray-500 dark:text-gray-400">
                    {email}
                  </p>
                </div>
              </div>

              {/* Separator */}
              <DropdownMenuSeparator />

              {/* Menu Items */}
              <DropdownMenuItem
                onClick={() => {
                  setIsAccountModalOpen(true);
                  setIsUserMenuOpen(false);
                }}
              >
                <div className="flex items-center">
                  <User className="mr-2 h-4 w-4" />
                  <span>Account</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={toggleTheme}>
                <div className="flex items-center">
                  {isDark ? (
                    <Sun className="mr-2 h-4 w-4" />
                  ) : (
                    <Moon className="mr-2 h-4 w-4" />
                  )}
                  <span>{isDark ? "Light Mode" : "Dark Mode"}</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={handleLogout}
                className="text-red-600 dark:text-red-400"
              >
                <div className="flex items-center">
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarGroup>
      </Sidebar>

      <AccountSettingsModal 
        isOpen={isAccountModalOpen}
        onClose={() => setIsAccountModalOpen(false)}
      />
    </>
  );
};

DashboardIntegratedSidebar.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    })
  ),
};

DashboardIntegratedSidebar.defaultProps = {
  navigationItems: [],
};

export default DashboardIntegratedSidebar;
