import React, { useState } from "react";
import {
  Bot,
  Star,
  Users,
  Award,
  ArrowRight,
  MessageSquare,
  ChevronDown,
  Play,
  Check,
} from "lucide-react";
import MainPageNavbar from "../../../components/layout/MainPageNavbar";
import FeatureCard from "../../../components/common/FeatureCard";
import NetworkBackground from "../../../components/common/NetworkBackground";
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [activeTestimonial, setActiveTestimonial] = useState(0);

  const testimonials = [
    {
      text: "FeedBacked transformed how we handle customer feedback. Our response time improved by 300%!",
      author: "Sarah Chen",
      role: "Owner, The Golden Plate",
      image: "/api/placeholder/64/64",
    },
    {
      text: "The AI-powered responses are incredibly personalized. Our customers feel heard and valued.",
      author: "Michael Rodriguez",
      role: "Manager, Bistro 54",
      image: "/api/placeholder/64/64",
    },
    {
      text: "The analytics helped us identify and fix key issues in our service. 5-star ratings up by 45%!",
      author: "Emma Thompson",
      role: "Director, Fine Dining Co",
      image: "/api/placeholder/64/64",
    },
  ];

  const StatCard = ({ value, label, sublabel }) => (
    <div className="transform hover:scale-105 transition-transform">
      <div className="text-5xl font-bold mb-2">{value}</div>
      <div className="text-xl mb-1">{label}</div>
      <div className="text-blue-200 text-sm">{sublabel}</div>
    </div>
  );

  const handleStartFreeTrial = () => {
    navigate('/onboarding');
  };

  return (
    <div className="min-h-screen overflow-x-hidden">
      <div className="relative min-h-screen">
        {/* Background Container */}
        <div className="absolute inset-0">
          <NetworkBackground
            color="rgba(37, 99, 235, 0.3)"
            pointColor="rgba(37, 99, 235, 0.7)"
            pointCount={50}
            maxDistance={500}
            speedFactor={0.2}
            backgroundColor="rgb(243, 244, 255)"
            pointSize={2}
            lineWidth={1}
            className="!fixed"
          />
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-white/50 to-white/90" />
        </div>

        {/* Content */}
        <div className="relative pt-20 px-6">
          <div className="max-w-7xl mx-auto py-24">
            <div className="flex items-center justify-center mb-6 space-x-2">
              <span className="bg-blue-100/80 text-blue-600 px-4 py-1 rounded-full text-sm font-medium backdrop-blur-sm">
                New Feature
              </span>
              <span className="text-gray-600">
                AI-Powered Sentiment Analysis
              </span>
            </div>

            <h1 className="text-6xl font-bold text-gray-900 mb-8 text-center">
              Automate Your Restaurant's
              <br />
              <span className="text-blue-600 relative inline-block">
                Review Management
                <svg
                  className="absolute -bottom-2 left-0 w-full"
                  height="8"
                  viewBox="0 0 200 8"
                >
                  <path
                    d="M0 4C50 4 50 1 100 1S150 7 200 7"
                    fill="none"
                    stroke="#2563EB"
                    strokeWidth="2"
                  />
                </svg>
              </span>
            </h1>

            <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-12">
              AI-powered platform that helps restaurants manage reviews, analyze
              customer feedback, and improve their online presence
            </p>

            <div className="flex justify-center space-x-4 mb-12">
              <button 
                className="bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition-all transform hover:scale-105 hover:shadow-lg flex items-center backdrop-blur-sm"
                onClick={handleStartFreeTrial}
              >
                Start Free Trial
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button
                className="bg-white/80 text-gray-900 px-8 py-4 rounded-lg border-2 border-gray-200 hover:border-blue-600 transition-all flex items-center backdrop-blur-sm"
                onClick={() => setIsVideoModalOpen(true)}
              >
                <Play className="mr-2 h-5 w-5" />
                Watch Demo
              </button>
            </div>

            <div className="flex items-center justify-center space-x-8">
              {[
                { icon: Check, text: "30-day free trial" },
                { icon: Check, text: "No credit card required" },
                { icon: Check, text: "Cancel anytime" },
              ].map(({ icon: Icon, text }) => (
                <div
                  key={text}
                  className="flex items-center text-gray-600 backdrop-blur-sm"
                >
                  <Icon className="h-5 w-5 text-green-500 mr-2" />
                  {text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="relative z-10 bg-gray-50">
        <div className="max-w-7xl mx-auto px-6 py-20">
          <div className="grid md:grid-cols-3 gap-12">
            {[
              {
                icon: MessageSquare,
                title: "Smart Responses",
                description:
                  "AI generates personalized responses to customer reviews automatically",
                highlight: "Save 10+ hours weekly",
              },
              {
                icon: Users,
                title: "Staff Insights",
                description:
                  "Track staff performance through customer feedback and reviews",
                highlight: "Improve team efficiency",
              },
              {
                icon: Star,
                title: "Review Analytics",
                description:
                  "Get actionable insights from your customer reviews",
                highlight: "Boost ratings by 30%",
              },
            ].map((feature) => (
              <FeatureCard key={feature.title} {...feature} />
            ))}
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="relative z-10 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
        <div className="max-w-7xl mx-auto px-6 py-20">
          <div className="grid md:grid-cols-3 gap-12 text-center">
            {[
              {
                value: "98%",
                label: "Customer Satisfaction",
                sublabel: "Based on 1000+ reviews",
              },
              {
                value: "50K+",
                label: "Reviews Managed",
                sublabel: "Across 100+ restaurants",
              },
              {
                value: "2x",
                label: "Response Rate",
                sublabel: "Compared to manual management",
              },
            ].map((stat) => (
              <StatCard key={stat.label} {...stat} />
            ))}
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="relative z-10 bg-white">
        <div className="max-w-7xl mx-auto px-6 py-20">
          <h2 className="text-3xl font-bold text-center mb-12">
            Trusted by Leading Restaurants
          </h2>
          <div className="bg-white rounded-xl shadow-lg p-8 max-w-3xl mx-auto">
            <div className="relative">
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className={`transition-opacity duration-500 ${
                    index === activeTestimonial
                      ? "opacity-100"
                      : "opacity-0 absolute inset-0"
                  }`}
                >
                  <p className="text-xl text-gray-600 mb-6">
                    "{testimonial.text}"
                  </p>
                  <div className="flex items-center">
                    <img
                      src={testimonial.image}
                      alt={testimonial.author}
                      className="w-16 h-16 rounded-full mr-4"
                    />
                    <div>
                      <div className="font-semibold">{testimonial.author}</div>
                      <div className="text-gray-500">{testimonial.role}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-8 space-x-2">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full transition-colors ${
                    index === activeTestimonial ? "bg-blue-600" : "bg-gray-300"
                  }`}
                  onClick={() => setActiveTestimonial(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="relative z-10 bg-gray-50">
        <div className="max-w-7xl mx-auto px-6 py-24 text-center">
          <h2 className="text-4xl font-bold mb-8">
            Ready to Transform Your Restaurant's
            <br />
            <span className="text-blue-600">Online Presence?</span>
          </h2>
          <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
            Join hundreds of successful restaurants already using feedBacked to
            improve their customer experience
          </p>
          <button className="bg-blue-600 text-white px-12 py-6 rounded-lg hover:bg-blue-700 transition-all transform hover:scale-105 hover:shadow-xl text-lg font-semibold">
            Get Started Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
