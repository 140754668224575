import { createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { 
  login, 
  refreshSession, 
  logoutSession,
  getLocations,
  getRestaurant,
  fetchRoles
} from "../../../services/api/apiUtility";
import { fetchAlerts, resetAlerts } from '../alerts/alertsSlice';

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      // 1. Login
      const loginResponse = await login(email, password);
      if (!loginResponse.status === "success")
        throw new Error("Authentication failed");

      const { access_token, expires_in } = loginResponse.data;
      const decodedToken = jwtDecode(access_token);
      
      // Extract all necessary user details from token
      const {
        email: userEmail,
        given_name: userFirstName,
        family_name: userLastName,
        preferred_username: userUsername,
        sub: userId,
        location_id: userLocationId,
        resource_access: { restos: { roles: [userRole] } }
      } = decodedToken;

      // 2. Get locations and restaurant in sequence, not parallel
      let locations = [];
      let restaurant = null;

      if (userRole !== "SuperAdmin") {
        const locationsResponse = await getLocations(access_token);
        if (locationsResponse.status === "success") {
          locations = locationsResponse.data;
        }

        const restaurantResponse = await getRestaurant(access_token);
        if (restaurantResponse.status === "success") {
          [restaurant] = restaurantResponse.data;
        }
      }

      // After successful login and getting restaurant info
      if (restaurant) {
        // Fetch alerts right after login
        await dispatch(fetchAlerts({ 
          restaurantId: restaurant.id, 
          token: access_token 
        }));
      }

      return {
        accessToken: access_token,
        userRole,
        userEmail,
        userFirstName,
        userLastName,
        userUsername,
        userId,
        userLocationId,
        authorizedLocations: locations,
        selectedRestaurant: restaurant,
        accessTokenExpiresIn: Number(expires_in),
        userEnabled: true
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const refreshAuth = createAsyncThunk(
  "auth/refresh",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await refreshSession();
      const { access_token, expires_in } = response.data;

      if (!access_token) {
        throw new Error("Invalid response from server: Missing token");
      }

      const decodedToken = jwtDecode(access_token);
      const userRole = decodedToken.resource_access.restos.roles[0];

      // Extract user details from token
      const {
        email: userEmail,
        given_name: userFirstName,
        family_name: userLastName,
        preferred_username: userUsername,
        sub: userId,
        location_id: userLocationId
      } = decodedToken;

      // Get role ID from the roles endpoint (add this)
      const rolesResponse = await fetchRoles(access_token);
      const roleId = rolesResponse.data.find(role => role.name === userRole)?.id;

      // Fetch fresh locations and restaurant data
      let locations = [];
      let restaurant = null;

      if (userRole !== "SuperAdmin") {
        const locationsResponse = await getLocations(access_token);
        if (locationsResponse.status === "success") {
          locations = locationsResponse.data;
        }

        const restaurantResponse = await getRestaurant(access_token);
        if (restaurantResponse.status === "success") {
          [restaurant] = restaurantResponse.data;
        }
      }

      if (restaurant) {
        // Fetch alerts after refresh
        await dispatch(fetchAlerts({ 
          restaurantId: restaurant.id, 
          token: access_token 
        }));
      }

      return {
        accessToken: access_token,
        userRole,
        userRoleId: roleId,
        userEmail,
        userFirstName,
        userLastName,
        userUsername,
        userId,
        userLocationId,
        userEnabled: true,
        accessTokenExpiresIn: Number(expires_in),
        authorizedLocations: locations,
        selectedRestaurant: restaurant,
        lastRefreshTime: Date.now(),
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, getState }) => {
    try {
      // Clear refresh timer if exists
      const state = getState();
      if (state.auth.refreshTokenTimeoutId) {
        clearTimeout(state.auth.refreshTokenTimeoutId);
      }

      // Clear alerts state
      dispatch(resetAlerts());

      await logoutSession();
    } catch (error) {
      console.error("Logout error:", error);
    }
    return null;
  }
);
