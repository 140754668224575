import React, { useContext, useState, useCallback } from "react";
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { Menu } from "lucide-react";
import DashboardIntegratedSidebar from "./DashboardIntegratedSidebar";
import { locationSelectors } from '../../../store/shared/location/locationSlice';

// Custom hook for managing mobile sidebar
const useMobileSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = useCallback(() => setIsSidebarOpen(true), []);
  const closeSidebar = useCallback(() => setIsSidebarOpen(false), []);
  const toggleSidebar = useCallback(() => setIsSidebarOpen(prev => !prev), []);

  return {
    isSidebarOpen,
    openSidebar,
    closeSidebar,
    toggleSidebar,
  };
};

const Logo = React.memo(({ brandName }) => (
  <div className="flex items-center gap-3 px-6 py-5 border-b border-gray-100 dark:border-gray-800">
    <div className="flex items-center gap-2">
      <svg className="h-8 w-8" viewBox="0 0 128 128">
        <defs>
          <linearGradient id="logoGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#3B82F6" />
            <stop offset="100%" stopColor="#2563EB" />
          </linearGradient>
        </defs>
        <path
          fill="url(#logoGradient)"
          d="M108 28H20c-6.627 0-12 5.373-12 12v48c0 6.627 5.373 12 12 12h28l12 16 12-16h36c6.627 0 12-5.373 12-12V40c0-6.627-5.373-12-12-12z"
        />
        <path fill="#fff" d="M36 52h56v4H36zm0 12h40v4H36z" />
      </svg>
      <span className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
        {brandName}
      </span>
    </div>
  </div>
));

const NavItem = React.memo(({ icon: Icon, label, path, description, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === path;
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";

  return (
    <Link
      to={path}
      onClick={onClick}
      className={`
        flex items-center gap-3 px-6 py-3 
        transition-all duration-200
        ${
          isActive
            ? `${
                isDark ? "bg-blue-600 text-white" : "bg-blue-50 text-blue-600"
              } shadow-sm`
            : `hover:bg-gray-50 dark:hover:bg-gray-800/50
             ${isDark ? "text-gray-300" : "text-gray-700"}`
        }
      `}
    >
      <Icon
        className={`h-5 w-5 ${isActive ? "text-current" : "text-gray-400"}`}
      />
      <div>
        <div className="font-medium">{label}</div>
        <div
          className={`text-sm ${
            isActive
              ? isDark
                ? "text-blue-200"
                : "text-blue-600/70"
              : "text-gray-500 dark:text-gray-400"
          }`}
        >
          {description}
        </div>
      </div>
    </Link>
  );
});

// Mobile top bar component with proper props
const MobileTopBar = React.memo(({ onMenuClick, selectedLocation, isDark }) => (
  <div
    className={`
      md:hidden fixed top-0 left-0 right-0 z-[60] px-4 py-3
      ${isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}
      border-b shadow-sm
    `}
  >
    <div className="flex items-center justify-between">
      <button
        onClick={onMenuClick}
        className={`p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700
          ${isDark ? 'text-gray-200' : 'text-gray-700'}`}
        aria-label="Open menu"
      >
        <Menu className="h-6 w-6" />
      </button>
      <span className={`text-sm font-medium ${isDark ? 'text-gray-200' : 'text-gray-900'}`}>
        {selectedLocation?.name || "All Locations"}
      </span>
    </div>
  </div>
));

// Mobile Sidebar Portal Component
const MobileSidebarPortal = ({ isOpen, onClose, navigationItems, isDark }) => {
  if (!isOpen) return null;

  const content = (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div className="absolute inset-0 bg-black/50" onClick={onClose} />
      <div className={`absolute inset-y-0 left-0 w-64 transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      }`}>
        <DashboardIntegratedSidebar 
          navigationItems={navigationItems} 
          isMobile={true} 
          onClose={onClose}
        />
      </div>
    </div>
  );

  return createPortal(content, document.body);
};

const DashboardLayout = ({
  children,
  navigationItems = [],
  brandName = "FeedBacked",
}) => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  const { isSidebarOpen, toggleSidebar, closeSidebar } = useMobileSidebar();
  const selectedLocation = useSelector(locationSelectors.selectSelectedLocation);

  return (
    <div className="min-h-screen flex">
      {/* Desktop Sidebar */}
      <div className="hidden md:block w-64 fixed inset-y-0">
        <DashboardIntegratedSidebar navigationItems={navigationItems} />
      </div>

      {/* Mobile Sidebar Portal */}
      <MobileSidebarPortal
        isOpen={isSidebarOpen}
        onClose={closeSidebar}
        navigationItems={navigationItems}
        isDark={isDark}
      />

      {/* Main Content */}
      <div className="flex-1 md:ml-64">
        {/* Mobile Top Bar */}
        <MobileTopBar 
          onMenuClick={toggleSidebar}
          selectedLocation={selectedLocation}
          isDark={isDark}
        />

        {/* Main Content Area */}
        <main
          className={`
            min-h-screen 
            ${isDark ? "bg-gray-900" : "bg-gray-50"}
            pt-16 md:pt-0
          `}
        >
          <div className="p-4 md:p-8">{children}</div>
        </main>
      </div>
    </div>
  );
};

// PropTypes
MobileSidebarPortal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  navigationItems: PropTypes.arrayOf(  // Changed from navLinks to navigationItems
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    })
  ).isRequired,
  isDark: PropTypes.bool.isRequired,
};

NavItem.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Logo.propTypes = {
  brandName: PropTypes.string.isRequired,
};

MobileTopBar.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  selectedLocation: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  isDark: PropTypes.bool.isRequired,
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    })
  ),
  brandName: PropTypes.string,
};

export default React.memo(DashboardLayout);