import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'sonner';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../../../components/shadcn/dialog/Dialog';
import {
  fetchSubscriptionTiers,
  selectSubscriptionTiers,
} from '../../../../store/slices/subscriptionSlice';
import { addRestaurant, fetchRestaurants } from '../../../../store/slices/restaurantSlice';
import { selectAccessToken } from '../../../../store/shared/auth/authSelectors';

const RestaurantCreationModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);
  const subscriptionTiers = useSelector(selectSubscriptionTiers);
  const [name, setName] = useState('');
  const [subscriptionTierId, setSubscriptionTierId] = useState('');

  useEffect(() => {
    if (open && accessToken) {
      dispatch(fetchSubscriptionTiers(accessToken));
    }
  }, [open, dispatch, accessToken]);

  const handleAddRestaurant = async () => {
    if (name && subscriptionTierId) {
      try {
        await dispatch(addRestaurant({ 
          name, 
          subscription_tier_id: subscriptionTierId,
          token: accessToken 
        })).unwrap();
        
        onClose();
        setName('');
        setSubscriptionTierId('');
        
        await dispatch(fetchRestaurants());
        
        toast.success('Restaurant created successfully!', {
          duration: 3000,
          className: 'bg-green-500',
        });
      } catch (error) {
        toast.error(error.message || 'Failed to create restaurant', {
          duration: 3000,
        });
      }
    }
  };

  const formatTierOption = (tier) => {
    return `${tier.name} - ${tier.max_reviews_per_month} reviews/month, ${tier.keywords_max_count} keywords`;
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add New Restaurant</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Restaurant Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded"
          />
          <select
            value={subscriptionTierId}
            onChange={(e) => setSubscriptionTierId(e.target.value)}
            className="w-full p-2 border rounded bg-white"
          >
            <option value="">Select Subscription Tier</option>
            {subscriptionTiers.map((tier) => (
              <option 
                key={tier.id} 
                value={tier.id}
                className="py-2"
              >
                {formatTierOption(tier)}
              </option>
            ))}
          </select>
        </div>
        <DialogFooter>
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={handleAddRestaurant}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:hover:bg-blue-600"
            disabled={!name || !subscriptionTierId}
          >
            Add
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RestaurantCreationModal;
